import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_POCKET_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/pocket/transactions`;
const SEARCH_POCKET_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/pocket/transactions?`;
const SEARCH_PENDING_POCKET_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/pocket/transactions?status=PENDING&notInBatch=false&source=WITHDRAW`;

const CREATE_PAYMENT_BLOCK = `${REACT_APP_BASE_URL}/v1/admin/payment-batch`;

const FETCH_ALL_CURRENCIES = `${REACT_APP_BASE_URL}/v1/admin/currencies`;
const FETCH_PAYMENT_BLOCK = `${REACT_APP_BASE_URL}/v1/admin/payment-batch`;
const COMPLETE_PAYMENT_BLOCK = `${REACT_APP_BASE_URL}/v1/admin/payment-batch`;
const UPDATE_WITHDRAWAL_STATUS = `${REACT_APP_BASE_URL}/v1/admin/pocket/withdrawal-status`;

const fetchPocketTransactions = async (page, limit) => {
  let res = '';
  if (page && limit) {
    res = await axios.get(
      `${FETCH_POCKET_TRANSACTIONS}?page=${page}&limit=${limit}`,
    );
    return res;
  } else {
    res = axios.get(`${FETCH_POCKET_TRANSACTIONS}`);
  }
  return res;
};

const fetchPocketOpTransactions = async (page, limit) => {
  const PENDING = 'PENDING';
  let res = '';
  if (page && limit) {
    res = await axios.get(
      `${FETCH_POCKET_TRANSACTIONS}?status=${PENDING}&source=WITHDRAW&notInBatch=${false}&page=${page}&limit=${limit}`,
    );
    return res;
  } else {
    res = axios.get(`${FETCH_POCKET_TRANSACTIONS}`);
  }
  return res;
};
const updatePocketTransactionStatus = async (id, status) => {
  let res = '';
  const body = {
    status: status,
  };
  res = await axios.put(`${FETCH_POCKET_TRANSACTIONS}/${id}`, body);
  return res;
};

const searchPocketTransactions = async (
  operation,
  partnerId,
  userStatus,
  source,
  status,
  dateColumnName,
  startDate,
  endDate,
  search,
  page,
  limit,
) => {
  let URL = SEARCH_POCKET_TRANSACTIONS;
  let queryParams = [
    'operation',
    'partnerId',
    'userStatus',
    'source',
    'status',
    'dateColumnName',
    'startDate',
    'endDate',
    'search',
    'page',
    'limit',
  ];
  let functionParams = [
    operation,
    partnerId,
    userStatus,
    source,
    status,
    dateColumnName,
    startDate,
    endDate,
    search,
    page,
    limit,
  ];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = await axios.get(URL);
  return res;
};
const searchPendingPocketTransactions = async (
  serviceProvider,
  partnerId,
  currency,
  userStatus,
  dateColumnName,
  startDate,
  endDate,
  search,
  page,
  limit,
) => {
  let URL = SEARCH_PENDING_POCKET_TRANSACTIONS;
  let queryParams = [
    'serviceProvider',
    'partnerId',
    'currency',
    'userStatus',
    'dateColumnName',
    'startDate',
    'endDate',
    'search',
    'page',
    'limit',
  ];
  let functionParams = [
    serviceProvider,
    partnerId,
    currency,
    userStatus,
    dateColumnName,
    startDate,
    endDate,
    search,
    page,
    limit,
  ];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = await axios.get(URL);
  return res;
};

const updateTransactionWithdrawalStatus = async transactionIds => {
  const res = await axios.put(`${UPDATE_WITHDRAWAL_STATUS}`, {
    transactionIds,
  });
  return res;
};

const createPaymentBlock = async (transactionIds, currency, channel) => {
  const res = await axios.post(`${CREATE_PAYMENT_BLOCK}`, {
    transactionIds,
    currency,
    channel,
  });
  return res;
};

const fetchPaymentBlocks = async (page, limit) => {
  if (page && limit) {
    const res = await axios.get(
      `${FETCH_PAYMENT_BLOCK}?page=${page}&limit=${limit}`,
    );
    return res;
  }

  const res = await axios.get(`${FETCH_PAYMENT_BLOCK}`);

  return res;
};

const fetchPaymentBlockById = async id => {
  const res = await axios.get(`${FETCH_PAYMENT_BLOCK}/${id}`);
  return res;
};

const completeBlock = async (id, paymentOption, pin) => {
  const res = await axios.patch(`${COMPLETE_PAYMENT_BLOCK}/${id}`, {
    paymentOption: paymentOption,
    pin,
  });
  return res;
};

const removePaymentBatch = async id => {
  const res = await axios.delete(`${COMPLETE_PAYMENT_BLOCK}/${id}`);
  return res;
};
const removeTransactionFromPaymentBatch = async (id, transactionIds) => {
  const res = await axios.patch(
    `${COMPLETE_PAYMENT_BLOCK}/${id}/remove-transactions`,
    {
      transactionIds,
    },
  );
  return res;
};

const completeTransactionFromPaymentBatch = async id => {
  const res = await axios.patch(`${COMPLETE_PAYMENT_BLOCK}/${id}/status`);
  return res;
};

const fetchAllCurrencies = async () => {
  const res = await axios.get(`${FETCH_ALL_CURRENCIES}`);
  return res;
};

export {
  completeBlock,
  completeTransactionFromPaymentBatch,
  createPaymentBlock,
  fetchAllCurrencies,
  fetchPaymentBlockById,
  fetchPaymentBlocks,
  fetchPocketOpTransactions,
  fetchPocketTransactions,
  removePaymentBatch,
  removeTransactionFromPaymentBatch,
  searchPendingPocketTransactions,
  searchPocketTransactions,
  updatePocketTransactionStatus,
  updateTransactionWithdrawalStatus,
};
