import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';

import { fetchBlocks } from '../_redux/blocksCrud';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import CommonModal from '../../../common/CommonModal';
import { AdminPermissions } from '../../../helpers/constants';
import BackIcon from '../../../icons/backIcon.svg';
import NumberOfRows from '../../../common/SelectNumberOfRows';

const Blocks = () => {
  const { partnerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [blockID, setBlockID] = useState('');
  const [blockStatus, setBlockStatus] = useState('');
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  const getBlocks = async currentPartnerId => {
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchBlocks(page, limit, currentPartnerId);
      setLoading(false);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setPage(meta.currentPage);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const searchForBlock = async () => {
    try {
      setLoadingData(true);
      const {
        data: { items, meta },
      } = await fetchBlocks(
        1,
        limit,
        partnerId ? partnerId : selectedPartnerId,
        blockID,
        blockStatus,
      );
      setLoadingData(false);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setPage(meta.currentPage);
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const handleChangePartnerId = value => {
    setSelectedPartnerId(value);
  };

  const handleChangePage = async (event, value) => {
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchBlocks(
        value,
        limit,
        partnerId ? partnerId : selectedPartnerId,
        blockID,
        blockStatus,
      );
      setLoading(false);
      setPage(value);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchBlocks(
        1,
        selectedValue,
        partnerId ? partnerId : selectedPartnerId,
        blockID,
        blockStatus,
      );
      setLoading(false);
      setLimit(selectedValue);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getBlocks(partnerId ? partnerId : selectedPartnerId);
  }, [partnerId]);

  const handleCloseModal = () => {
    setSelectedPartnerId(null);
    getBlocks(partnerId ? partnerId : null);
    setOpen(false);
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <div className="mb-2">
      {partnerId ? (
        <Link to={'/partners'}>
          <img src={BackIcon} alt={'Back icon'} />
          <span className="ml-2">Back</span>
        </Link>
      ) : (
        <></>
      )}
      <div className="card card-custom card-stretch gutter-b mt-3">
        <div className="card-header">
          <form className="form-inline my-10">
            <div className="form-group mb-2">
              <input
                type="number"
                name="blockID"
                className="form-control"
                value={blockID}
                min={0}
                placeholder="Block ID"
                onChange={e => setBlockID(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className="form-group mx-sm-3 mb-2">
              <select
                className="btn btn-light-primary font-weight-bold"
                onChange={e => setBlockStatus(e.target.value.toUpperCase())}
              >
                <option value="">Block Status</option>
                <option value="pending">pending</option>
                <option value="completed">completed</option>
              </select>
            </div>
            {grantPermission(AdminPermissions.PARTNER_VIEW) && !partnerId ? (
              <button
                style={{
                  background: '#0071CE',
                  borderRadius: '5px',
                  padding: '0px 20px',
                  border: '1px solid #0071CE',
                  color: '#ffffff',
                  height: '41px',
                  marginBottom: '10px',
                }}
                onClick={e => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                Filter By Partners
              </button>
            ) : (
              ''
            )}

            <CommonModal
              open={open}
              handleClose={() => handleCloseModal()}
              getByPartners={getBlocks}
              handleChangePartnerId={handleChangePartnerId}
              partnerId={selectedPartnerId}
              setOpen={setOpen}
            />
            <button
              type="submit"
              className="btn btn-light-primary mb-2 px-10 form-group"
              onClick={e => {
                e.preventDefault();
                searchForBlock();
              }}
            >
              Search....
              <span className="svg-icon svg-icon-light ml-2">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                  alt="search icon"
                />
              </span>
              {loadingData && <span className="ml-3 spinner spinner-white" />}
            </button>
          </form>
        </div>
        <div className="card-body mt-3 mb-10 pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-center text-uppercase">
                    <th style={{ minWidth: '100px' }} className="pl-7">
                      <span className="text-dark-75">Block ID</span>
                    </th>
                    <th style={{ minWidth: '100px' }} className="pl-7">
                      <span className="text-dark-75">Partner</span>
                    </th>
                    <th style={{ minWidth: '150px' }}>
                      <span className="text-dark-75">Timestamp Creation</span>
                    </th>
                    <th style={{ minWidth: '150px' }}>
                      <span className="text-dark-75">Block Status</span>
                    </th>
                    {grantPermission(AdminPermissions.BLOCK_VIEW) ? (
                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">View Funds</span>
                      </th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>
                <tbody>
                  {blocks &&
                    blocks.map(({ id, partner, status, createdAt }) => (
                      <tr key={id}>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {id}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {partner ? partner.name : '---'}
                          </div>
                        </td>
                        <td className="text-center">
                          <span className="text-dark-75  d-block font-size-lg">
                            {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-dark-75  d-block font-size-lg">
                            {status}
                          </span>
                        </td>
                        {grantPermission(AdminPermissions.BLOCK_VIEW) ? (
                          <td className="pr-0 text-center">
                            <Link
                              to={`/view-fund/${id}`}
                              className="btn btn-light-primary  font-size-sm"
                            >
                              View
                            </Link>
                          </td>
                        ) : (
                          ''
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            {
              <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
              />
            }
            {
              <NumberOfRows
                handleChangeLimit={handleChangeLimit}
                totalRecord={totalRecord}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blocks;
