import { useFormik } from 'formik';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { validateOtp } from '../_redux/authCrud';
import * as auth from '../_redux/authRedux';

const initialValues = {
  code: '',
};

const OtpVerification = props => {
  const { intl, history } = props;
  const [loading, setLoading] = useState(false);
  const authData = useSelector(state => state.auth);

  //When there is no authentication data go back to login
  if (!authData?.email || !authData?.password)
    props.history.push('/auth/login');

  const LoginSchema = Yup.object().shape({
    code: Yup.string()
      .min(4, '4 numbers required')
      .max(4, '4 numbers required')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        }),
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      try {
        const res = await validateOtp({
          code: values.code,
          email: authData.email,
          password: authData.password,
        });
        disableLoading();
        if (res.status === 200) {
          // this will now login the user to the home page
          props.login(res.data);
        }
      } catch (error) {
        disableLoading();
        setSubmitting(false);
        setStatus(
          error.response.data
            ? error.response.data.message
            : 'Unable to verify otp code.Try again.',
        );
      }
    },
  });

  const exitTokenPage = () => {
    localStorage.clear();
    history.push('/auth/login');
  };

  const displayAuthActionLink = () => {
    return (
      <>
        <span
          style={{ color: '#0071CE' }}
          className="font-weight-bold cursor-pointer ml-2"
          id="kt_login_signup"
          onClick={() => exitTokenPage()}
        >
          {' '}
          Exit Page{' '}
        </span>
      </>
    );
  };

  const renderAccessForm = () => {
    return (
      <div>
        <div className="text-center mb-5 mb-lg-10">
          <h1 className="font-size-h1">Access Token</h1>
          <p>
            A token has been sent to your email
            <br />
            Enter your 4 digit token
          </p>
        </div>

        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            ''
          )}

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Code"
              type="code"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'code',
              )}`}
              name="code"
              {...formik.getFieldProps('code')}
              autoComplete="off"
            />
            {formik.touched.code && formik.errors.code ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.code}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span>Verify</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
        {/*end::Form*/}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
            style={{
              height: '80vh',
            }}
          >
            {/*begin::Content header*/}
            <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              {displayAuthActionLink()}
            </div>
            {/*end::Content header*/}
            {/* begin::Content body */}

            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              {renderAccessForm()}
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};

export default injectIntl(connect(null, { ...auth.actions })(OtpVerification));
