import React from 'react';
import moment from 'moment';
import { InvestorTypes } from '../../../helpers/constants';

const AllKycAuditInfo = ({
  createdAt,
  email,
  firstName,
  lastName,
  kycStatus,
  status,
  userLevel,
  kycSubmittedAt,
  kycEvaluatedAt,
  investorType,
  businessChamaName,
  admin,
}) => {
  return (
    <tr>
      <td>
        <span className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {email}
        </span>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">
          {' '}
          {investorType === InvestorTypes.INDIVIDUAL
            ? `${firstName} ${lastName}`
            : businessChamaName}
        </span>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD')}
        </span>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">
          {kycSubmittedAt === null
            ? '- - -'
            : moment(kycSubmittedAt).format('YYYY-MM-DD')}
        </span>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">
          {kycEvaluatedAt === null
            ? '- - -'
            : moment(kycEvaluatedAt).format('YYYY-MM-DD')}
        </span>
      </td>
      <td>
        <span className="d-block font-size-sm">{kycStatus}</span>
      </td>
      <td>
        <span className="d-block font-size-sm"> {admin ? admin : '---'} </span>
      </td>
    </tr>
  );
};

export default AllKycAuditInfo;
