import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { fetchPlanDetails } from '../../plans/_redux/planCrud';
import { updateCorporateActions } from '../_redux';

import Alert from '../../../common/Alert';
import Back from '../../../common/Back';
import Loader from '../../../common/Loader';
import { PlanDetailsContainer } from '../../../common/PlanDetailsContainer';
import SelectBox from '../../../common/SelectBox';
import { DisplaySuccessModal } from '../../../common/SelectNumberOfRows/SuccessModal';
import { actionNames, corporateActions } from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';

import '../index.scss';

const CorprateActionDetail = () => {
  const { planId } = useParams();
  const [planDetails, setPlanDetails] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);
  const [corporateAction, setCorporateAction] = useState();
  const [factor, setFactor] = useState();
  const [newTotalShare, setNewTotalShare] = useState();
  const [newSharePrice, setNewSharePrice] = useState();

  const currentSharePrice = planDetails?.unitCost;

  const updatePlanDetailsState = async () => {
    try {
      setLoading(true);
      const response = await fetchPlanDetails(planId);
      const planData = response?.data?.data;
      setPlanDetails(planData);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };

  const changeShareAndSharePrice = () => {
    if (corporateAction) {
      if (planDetails?.totalUnitsHeld && factor) {
        if (corporateAction === corporateActions.STOCK_SPLIT) {
          setNewTotalShare(planDetails?.totalUnitsHeld * factor);
          setNewSharePrice(currentSharePrice / factor);
        } else {
          setNewTotalShare(planDetails?.totalUnitsHeld / factor);
          setNewSharePrice(currentSharePrice * factor);
        }
      }
    }
  };

  useEffect(() => {
    changeShareAndSharePrice();
  }, [factor, corporateAction, planDetails?.totalUnitsHeld]);

  useEffect(() => {
    updatePlanDetailsState();
  }, []);

  const handleSubmit = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });

    const estimateShare = `
      <div class="mt-8 mb-5 stock-split-details">
        <div>
          Estimated New Share Price: <strong>${
            planDetails?.currency
          } ${formatNumber(newSharePrice?.toFixed(4))}</strong>
        </div>
        <div>
          New Total Shares Outstanding:  <strong>${formatNumber(
            newTotalShare?.toFixed(4),
          )}</strong>
         
        </div>
      </div>
    `;

    const popupContent = `
      <div>
        <div><strong>Are you sure you want to ${actionNames[corporateAction]} ${planDetails?.coolName}.<strong></div>
        ${estimateShare}
      </div>
    `;

    const result = await swalWithBootstrapButtons.fire({
      html: popupContent,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      reverseButtons: true,

      customClass: {
        confirmButton: 'popup-buttons popup-confirm-button',
        cancelButton: 'popup-buttons popup-cancel-button',
      },

      imageUrl: '/media/svg/ndovu/tick-circle.svg',
      imageAlt: 'Icon',
    });

    if (result.isConfirmed) {
      setAlert({
        alertMessage: null,
        alertMessageType: null,
      });

      const payload = {
        planId: Number(planId),
        action: corporateAction,
        factor: Number(factor),
      };

      try {
        setLoading(true);
        const response = await updateCorporateActions(payload);

        DisplaySuccessModal(response?.data?.message);

        setLoading(false);
        setFactor(null);
        setCorporateAction(null);
      } catch (error) {
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
        setLoading(false);
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const dropdownOptions = [
    { name: 'Stock Split', value: corporateActions.STOCK_SPLIT },
    { name: 'Consolidation', value: corporateActions.CONSOLIDATION },
  ];

  const updateCorporateAction = action => {
    setCorporateAction(action);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-5">
            <Back url={`/plans`} />
          </div>

          <div className="card card-custom gutter-b">
            <div className="card-header">
              <h4 className="pt-8">Corporate Action</h4>
            </div>

            <div className="card-body">
              <div className="mb-5">
                {alert?.alertMessage ? (
                  <Alert alert={alert} alignText={'text-left'} />
                ) : null}
              </div>

              <PlanDetailsContainer>
                <div>
                  Fund:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.coolName ? planDetails?.coolName : '---'}
                  </span>
                </div>

                <div>
                  Symbol:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.identifier ? planDetails?.identifier : '---'}
                  </span>
                </div>

                <div>
                  Current share price:{' '}
                  <span className="font-weight-bold">
                    {currentSharePrice
                      ? `${planDetails?.currency} ${formatNumber(
                          currentSharePrice?.toFixed(4),
                        )}`
                      : '---'}
                  </span>
                </div>
                <div>
                  Total Amount of units held:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.totalUnitsHeld
                      ? formatNumber(planDetails?.totalUnitsHeld)
                      : '---'}
                  </span>
                </div>

                <div>
                  Total investors holding the fund:{' '}
                  <span className="font-weight-bold">
                    {planDetails?.totalInvestors
                      ? planDetails?.totalInvestors
                      : '---'}
                  </span>
                </div>
              </PlanDetailsContainer>

              <div className="d-flex flex-column mt-10" style={{ gap: '16px' }}>
                <div style={{ maxWidth: '500px' }}>
                  <div className="input-label">Select action</div>
                  <SelectBox
                    options={dropdownOptions}
                    name="Select option"
                    setValue={updateCorporateAction}
                    styles={{
                      border: '1px solid #797979',
                      color: '#000',
                      width: '100%',
                      height: '45px',
                      borderRadius: '3px',
                      textAlign: 'left',
                      padding: '0 10px',
                      background: '#FFFFFF',
                      fontWeight: 400,
                    }}
                  />
                </div>

                <div>
                  <div className="input-label">Factor</div>
                  <input
                    className="form-control input-container"
                    type="number"
                    name="factor"
                    min={0}
                    autoComplete="off"
                    placeholder="Enter factor"
                    value={factor || ''}
                    onChange={e => setFactor(e.target.value)}
                  />
                </div>
              </div>

              {factor && corporateAction ? (
                <div className="stock-split-details mt-5">
                  <div>
                    Estimated New Share Price:{' '}
                    <strong>
                      {planDetails?.currency}{' '}
                      {formatNumber(newSharePrice?.toFixed(6))}
                    </strong>
                  </div>
                  <div>
                    New Total Shares Outstanding:{' '}
                    <strong>{formatNumber(newTotalShare?.toFixed(6))}</strong>
                  </div>

                  <button
                    style={{ width: '155px' }}
                    className="btn btn-light-primary font-weight-bold mt-10"
                    onClick={handleSubmit}
                    disabled={!factor || !corporateAction}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CorprateActionDetail;
