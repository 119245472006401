import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
const SETTLEMENT_DETAILS_API = `${REACT_APP_BASE_URL}/v1/admin/settlement-details`;
const UPDATE_STATUS_API = `${REACT_APP_BASE_URL}/v1/admin/settlement-details`;

const fetchSettlementDetails = async (
  page,
  limit,
  serviceProvider,
  search,
  investorId = null,
) => {
  let URL = SETTLEMENT_DETAILS_API + '?';
  let queryParams = [
    'page',
    'limit',
    'serviceProvider',
    'search',
    'investorId',
  ];
  let functionParams = [page, limit, serviceProvider, search, investorId];
  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = await axios.get(URL);
  return res;
};

const updateSettlementDetailStatus = async (id, status) => {
  const res = await axios.put(`${UPDATE_STATUS_API}/${id}`, {
    status,
  });
  return res;
};

export { fetchSettlementDetails, updateSettlementDetailStatus };
