import React, { useEffect, useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Swal from 'sweetalert2';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';

import { AdminPermissions } from '../../../helpers/constants';

import NumberOfRows from '../../../common/SelectNumberOfRows';
import { fetchPaymentBlocks } from '../_redux/pocketsCrud';

const PaymentBlocks = () => {
  const [loading, setLoading] = useState(true);
  const [blocks, setBlocks] = useState([]);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  const getBlocks = async () => {
    try {
      setLoading(true);
      const res = await fetchPaymentBlocks(page, limit);

      const { items, meta } = res.data;

      setLoading(false);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setPage(meta.currentPage);
      setOpen(false);
    } catch (error) {
      setOpen(false);
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const handleChangePage = async (event, value) => {
    try {
      setLoading(true);
      const res = await fetchPaymentBlocks(value, limit);
      const { items, meta } = res.data;

      setLoading(false);
      setPage(value);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    try {
      setLoading(true);
      const res = await fetchPaymentBlocks(1, selectedValue);
      const { items, meta } = res.data;

      setLoading(false);
      setLimit(selectedValue);
      setBlocks(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    getBlocks();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <main className="card card-custom card-stretch gutter-b">
          <section
            style={{
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div className="card-header">
              <div
                className="mt-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h1>Payment batches</h1>
                <p>Batch of Pocket withdrawals transactions</p>
              </div>
            </div>
          </section>
          <section className="card-body mt-3 mb-10 pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive">
                <table
                  className="table table-head-custom table-head-bg  table-border table-vertical-center"
                  style={{
                    minWidth: '60%',
                  }}
                >
                  <thead>
                    <tr className="text-center text-uppercase">
                      <th
                        style={{ minWidth: '100px', textAlign: 'center' }}
                        className="pl-7"
                      >
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          {' '}
                          Payment Batch ID
                        </span>
                      </th>
                      <th
                        style={{ minWidth: '100px', textAlign: 'center' }}
                        className="pl-7"
                      >
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          Payment Channel
                        </span>
                      </th>
                      <th
                        style={{ minWidth: '100px', textAlign: 'center' }}
                        className="pl-7"
                      >
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          Currency
                        </span>
                      </th>
                      <th style={{ minWidth: '150px', textAlign: 'center' }}>
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          Timestamp Creation
                        </span>
                      </th>
                      <th style={{ minWidth: '150px', textAlign: 'center' }}>
                        <span
                          style={{
                            color: '#3699FF',
                          }}
                        >
                          Payment Block Status
                        </span>
                      </th>
                      {grantPermission(
                        AdminPermissions.MANIPULATE_PAYMENT_BATCH,
                      ) ? (
                        <th style={{ minWidth: '120px', textAlign: 'center' }}>
                          <span
                            style={{
                              color: '#3699FF',
                            }}
                          >
                            View Transactions
                          </span>
                        </th>
                      ) : (
                        ''
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {blocks &&
                      blocks?.map(
                        ({
                          id,
                          partner,
                          status,
                          createdAt,
                          channel,
                          currency,
                        }) => (
                          <tr key={id}>
                            <td className="pl-0 py-8 text-center">
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {id}
                              </div>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {channel ? channel : '---'}
                              </div>
                            </td>
                            <td className="pl-0 py-8 text-center">
                              <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                                {currency ? currency : '---'}
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="text-dark-75  d-block font-size-lg">
                                {moment(createdAt).format(
                                  'YYYY-MM-DD, h:mm:ss a',
                                )}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="text-dark-75  d-block font-size-lg">
                                {status}
                              </span>
                            </td>
                            {grantPermission(
                              AdminPermissions.MANIPULATE_PAYMENT_BATCH,
                            ) ? (
                              <td className="pr-0 text-center">
                                <Link
                                  to={`/payment-block/${id}`}
                                  className="btn btn-light-primary  font-size-sm"
                                >
                                  View
                                </Link>
                              </td>
                            ) : (
                              ''
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default PaymentBlocks;
