import Swal from 'sweetalert2';

const handleAlert = (message, iconType) => {
  Swal.fire({
    icon: iconType,
    position: 'top-end',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    text: message,
    showConfirmButton: false,
    timer: 2000,
  });
};

export default handleAlert;
