import React from 'react';

import './FileUpload.scss';

const FileUpload = ({
  title,
  name,
  handleChange,
  displayText,
  required,
  displayTitle,
}) => {
  return (
    <>
      <div className="d-flex flex-column">
        {displayTitle && <label className="">{title}:</label>}
        <div className="">
          <div className="file-input d-flex align-items-center">
            <input
              type="file"
              accept="image/png,image/jpeg,.pdf"
              name={name}
              onChange={e => handleChange(e, name)}
            />
            <div className="d-flex align-items-center">
              <label
                className={
                  displayText === 'Choose File'
                    ? 'input-label'
                    : 'input-label-change'
                }
                htmlFor={name}
              >
                {displayText}
              </label>
              {required ? (
                <span style={{ color: '#F05B7F' }} className="ml-1">
                  *
                </span>
              ) : null}
            </div>
            <div style={{ marginTop: '-5px' }} className="ml-3">
              Max 5MB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
