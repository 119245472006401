import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const GET_ADMIN = `${REACT_APP_BASE_URL}/v1/admin/admins`;
const UPDATE_ADMIN_PERMISSIONS = `${REACT_APP_BASE_URL}/v1/admin/permissions`;
const CREATE_ADMIN = `${REACT_APP_BASE_URL}/v1/admin/create`;
const UPDATE_ADMIN_STATUS = `${REACT_APP_BASE_URL}/v1/admin/admin-status`;

const fetchAdmins = async (page, limit) => {
  return axios.get(GET_ADMIN, {
    params: { ...(page && { page }), ...(limit && { limit }) },
  });
};

const getPermissions = async id => {
  return axios.get(`${GET_ADMIN}/${id}`);
};

const updatePermission = async (id, permissions) => {
  return axios.put(`${UPDATE_ADMIN_PERMISSIONS}/${id}`, {
    permissions,
  });
};

const createAdmin = async data => {
  return axios.post(CREATE_ADMIN, data);
};

const updateAdminStatus = async (id, status) => {
  return axios.put(`${UPDATE_ADMIN_STATUS}/${id}`, {
    status,
  });
};

export {
  createAdmin,
  fetchAdmins,
  getPermissions,
  updateAdminStatus,
  updatePermission,
};
