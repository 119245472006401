import axios from 'axios';
import dotenv from 'dotenv';
import moment from 'moment';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const DAILY_TRADES_URL = `${REACT_APP_BASE_URL}/v1/admin/plans/daily-trade-prices`;
const DAILY_TRADES_EXPORT_PATH = `v1/admin/plans/daily-trade-prices/export`;

const fetchAllDailyTrades = async (page, limit, fundName, date) => {
  let URL = `${DAILY_TRADES_URL}?`;
  let queryParams = ['page', 'limit', 'search', 'date'];
  let functionParams = [page, limit, fundName, date];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = await axios.get(URL);
  return res;
};

const saveDailyTrade = async askBidPriceArray => {
  const res = await axios.post(`${DAILY_TRADES_URL}`, askBidPriceArray);
  return res;
};

const updateDailyTrade = async (dailyTradeId, planId, askAmount, bidAmount) => {
  const res = await axios.put(`${DAILY_TRADES_URL}/${dailyTradeId}`, {
    planId: planId,
    askPrice: askAmount,
    bidPrice: bidAmount,
  });
  return res;
};

export {
  fetchAllDailyTrades,
  saveDailyTrade,
  updateDailyTrade,
  DAILY_TRADES_EXPORT_PATH,
};
