import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import SVG from 'react-inlinesvg';
import { Link, useParams } from 'react-router-dom';

import { createBlocks } from '../../Blocks/_redux/blocksCrud';
import { fetchTransactions, moveToArchived } from '../_redux/transactionsCrud';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import UnassignedTransaction from '../pages/UnassignedTransaction';
import './index.scss';
import {
  TransactionRejectionReason,
  OperationType,
  DisinvestmentTransactionRejectionReason,
  KycStatus,
  TransactionStatus,
} from './../../../helpers/constants';

import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import CommonModal from '../../../common/CommonModal';
import { DateInput } from '../../../common/DateInput';
import handleAlert from '../../../common/handleAlert';
import BackIcon from '../../../icons/backIcon.svg';
import { ExportButton } from '../../../common/CommonBtn';
import { exportToExcel } from '../../../utils/exportToExcel';

const UnassignedTransactions = () => {
  const { partnerId } = useParams();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [transactionIds, setTransactionIds] = useState([]);
  const [trnxnToArchiveId, setTrnxnToArchiveId] = useState(null);
  const [showReasonsModal, setShowReasonsModal] = useState(false);
  const [reasonToArchiveTrnxn, setReasonToArchiveTrnxn] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchItem, setSearchItem] = useState('');
  const [open, setOpen] = useState(false);
  const [dateValues, setDateValues] = useState({});
  const [transactionFilters, setTransactionFilters] = useState({
    transactionType: null,
    operationType: '',
  });
  const [selectedPartnerId, setSelectedPartnerId] = useState([]);

  const [exportLoading, setExportLoading] = useState(false);

  const getTransactions = async currentPartnerId => {
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchTransactions(
        transactionFilters.transactionType,
        page,
        limit,
        searchItem,
        dateValues?.startDate,
        dateValues?.endDate,
        currentPartnerId,
        transactionFilters.operationType,
      );
      setTransactions(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setPage(meta.currentPage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOperations = async e => {
    const { value: selectedValue } = e.target;
    const {
      data: { items, meta },
    } = await fetchTransactions(
      transactionFilters.transactionType,
      1,
      limit,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      partnerId ? partnerId : selectedPartnerId,
      selectedValue,
    );
    setTransactions(items);
    setTotalRecord(meta.totalItems);
    setTotalPages(meta.totalPages);
    setPage(meta.currentPage);
  };

  const handleSelect = async e => {
    const { value: selectedValue } = e.target;
    const {
      data: { items, meta },
    } = await fetchTransactions(
      selectedValue,
      1,
      limit,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      partnerId ? partnerId : selectedPartnerId,
      transactionFilters.operationType,
    );
    setTransactions(items);
    setTotalRecord(meta.totalItems);
    setTotalPages(meta.totalPages);
    setPage(meta.currentPage);
  };

  const handleCreateBlockModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure about creating block?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, create it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await createBlocks(id);
        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getTransactions(selectedPartnerId);
              setTransactionIds([]);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleChange = (e, id) => {
    if (e.target.checked) {
      !transactionIds.includes(id) &&
        setTransactionIds(transactionIds.concat(id));
    } else {
      setTransactionIds(transactionIds.filter(value => value !== id));
    }
  };

  const selectAll = () => {
    let collectors = [];
    transactions.forEach(transact => {
      if (
        transactionFilters.operationType === '' ||
        transactionFilters.operationType === OperationType.DEPOSIT
      ) {
        if (
          transact.planSubscription.investor.kycStatus ===
            KycStatus.COMPLETED &&
          transact.transactionStatus === TransactionStatus.RECONCILED
        ) {
          collectors.push(transact.id);
          setTransactionIds(collectors);
        }
      } else if (
        transactionFilters.operationType === OperationType.DISINVESTMENT
      ) {
        if (
          transact.planSubscription.investor.kycStatus ===
            KycStatus.COMPLETED &&
          transact.transactionStatus === TransactionStatus.PENDING
        ) {
          collectors.push(transact.id);
          setTransactionIds(collectors);
        }
      }
    });
  };

  const deselectAll = () => setTransactionIds([]);

  const handleChangePage = async (event, value) => {
    const {
      data: { items, meta },
    } = await fetchTransactions(
      transactionFilters.transactionType,
      value,
      limit,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      partnerId ? partnerId : selectedPartnerId,
      transactionFilters.operationType,
    );
    setPage(value);
    setTotalRecord(meta.totalItems);
    setTransactions(items);
    window.scrollTo({ behavior: 'smooth', top: '0px' });
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const {
      data: { items, meta },
    } = await fetchTransactions(
      transactionFilters.transactionType,
      1,
      selectedValue,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      partnerId ? partnerId : selectedPartnerId,
      transactionFilters.operationType,
    );
    setLimit(selectedValue);
    setTotalRecord(meta.totalItems);
    setTotalPages(meta.totalPages);
    setTransactions(items);
    setPage(meta.currentPage);
  };

  useEffect(() => {
    getTransactions(partnerId ? partnerId : selectedPartnerId);
  }, [partnerId]);

  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const {
      data: { items, meta },
    } = await fetchTransactions(
      transactionFilters.transactionType,
      1,
      limit,
      value,
      dateValues?.startDate,
      dateValues?.endDate,
      partnerId ? partnerId : selectedPartnerId,
      transactionFilters.operationType,
    );
    setSearchItem(value);
    setTotalRecord(meta.totalItems);
    setTotalPages(meta.totalPages);
    setTransactions(items);
    setPage(meta.currentPage);
  };

  const hideReasonsModal = () => {
    setTrnxnToArchiveId(null);
    setShowReasonsModal(false);
    setReasonToArchiveTrnxn(null);
  };

  const displayReasonsModal = (id, operationType) => {
    setTrnxnToArchiveId(id);
    setShowReasonsModal(true);
    if (operationType === OperationType.DISINVESTMENT) {
      setReasonToArchiveTrnxn(
        DisinvestmentTransactionRejectionReason.WITHDARWAL_CANCELLED_BY_CLIENT,
      );
    }
  };

  const handleArchiveTrnxn = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });

    try {
      const response = await moveToArchived(
        trnxnToArchiveId,
        reasonToArchiveTrnxn,
      );
      swalWithBootstrapButtons
        .fire('Archived!', response.data.message, 'success')
        .then(res => {
          setTrnxnToArchiveId(null);
          setShowReasonsModal(false);
          setReasonToArchiveTrnxn(null);
          getTransactions();
        });
    } catch (error) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        error.response.data.message,
        'error',
      );
    }
  };

  const handleFiltersChange = e => {
    const { value, name } = e.target;
    setTransactionFilters({ ...transactionFilters, [name]: value });
  };

  const renderArchiveModal = () => {
    return (
      <div className={`modal ${showReasonsModal === false ? 'd-none' : ''}`}>
        {/* <!-- Modal content --> */}
        <div className="modal-content">
          <div className="card card-custom gutter-b">
            {reasonToArchiveTrnxn &&
            reasonToArchiveTrnxn ===
              DisinvestmentTransactionRejectionReason.DISINVESTMENT_CANCELLED_BY_CLIENT ? (
              <>
                <div className="card-body text-center">
                  <h2>
                    Are you sure you want to archive this transaction{' '}
                    {trnxnToArchiveId}?
                  </h2>
                </div>
              </>
            ) : (
              <>
                <div className="card-header">
                  <h4 className="pt-8">
                    Pick reason to archive transaction {trnxnToArchiveId}
                  </h4>
                </div>
                <div className="card-body">
                  {Object.keys(TransactionRejectionReason).map(reason => (
                    <div key={reason} className="form-check pb-3">
                      <input
                        className="form-check-input"
                        type="radio"
                        value={reason}
                        onChange={e => setReasonToArchiveTrnxn(e.target.value)}
                        checked={reasonToArchiveTrnxn === reason}
                      />
                      <label className="form-check-label" htmlFor={reason}>
                        {TransactionRejectionReason[reason]}
                      </label>
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="card-footer d-flex justify-content-center">
              <button
                type="button"
                className="blue-button"
                style={{
                  background: '#dbedf8',
                  color: '#3699FF',
                  border: 'none',
                  marginRight: '10px',
                }}
                onClick={hideReasonsModal}
              >
                Cancel
              </button>
              {reasonToArchiveTrnxn ? (
                <button
                  type="button"
                  className="blue-button"
                  onClick={handleArchiveTrnxn}
                >
                  Archive
                </button>
              ) : (
                <button
                  type="button"
                  style={{ background: '#dbdbdb', border: 'none' }}
                  className="blue-button"
                >
                  Archive
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const searchForUnassignedTransactions = async (
    transactionFilters,
    searchValue,
    dateValues,
  ) => {
    try {
      if (transactionFilters || searchValue || dateValues) {
        setLoading(true);
        const {
          data: { items, meta },
        } = await fetchTransactions(
          transactionFilters.transactionType,
          1,
          limit,
          searchValue,
          dateValues?.startDate,
          dateValues?.endDate,
          [],
          transactionFilters.operationType,
        );
        setSearchItem(searchValue);
        setTotalRecord(meta.totalItems);
        setTotalPages(meta.totalPages);
        setTransactions(items);
        handleAlert(meta.message, 'success');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleChangePartnerId = value => {
    setSelectedPartnerId(value);
  };

  const handleCloseModal = () => {
    setSelectedPartnerId(null);
    getTransactions(null);
    setOpen(false);
  };

  const getParams = () => {
    let params;
    if (
      (transactionFilters?.transactionType && selectedPartnerId, searchItem)
    ) {
      params = {
        investmentType: transactionFilters?.transactionType,
        selectedPartnerId,
        search: searchItem,
      };
    } else if (transactionFilters?.transactionType && selectedPartnerId) {
      params = {
        investmentType: transactionFilters?.transactionType,
        selectedPartnerId,
      };
    } else if (transactionFilters?.transactionType && searchItem) {
      params = {
        investmentType: transactionFilters?.transactionType,
        search: searchItem,
      };
    } else if (selectedPartnerId && searchItem) {
      params = {
        selectedPartnerId,
        search: searchItem,
      };
    } else {
      params = '';
    }

    return params;
  };

  return (
    <div>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <div className="mb-2">
          {partnerId ? (
            <Link to={'/partners'}>
              <img src={BackIcon} alt={'Back icon'} />
              <span className="ml-2">Back</span>
            </Link>
          ) : (
            <></>
          )}
          <div className="card card-custom gutter-b mt-3">
            {renderArchiveModal()}
            <div className="card-header">
              <h4 className="pt-8">Unassigned Transactions</h4>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              <div>
                <select
                  className="btn btn-light-primary font-weight-bold mb-5"
                  style={{ paddingTop: '10px', paddingBottom: '10px' }}
                  name="transactionType"
                  onChange={e => {
                    handleFiltersChange(e);
                    handleSelect(e);
                  }}
                >
                  <option value="">Transaction Filters</option>
                  <option value="">All transactions</option>
                  <option value="DIRECT">Direct Investments</option>
                  <option value="GOAL">Goal Investments</option>
                </select>
              </div>
              <div>
                <select
                  className="btn btn-light-primary font-weight-bold mb-5"
                  style={{ paddingTop: '10px', paddingBottom: '10px' }}
                  name="operationType"
                  onChange={e => {
                    handleFiltersChange(e);
                    handleOperations(e);
                  }}
                >
                  <option value="">Operation Filters</option>
                  <option value="">All operations</option>
                  <option value="DEPOSIT">Deposits</option>
                  <option value="DISINVESTMENT">Disinvestment</option>
                </select>
              </div>

              <div className="form-group mb-2 mr-1">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by fund name,email , phone and reference"
                  name="plan"
                  min={0}
                  style={{
                    width: '290px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                  onChange={handleSearch}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex">
                <div className="mb-2 mr-2">
                  <p className="font-weight-bold text-center py-2 bg-primary text-white">
                    DATE EXECUTED
                  </p>
                  <DateInput
                    name="startDate"
                    label="Start Date"
                    handleDateChange={handleDateChange}
                  />
                  <DateInput
                    name="endDate"
                    label="End Date"
                    handleDateChange={handleDateChange}
                    classes="ml-2"
                  />
                </div>
                <div>
                  <button
                    className="btn btn-primary px-6 mb-2 mr-1"
                    onClick={e => {
                      e.preventDefault();
                      searchForUnassignedTransactions(
                        transactionFilters,
                        searchItem,
                        dateValues,
                      );
                    }}
                  >
                    Search
                    <span className="svg-icon svg-icon-light ml-2">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Search.svg',
                        )}
                        alt="search icon"
                      />
                    </span>
                    {loading && <span className="ml-3 spinner spinner-white" />}
                  </button>
                </div>
              </div>
            </div>
            <div className="container d-flex">
              {grantPermission(AdminPermissions.PARTNER_VIEW) && !partnerId ? (
                <div className="mr-5">
                  <button
                    style={{
                      background: '#0071CE',
                      borderRadius: '5px',
                      padding: '0px 20px',
                      border: '1px solid #0071CE',
                      color: '#ffffff',
                      height: '41px',
                      marginBottom: '10px',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    Filter By Partners
                  </button>
                </div>
              ) : null}

              <div>
                <ExportButton
                  exportDocument={() =>
                    exportToExcel(
                      setExportLoading,
                      `v1/admin/transactions/export-unassigned-transactions`,
                      getParams(),
                    )
                  }
                  loading={exportLoading}
                  btnText={'Export to Excel'}
                />
              </div>
            </div>

            <CommonModal
              open={open}
              handleClose={() => handleCloseModal()}
              getByPartners={getTransactions}
              handleChangePartnerId={handleChangePartnerId}
              partnerId={selectedPartnerId}
              setOpen={setOpen}
            />

            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive transaction-table">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-uppercase text-center">
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Investor Name</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Investor ID</span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Date Executed</span>
                        </th>

                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Investor Email</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">KYC Status</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">KYC Level</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Operation Type</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Source</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Investment Type</span>
                        </th>

                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Investor Reference
                          </span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Fund Name</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Fund Cool Name</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Fund Manager Name
                          </span>
                        </th>
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Transaction ID</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Payment Channel</span>
                        </th>

                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">
                            Payment Reference
                          </span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Original amount</span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Discount amount</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Ndovu fee</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Ndovu fee percentage
                          </span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">
                            Conversion Curreny
                          </span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            CBK Conversion rate
                          </span>
                        </th>

                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">
                            Applied conversion rate
                          </span>
                        </th>

                        <th style={{ minWidth: '220px' }}>
                          <span className="text-dark-75">
                            Final converted amount
                          </span>
                        </th>

                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Forex fee</span>
                        </th>

                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Units</span>
                        </th>

                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Partner</span>
                        </th>

                        {grantPermission(
                          AdminPermissions.TRANSACTION_STATUS_CHANGE,
                        ) ? (
                          <th style={{ minWidth: '180px' }}>
                            <span className="text-dark-75">
                              Transaction Status
                            </span>
                          </th>
                        ) : (
                          ''
                        )}
                        {grantPermission(
                          AdminPermissions.BLOCK_MANIPULATION,
                        ) ? (
                          <th style={{ minWidth: '120px' }}>
                            <span className="text-dark-75">Select</span>
                          </th>
                        ) : (
                          ''
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {transactions &&
                        transactions.map(eachTransaction => (
                          <UnassignedTransaction
                            displayReasonsModal={displayReasonsModal}
                            getTransactions={getTransactions}
                            handleChange={handleChange}
                            transactionIds={transactionIds}
                            key={eachTransaction.id}
                            {...eachTransaction}
                            planSubscription={eachTransaction?.planSubscription}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                {totalPages && totalPages > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPages}
                    onChange={handleChangePage}
                  />
                ) : (
                  ''
                )}
                {totalRecord && totalRecord > 10 ? (
                  <NumberOfRows
                    handleChangeLimit={handleChangeLimit}
                    totalRecord={totalRecord}
                  />
                ) : (
                  ''
                )}
              </div>
              {grantPermission(AdminPermissions.BLOCK_MANIPULATION) ? (
                <>
                  <div className="text-right mt-8">
                    <button
                      className="btn btn-light-primary font-weight-bold mr-1"
                      onClick={deselectAll}
                    >
                      Deselect All
                    </button>
                    <button
                      className="btn btn-light-primary font-weight-bold ml-2"
                      onClick={selectAll}
                    >
                      Select All
                    </button>
                  </div>
                  <div className="text-right mt-8">
                    <span className="mr-8">
                      <strong>Selected</strong>:{' '}
                      {transactionIds.length ? transactionIds.length : 0}
                    </span>
                    <button
                      className="btn btn-light-primary font-weight-bold"
                      onClick={() => handleCreateBlockModal(transactionIds)}
                    >
                      Create Blocks
                    </button>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnassignedTransactions;
