import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useHistory } from 'react-router';

const SessionTimeout = ({ isAuthenticated }) => {
  const idleTimer = useRef(null);
  const history = useHistory();

  const onIdle = () => {
    if (isAuthenticated) history.push('/logout');
  };

  return <IdleTimer ref={idleTimer} onIdle={onIdle} timeout={1000 * 60 * 10} />;
};

export default SessionTimeout;
