import React, { useState } from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';

function ForexTable({
  forexDetails: {
    id,
    conversion,
    offeredConversionRate,
    rate,
    ndovuConversionPercentageFee,
  },

  updateManualSpread,
}) {
  const [manualSpread, setManualSpread] = useState(
    ndovuConversionPercentageFee !== undefined ||
      ndovuConversionPercentageFee !== null
      ? ndovuConversionPercentageFee
      : '',
  );

  return (
    <tr className="py-2 my-2">
      <td
        className="text-center  sticky-col first-col"
        style={{
          minWidth: '250px',
        }}
      >
        <span className="text-color d-block font-size-sm">
          {conversion ? conversion : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {rate ? rate : '---'}
        </span>
      </td>
      {grantPermission(AdminPermissions.MANIPULATE_CONVERSIONS_RATE) && (
        <td className="text-center">
          <div className="form-group mb-2 mr-1">
            <input
              className="form-control mt-10"
              type="number"
              placeholder="%"
              name="ndovuConversionPercentageFee"
              value={manualSpread}
              onChange={e => {
                setManualSpread(e.target.value);
                updateManualSpread(id, e);
              }}
              autoComplete="off"
              style={{ width: '100%' }}
            />
          </div>
        </td>
      )}

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {offeredConversionRate ? offeredConversionRate : '---'}
        </span>
      </td>
    </tr>
  );
}

export default ForexTable;
