import React from 'react';
import HighlightedHeader from '../../../common/FormDisplay/HighlightedHeader';
import { Link, useLocation } from 'react-router-dom';
const tableHeaders = [
  {
    id: 1,
    title: 'User ID',
  },
  {
    id: 2,
    title: 'Full Name',
  },
  {
    id: 3,
    title: 'User Email',
  },
  {
    id: 4,
    title: 'View User',
  },
];
const Userstable = ({ users }) => {
  const location = useLocation();
  return (
    <section>
      {users?.length > 0 && (
        <section>
          <HighlightedHeader name={'Linked Users'} />
          <section
            style={{
              marginTop: '1.5rem',
            }}
          >
            <table
              className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center d-flex flex-wrap"
              style={{
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <thead>
                <tr
                  className="text-left text-uppercase"
                  style={{
                    width: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {tableHeaders.map(header => (
                    <th
                      key={header.id}
                      style={{
                        minWidth: '200px',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                      className="pl-7"
                    >
                      <span
                        style={{
                          color: '#3699FF',
                        }}
                      >
                        {header.title}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {users?.map(eachUser => (
                  <tr key={eachUser?.id}>
                    <td
                      className=" text-center"
                      style={{
                        minWidth: '200px',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      <span className="d-block font-size-sm">
                        {eachUser?.id}
                      </span>
                    </td>
                    <td
                      className="text-center"
                      style={{
                        minWidth: '200px',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      <span className="d-block font-size-sm">
                        {eachUser?.firstName + ' ' + eachUser?.lastName}
                      </span>
                    </td>
                    <td
                      className=" text-center"
                      style={{
                        minWidth: '200px',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      <span className="d-block font-size-sm">
                        {eachUser?.email}
                      </span>
                    </td>
                    <td
                      className=" text-center"
                      style={{
                        minWidth: '200px',
                        textAlign: 'center',
                        margin: 'auto',
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/users/${eachUser.id}`,
                          state: {
                            prevUrl: location.pathname,
                          },
                        }}
                        className="btn btn-light-primary  font-size-sm"
                      >
                        View User
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </section>
      )}
    </section>
  );
};

export default Userstable;
