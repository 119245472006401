import jwtDecode from 'jwt-decode';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const authToken = localStorage.getItem('ndovuAdminToken');
      if (authToken && authToken !== null) {
        const decodedToken = jwtDecode(authToken);
        if (decodedToken.exp * 1000 < Date.now()) {
          localStorage.removeItem('ndovuAdminToken');
          window.location.replace('/');
          return;
        }
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    err => Promise.reject(err),
  );
}
