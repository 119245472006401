import React from 'react';

import './index.scss';

const CommonButton = ({
  icon,
  alt,
  className,
  btnText,
  onClick,
  type,
  loading,
  disabled,
}) => {
  return (
    <button
      type={type ? type : 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img className="mr-3" src={icon} alt={alt} />}
      {btnText}
      {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};

export default CommonButton;

export const ExportButton = ({ loading, exportDocument, btnText }) => {
  return (
    <button
      style={{
        background: '#0071CE',
        borderRadius: '5px',
        padding: '0 20px',
        height: '41px',
        border: '1px solid #0071CE',
        color: '#ffffff',
      }}
      onClick={exportDocument}
      type='button'
    >
      {btnText}
      {loading && <span className="mx-3 spinner spinner-white"></span>}
    </button>
  );
};
