import React, { useState, useEffect } from 'react';
import './FundInstance.scss';

import { updateFund } from '../_redux/blocksCrud';
import { useHistory } from 'react-router-dom';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import { BlockType } from '../../../helpers/constants';
import moment from 'moment';
import WarningIconTwo from '../../../icons/exclamation-round-line.svg';
import ChecksModal from '../../../common/ChecksModal';
import { currencyHelper } from '../../../helpers/helper';

const FundInstance = props => {
  const {
    status,
    id: fundInstanceId,
    investmentTotals,
    askPrice,
    bidPrice,
    transactions,
    blockId,
    plan: {
      id: planId,
      currency,
      name,
      identifier,
      coolName,
      fundManager: { id: fundManagerId, fundname: fundManagerName },
      alertVariationPercentage,
    },
  } = props;

  const previousTradingPrices = props?.previousTradingPrices;
  const previousAskPrice = previousTradingPrices?.askPrice;
  const previousBidPrice = previousTradingPrices?.bidPrice;
  const day = previousTradingPrices?.day;

  const [askAmount, setAskAmount] = useState(askPrice);
  const [bidAmount, setBidAmount] = useState(bidPrice);
  const [askAndBidPriceChanged, setaskAndBidPriceChanged] = useState({
    askAmount,
    bidAmount,
  });
  const [loadData, setLoadData] = useState(false);

  const [increase, setIncrease] = useState({
    askPrice: askAmount,
    bidPrice: bidAmount,
  });
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const history = useHistory();

  const performChecks = () => {
    const allAlerts = [];
    const askPriceIncrease =
      askAmount && Number(askAmount) !== 0 && previousAskPrice
        ? Number(askAmount) - Number(previousAskPrice)
        : 0;

    const bidPriceIncrease =
      bidAmount && Number(bidAmount) !== 0 && previousBidPrice
        ? Number(bidAmount) - Number(previousBidPrice)
        : 0;

    const askPricePercentageIncrease =
      askPriceIncrease && askPriceIncrease !== 0
        ? (askPriceIncrease / Number(previousAskPrice)) * 100
        : 0;

    const bidPricePercentageIncrease =
      bidPriceIncrease && bidPriceIncrease !== 0
        ? (bidPriceIncrease / Number(previousBidPrice)) * 100
        : 0;
    setIncrease({
      askPrice: askPricePercentageIncrease,
      bidPrice: bidPricePercentageIncrease,
    });
    if (Number(bidAmount) > Number(askAmount)) {
      const messageText = 'Confirming that the bid is higher than the ask.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }
    if (Number(askPricePercentageIncrease) > alertVariationPercentage) {
      const messageText =
        'The new Ask price differs considerably from the old one.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }
    if (Number(bidPricePercentageIncrease) > alertVariationPercentage) {
      const messageText =
        'The new Bid price differs considerably from the old one.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }

    setAlerts([...allAlerts]);
  };

  const selectOptions = value => {
    const newData = [...selectedOptions];
    const valueExist = newData.includes(value);
    if (valueExist) {
      const index = newData.indexOf(value);
      newData.splice(index, 1);
      setSelectedOptions(newData);
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handlePriceModal = async id => {
    try {
      const response = await updateFund(
        id,
        Number(askAmount),
        Number(bidAmount),
      );

      if (response) {
        const askPrice = response.data.data.askPrice;
        const bidPrice = response.data.data.bidPrice;
        setAskAmount(askPrice);
        setBidAmount(bidPrice);
        setaskAndBidPriceChanged({
          askPrice: askPrice,
          bidPrice: bidPrice,
        });
        setSelectedOptions([]);
        setOpen(false);
      }
    } catch (error) {
      setOpen(false);
    }
  };

  const checkBlockTransactionType = transactions => {
    let deposits = 0;
    let withdrawals = 0;
    for (const transaction of transactions) {
      transaction.operationType === BlockType.DEPOSIT
        ? (deposits += 1)
        : (withdrawals += 1);
    }
    if (deposits === transactions.length) {
      return BlockType.DEPOSIT;
    } else if (withdrawals === transactions.length) {
      return BlockType.WITHDRAW;
    } else {
      return BlockType.ALL;
    }
  };

  const BlockTotals = ({ unitDescription, unitValue }) => {
    return (
      <div className="font-weight-bold total-transactions">
        <span> {unitDescription} </span>{' '}
        <span style={{ color: '#0071CE' }}>{unitValue}</span>
      </div>
    );
  };

  useEffect(() => {
    performChecks();
  }, [askAmount, bidAmount]);

  useEffect(() => {
    let total = 0;
    transactions.length > 0 &&
      transactions.forEach(transaction => {
        total = total + transaction.convertedFinalAmount;
      });
    checkBlockTransactionType(transactions);
  }, []);
  return (
    <div key={fundInstanceId} className="card card-custom gutter-b mb-5 pb-5">
      <div className="card-body">
        <div className="tab-content special-content">
          <div className="table-responsive">
            <table className="table  table-vertical-center">
              <thead className="main-table-head">
                <tr className="text-center text-uppercase">
                  <th style={{ minWidth: '200px' }} className="pl-7">
                    <span className="text-dark-75 special-content">
                      FundInstance ID
                    </span>
                  </th>
                  <th style={{ minWidth: '150px' }}>
                    <span className="text-dark-75">Plan ID</span>
                  </th>
                  <th style={{ minWidth: '200px' }}>
                    <span className="text-dark-75">Fund Name</span>
                  </th>
                  <th style={{ minWidth: '150px' }}>
                    <span className="text-dark-75">Fund Cool Name</span>
                  </th>
                  <th style={{ minWidth: '150px' }}>
                    <span className="text-dark-75">Identifier</span>
                  </th>
                  <th style={{ minWidth: '200px' }}>
                    <span className="text-dark-75">Fund Manager ID</span>
                  </th>
                  <th style={{ minWidth: '200px' }}>
                    <span className="text-dark-75">Fund Manager Name</span>
                  </th>
                  <th style={{ minWidth: '120px' }}>
                    <span className="text-dark-75">Provider ID</span>
                  </th>
                  <th style={{ minWidth: '200px' }}>
                    <span className="text-dark-75">Provider Name</span>
                  </th>
                  <th style={{ minWidth: '120px' }}>
                    <span className="text-dark-75">Bid Price</span>
                  </th>
                  <th style={{ minWidth: '120px' }}>
                    <span className="text-dark-75">Ask Price</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0 py-8 text-center">
                    <div className="special-color mb-1 font-size-lg">
                      {fundInstanceId}
                    </div>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {planId}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {name}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {coolName}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {identifier}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {fundManagerId}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {fundManagerName}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {''}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {''}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {bidPrice}
                    </span>
                  </td>
                  <td className="text-center">
                    <span className="special-color  d-block font-size-lg">
                      {askPrice}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ display: 'flex' }} className="mt-5 mb-15">
          <form className="form-inline">
            <div
              style={{
                maxWidth: '180px',
              }}
            >
              <div className="form-group mb-2 mr-1">
                <label className="mr-1 font-weight-bold">Bid Price</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Bid"
                  name="bidAmount"
                  value={bidAmount || ''}
                  min={0}
                  onChange={e => setBidAmount(e.target.value)}
                  readOnly={status === 'COMPLETED'}
                  autoComplete="off"
                  style={{ width: '100%' }}
                />
              </div>
              <div
                style={{
                  marginTop:
                    Number(increase?.bidPrice) > alertVariationPercentage
                      ? '-12px'
                      : '0',
                }}
              >
                <div>
                  {`Previous Price: ${previousBidPrice?.toFixed(2)}`}
                  {Number(increase?.bidPrice) > alertVariationPercentage ? (
                    <div style={{ display: 'inline', marginLeft: '5px' }}>
                      <img src={WarningIconTwo} />
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    marginTop:
                      Number(increase?.bidPrice) > alertVariationPercentage
                        ? '-15px'
                        : 0,
                  }}
                >
                  Date: {moment(day).format('l')}
                </div>
                <div>{`Difference: ${increase?.bidPrice?.toFixed(2)}%`}</div>
              </div>
            </div>

            <div
              style={{
                maxWidth: '180px',
                marginLeft: '10px',
              }}
            >
              <div
                style={{
                  marginTop:
                    Number(increase?.askPrice) > alertVariationPercentage
                      ? '5px'
                      : 0,
                }}
                className="form-group mb-2 mr-2"
              >
                <label className="mr-1 font-weight-bold">Ask Price</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Ask"
                  name="askAmount"
                  value={askAmount || ''}
                  min={0}
                  onChange={e => setAskAmount(e.target.value)}
                  readOnly={status === 'COMPLETED'}
                  autoComplete="off"
                  style={{ width: '100%' }}
                />
              </div>
              <div
                style={{
                  marginTop:
                    Number(increase?.askPrice) > alertVariationPercentage
                      ? '-12px'
                      : '0',
                }}
              >
                <div>
                  {`Previous Price: ${previousAskPrice?.toFixed(2)}`}
                  {Number(increase?.askPrice) > alertVariationPercentage ? (
                    <div style={{ display: 'inline', marginLeft: '5px' }}>
                      <img src={WarningIconTwo} />
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    marginTop:
                      Number(increase?.askPrice) > alertVariationPercentage
                        ? '-15px'
                        : '0',
                  }}
                >
                  Date: {moment(day).format('l')}
                </div>
                <div>{`Difference: ${increase?.askPrice?.toFixed(2)}%`}</div>
              </div>
            </div>

            {grantPermission(AdminPermissions.BLOCK_COMPLETION) ? (
              <button
                type="submit"
                className={`btn btn-light-primary mb-8 px-9 save-btn`}
                disabled={loadData || status === 'COMPLETED'}
                style={{ marginTop: '-20px', marginLeft: '10px' }}
                onClick={e => {
                  e.preventDefault();
                  setOpen(true);
                }}
              >
                {askAndBidPriceChanged?.askPrice ? 'Edit' : 'Save'}
                {loadData && <span className="ml-3 spinner spinner-white" />}
              </button>
            ) : (
              ''
            )}

            {grantPermission(AdminPermissions.BLOCK_VIEW) ? (
              <button
                className="blue-button view-transactions-btn"
                onClick={() =>
                  history.push(
                    `/block/${blockId}/fund-instance/${fundInstanceId}`,
                  )
                }
              >
                View transactions
              </button>
            ) : (
              ''
            )}
          </form>
        </div>
        <ChecksModal
          alerts={alerts}
          open={open}
          handleClose={() => setOpen(false)}
          handleSubmit={() => handlePriceModal(fundInstanceId)}
          selectedOptions={selectedOptions}
          selectOptions={selectOptions}
          modalTitle={'Are you sure you want to set the price?'}
        />
        {checkBlockTransactionType(transactions) === BlockType.ALL ? (
          <div className="d-flex justify-content-evenly">
            <BlockTotals
              unitDescription={'Total Deposited Amount:'}
              unitValue={currencyHelper(
                investmentTotals?.totalDepositAmnt,
                currency,
              )}
            />
            <BlockTotals
              unitDescription={'Total Deposited Units:'}
              unitValue={investmentTotals?.totalDepositUnits?.toFixed(4)}
            />
            <BlockTotals
              unitDescription={'Total Disinvested Amount:'}
              unitValue={currencyHelper(
                investmentTotals?.totalWidthdrawAmnt,
                currency,
              )}
            />
            <BlockTotals
              unitDescription={'Total Disinvested Units:'}
              unitValue={investmentTotals?.totalWithdrawUnits?.toFixed(4)}
            />
          </div>
        ) : checkBlockTransactionType(transactions) === BlockType.DEPOSIT ? (
          <div className="d-flex justify-content-evenly">
            <BlockTotals
              unitDescription={'Total Deposited Amount:'}
              unitValue={currencyHelper(
                investmentTotals?.totalDepositAmnt,
                currency,
              )}
            />
            <BlockTotals
              unitDescription={'Total Deposited Units:'}
              unitValue={investmentTotals?.totalDepositUnits?.toFixed(4)}
            />
          </div>
        ) : (
          <div className="d-flex justify-content-evenly">
            <BlockTotals
              unitDescription={'Total Disinvested Amount:'}
              unitValue={currencyHelper(
                investmentTotals?.totalWidthdrawAmnt,
                currency,
              )}
            />
            <BlockTotals
              unitDescription={'Total Disinvested Units:'}
              unitValue={investmentTotals?.totalWithdrawUnits?.toFixed(4)}
            />
          </div>
        )}

        <div className="separator separator-dashed my-10 font-weight-bold" />
      </div>
    </div>
  );
};

export default FundInstance;
