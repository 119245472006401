import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { fetchArchivedTransactions } from '../_redux/archivedTransactionsCrud';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ArchivedTransaction from './archivedTransaction';
import '../../UnassignedTransactions/pages/index.scss';

import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import {
  TransactionType,
  AdminPermissions,
  TransactionStatus,
} from '../../../helpers/constants';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { DateInput } from '../../../common/DateInput';
import handleAlert from '../../../common/handleAlert';

const ArchivedTransactions = () => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [allTransactions, setAllTransactions] = useState([]);
  const [investmentType, setInvestmentType] = useState(null);
  const [searchItem, setSearchItem] = useState('');
  const [dateValues, setDateValues] = useState({});

  useEffect(() => {
    getTransactionsArchived();
  }, []);

  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };

  const getTransactionsArchived = async () => {
    try {
      setLoading(true);
      const {
        data: { items, meta },
      } = await fetchArchivedTransactions(
        investmentType,
        page,
        limit,
        searchItem,
        dateValues?.startDate,
        dateValues?.endDate,
        TransactionStatus.ARCHIVED,
      );
      setAllTransactions(items);
      setTransactions(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelect = async e => {
    const { value: selectedValue } = e.target;
    let res;
    switch (selectedValue) {
      case 'all':
        res = await fetchArchivedTransactions(
          '',
          page,
          limit,
          searchItem,
          dateValues?.startDate,
          dateValues?.endDate,
          TransactionStatus.ARCHIVED,
        );
        setTransactions(res.data.items);
        setInvestmentType('');
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        break;
      case 'plans':
        res = await fetchArchivedTransactions(
          TransactionType.DIRECT,
          page,
          limit,
          searchItem,
          dateValues?.startDate,
          dateValues?.endDate,
          TransactionStatus.ARCHIVED,
        );
        setTransactions(res.data.items);
        setInvestmentType(TransactionType.DIRECT);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        break;
      case 'goals':
        res = await fetchArchivedTransactions(
          TransactionType.GOAL,
          page,
          limit,
          searchItem,
          dateValues?.startDate,
          dateValues?.endDate,
          TransactionStatus.ARCHIVED,
        );
        setTransactions(res.data.items);
        setInvestmentType(TransactionType.GOAL);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        break;
      default:
        setTransactions(allTransactions);
        setInvestmentType(investmentType);
    }
  };

  const handleChangePage = async (event, value) => {
    const res = await fetchArchivedTransactions(
      investmentType,
      value,
      limit,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      TransactionStatus.ARCHIVED,
    );
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTransactions(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };
  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await fetchArchivedTransactions(
      investmentType,
      1,
      selectedValue,
      searchItem,
      dateValues?.startDate,
      dateValues?.endDate,
      TransactionStatus.ARCHIVED,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setTransactions(res.data.items);
    }
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await fetchArchivedTransactions(
      investmentType,
      1,
      limit,
      value,
      dateValues?.startDate,
      dateValues?.endDate,
      TransactionStatus.ARCHIVED,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setTransactions(res.data.items);
    }
  };

  const searchForArchivedTransactions = async (
    investmentType,
    value,
    dateValues,
  ) => {
    try {
      if (investmentType || value || dateValues) {
        setLoading(true);
        const res = await fetchArchivedTransactions(
          investmentType,
          1,
          limit,
          value,
          dateValues?.startDate,
          dateValues?.endDate,
          TransactionStatus.ARCHIVED,
        );
        setSearchItem(value);
        if (res.status === 200) {
          setTotalRecord(res.data.meta.totalItems);
          setTotalPages(res.data.meta.totalPages);
          setTransactions(res.data.items);
          handleAlert(res.data.message, 'success');
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <div className="card card-custom gutter-b">
          <div className="card-header">
            <h4 className="pt-8">Archived Transactions</h4>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            <select
              className="btn btn-light-primary font-weight-bold mb-5"
              style={{
                paddingTop: '10px',
                paddingBottom: '10px',
                height: '40px',
              }}
              onChange={handleSelect}
            >
              <option value="all">Filters</option>
              <option value="all">All transactions</option>
              <option value="plans">Direct Investments</option>
              <option value="goals">Goal Investments</option>
            </select>
            <div className="mb-2 mr-2">
              <p className="font-weight-bold text-center py-2 bg-primary text-white">
                DATE EXECUTED
              </p>
              <DateInput
                name="startDate"
                label="Start Date"
                handleDateChange={handleDateChange}
              />
              <DateInput
                name="endDate"
                label="End Date"
                handleDateChange={handleDateChange}
                classes="ml-2"
              />
            </div>
            <div className="form-group mb-2 mr-1">
              <input
                className="form-control"
                type="text"
                placeholder="Search by fund name, email ,phone and reference"
                name="plan"
                min={0}
                style={{
                  width: '300px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                }}
                onChange={handleSearch}
              />
            </div>
            <div>
              <button
                className="btn btn-primary px-6 py-2 mb-2 mr-1"
                onClick={e => {
                  e.preventDefault();
                  searchForArchivedTransactions(
                    investmentType,
                    searchItem,
                    dateValues,
                  );
                }}
              >
                Search
                <span className="svg-icon svg-icon-light ml-2">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                    alt="search icon"
                  />
                </span>
                {loading && <span className="ml-3 spinner spinner-white" />}
              </button>
            </div>
          </div>

          <div className="card-body mt-3 mb-15 pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive transaction-table">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-uppercase text-center">
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Investor Name</span>
                      </th>
                      <th style={{ minWidth: '150px' }} className="pl-7">
                        <span className="text-dark-75">Transaction ID</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Investment Type</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Operation Type</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Source</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Date Executed</span>
                      </th>
                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Investor ID</span>
                      </th>
                      <th style={{ minWidth: '200px' }}>
                        <span className="text-dark-75">Investor Reference</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Investor Email</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">KYC Status</span>
                      </th>

                      <th style={{ minWidth: '200px' }}>
                        <span className="text-dark-75">Fund Manager Name</span>
                      </th>

                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Fund Name</span>
                      </th>

                      <th style={{ minWidth: '180px' }}>
                        <span className="text-dark-75">Fund Cool Name</span>
                      </th>

                      <th style={{ minWidth: '180px' }}>
                        <span className="text-dark-75">Payment Channel</span>
                      </th>

                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Original amount</span>
                      </th>

                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Discount amount</span>
                      </th>

                      <th style={{ minWidth: '220px' }}>
                        <span className="text-dark-75">
                          Final converted amount
                        </span>
                      </th>
                      <th style={{ minWidth: '120px' }}>
                        <span className="text-dark-75">Conversion</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Conversion rate</span>
                      </th>
                      <th style={{ minWidth: '150px' }}>
                        <span className="text-dark-75">Ndovu fee</span>
                      </th>
                      <th style={{ minWidth: '200px' }}>
                        <span className="text-dark-75">
                          Ndovu fee percentage
                        </span>
                      </th>

                      <th style={{ minWidth: '200px' }}>
                        <span className="text-dark-75">
                          Applied conversion rate
                        </span>
                      </th>
                      <th style={{ minWidth: '180px' }}>
                        <span className="text-dark-75">Reason</span>
                      </th>
                      {grantPermission(
                        AdminPermissions.TRANSACTION_REFUND_CHANGE,
                      ) ? (
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Refunded</span>
                        </th>
                      ) : (
                        ''
                      )}
                      {grantPermission(
                        AdminPermissions.TRANSACTION_STATUS_CHANGE,
                      ) ? (
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">
                            Transaction Status
                          </span>
                        </th>
                      ) : (
                        ''
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {transactions &&
                      transactions.map(eachTransaction => (
                        <ArchivedTransaction
                          getTransactionsArchived={getTransactionsArchived}
                          dateValues={dateValues}
                          key={eachTransaction.id}
                          {...eachTransaction}
                        />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArchivedTransactions;
