import axios from 'axios';
import dotenv from 'dotenv';
import { clearConfigCache } from 'prettier';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_ALL_PARTNERS = `${REACT_APP_BASE_URL}/v1/admin/partners`;

export const fetchAllPartners = async () => {
  const res = await axios.get(FETCH_ALL_PARTNERS);
  return res;
};
