import dotenv from 'dotenv';
import axios from 'axios';
import { downloadFile } from '../helpers/downloadFile';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const exportToExcel = async (setExportLoading, url, params) => {
  setExportLoading(true);
  try {
    const EXPORT_TO_EXCEL = `${REACT_APP_BASE_URL}/${url}`;
    const res = await axios.get(EXPORT_TO_EXCEL, {params, responseType: "arraybuffer"});
    downloadFile(res);
    setExportLoading(false);
  } catch (error) {
    setExportLoading(false);
  }
};
