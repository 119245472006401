import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { AdminPermissions } from '../../../helpers/constants';
import { fetchAdmins, updateAdminStatus } from '../_redux/adminCrud';
import Admin from './Admins';

const Admins = () => {
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const getAdmins = async () => {
    try {
      setLoading(true);
      const res = await fetchAdmins(page, limit);
      if (res.status === 200) {
        const { items, meta } = res.data;
        setAdmins(items);
        setTotalRecord(meta.totalItems);
        setTotalPages(meta.totalPages);
        setLoading(false);
      }
    } catch (error) {}
  };

  const handleChangePage = async (event, value) => {
    const res = await fetchAdmins(value, limit);
    if (res.status === 200) {
      const { items, meta } = res.data;
      setAdmins(items);
      setPage(value);
      setTotalRecord(meta.totalItems);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    setLimit(selectedValue);
    const res = await fetchAdmins(1, selectedValue);
    if (res.status === 200) {
      const { items, meta } = res.data;
      setAdmins(items);
      setTotalRecord(meta.totalItems);
      setTotalPages(meta.totalPages);
    }
  };

  const handleActivation = async (id, status, label, names) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you want to ${label} ${names}'s account?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${label} it!`,
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await updateAdminStatus(id, status);
        swalWithBootstrapButtons
          .fire('Updates!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getAdmins();
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  useEffect(() => {
    getAdmins();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div className="card-header">
          <div className="my-5 d-flex flex-wrap w-100 justify-content-between align-items-center">
            <h4 className="pt-8">Admins</h4>
            {grantPermission(AdminPermissions.SUPER_ADMIN) ? (
              <Link
                style={{
                  background: '#0071CE',
                  borderRadius: '5px',
                  padding: '8px 20px',
                  border: '1px solid #0071CE',
                  color: '#ffffff',
                }}
                to="/admin-create"
              >
                Create Account
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="card-body mt-3 mb-10 pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th style={{ minWidth: '250px' }} className="pl-7">
                      <span className="text-dark-75">Names</span>
                    </th>
                    <th style={{ minWidth: '250px' }}>
                      <span className="text-dark-75">E-mail</span>
                    </th>
                    <th style={{ minWidth: '250px' }}>
                      <span className="text-dark-75">Status</span>
                    </th>
                    <th style={{ minWidth: '180px' }}>
                      <span className="text-dark-75">Date Registered</span>
                    </th>
                    {grantPermission(AdminPermissions.SUPER_ADMIN) ? (
                      <>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">View Permissions</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Action</span>
                        </th>
                      </>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>
                <tbody>
                  {admins &&
                    admins.map(eachAdmin => (
                      <Admin
                        key={eachAdmin.id}
                        {...eachAdmin}
                        handleActivation={handleActivation}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            {totalPages && totalPages > 0 ? (
              <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
              />
            ) : (
              ''
            )}
            {totalRecord && totalRecord > 10 ? (
              <NumberOfRows
                handleChangeLimit={handleChangeLimit}
                totalRecord={totalRecord}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
