import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { DateInput } from '../DateInput';
import moment from 'moment';

function getModalStyle() {
  const top = 49;
  const left = 44;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const CustomDateRange = ({
  open,
  setOpen,
  handleDateChange,
  searchTransaction,
  operation,
  partnerId,
  userStatus,
  dateFilter,
  dateValues,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const handleClose = e => {
    e.preventDefault();
    setOpen(false); // Close the modal when called

    searchTransaction(operation, partnerId, userStatus, dateFilter, dateValues);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section style={modalStyle} className={classes.paper}>
        <DateInput
          name="startDate"
          label="Start Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
        />
        <DateInput
          name="endDate"
          label="End Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
        />
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div />
          <button
            onClick={handleClose}
            id="kt_login_signin_submit"
            className={`btn btn-primary font-weight-bold `}
          >
            <span>Search</span>
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default CustomDateRange;
