import React from 'react';
import FileIcon from './FileIcon';
import FileUpload from './FileUpload';

const UploadFile = ({
  handleChange,
  files,
  name,
  title,
  document,
  displayTitle,
}) => {
  const FILE_SIZE = 5 * 1024 * 1024;
  const uploadElements = (type, uploads) => {
    return uploads.map((upload, i) => {
      return (
        <div key={i}>
          {upload.file.size > FILE_SIZE && upload.name === type ? (
            <div
              className="font-weight-bold fv-plugins-message-container"
              style={{ color: '#F05B7F' }}
            >
              {'Max 5MB'}
            </div>
          ) : (
            upload.name === type && (
              <div className="d-flex" style={{ marginTop: '5px' }}>
                <FileIcon />
                <span style={{ color: '#1e1e1e', marginTop: '6px' }}>
                  {upload?.file?.name}
                </span>
              </div>
            )
          )}
        </div>
      );
    });
  };
  return (
    <div className="my-5">
      <FileUpload
        title={title}
        name={name}
        handleChange={handleChange}
        displayText={
          files.some(val => val.name === name) || document
            ? 'Change File'
            : 'Choose File'
        }
        displayTitle={displayTitle}
      />
      <div className="">
        <div className="" />
        <div className="font-weight-bold" style={{ color: '#F05B7F' }}>
          {uploadElements(name, files)}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
