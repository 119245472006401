import React, { useEffect, useState } from 'react';
import BackIcon from '../../../icons/backIcon.svg';
import {
  completeBlock,
  fetchBlock,
  removeBlock,
  sendBulkEmail,
} from '../_redux/blocksCrud';

import 'dotenv/config';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import ErrorCard from '../../../common/ErrorCard';
import { AdminPermissions } from '../../../helpers/constants';
import FundInstance from './FundInstance';

const SingleFund = props => {
  const history = useHistory();
  const { blockId } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [fund, setFund] = useState({});
  const [fundInstances, setFundInstances] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const getFund = async id => {
    try {
      setLoading(true);
      const res = await fetchBlock(id);
      if (res.status === 200) {
        setLoading(false);
        setFund(res.data.data);
        setFundInstances(res.data.data.fundInstances);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleDeleteBlockModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await removeBlock(id);
        swalWithBootstrapButtons
          .fire('Deleted!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/blocks');
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleCompleteBlockModal = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to complete the block?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, complete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      try {
        const response = await completeBlock(id);

        swalWithBootstrapButtons
          .fire('Completed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/blocks');
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleSendBulkEmail = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure you want to send these emails?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, send them!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await sendBulkEmail(id);
        swalWithBootstrapButtons
          .fire('Completed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              history.push('/blocks');
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  useEffect(() => {
    getFund(blockId);
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  const { id, createdAt, status } = fund;
  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/blocks'} errorMessage={errorMessage} />
      ) : (
        <>
          <Link to={'/blocks'}>
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </Link>
          <div className="mt-2">
            <div
              className="card card-custom gutter-b mb-3 mt-0"
              style={{ height: '250px' }}
            >
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center flex-wrap my-15">
                  <div>
                    <p>
                      Block: {id} <br />
                      Timestamp block creation:{' '}
                      {moment(createdAt).format('YYYY-MM-DD hh:mm:ss')}
                    </p>
                    <p>
                      Status: <span style={{ color: '#0071CE' }}>{status}</span>
                    </p>
                  </div>

                  {grantPermission(AdminPermissions.BLOCK_MANIPULATION) ? (
                    <button
                      disabled={status && status.toLowerCase() === 'completed'}
                      className="btn btn-light-primary mr-2"
                      onClick={() => handleDeleteBlockModal(blockId)}
                    >
                      Remove block
                    </button>
                  ) : (
                    ''
                  )}
                  {grantPermission(AdminPermissions.BLOCK_COMPLETION) ? (
                    <div>
                      <button
                        className="btn btn-light-primary mt-1 mt-lg-0 mt-md-0 mr-2"
                        disabled={status === 'COMPLETED'}
                        onClick={() => handleCompleteBlockModal(blockId)}
                      >
                        Complete block
                      </button>
                      <button
                        className="btn btn-light-primary mt-1 mt-lg-0 mt-md-0"
                        disabled={status !== 'COMPLETED'}
                        onClick={() => handleSendBulkEmail(blockId)}
                      >
                        Send Email
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            <div>
              {fundInstances.map(eachData => (
                <FundInstance
                  key={eachData.id}
                  {...eachData}
                  blockId={blockId}
                  status={status}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default injectIntl(SingleFund);
