import React from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import CommonInput from '../../../common/CommonInput';
import DisabledInput from '../../../common/DisabledInput';
import HighlightedHeader from '../../../common/FormDisplay/HighlightedHeader';
import { AdminPermissions } from '../../../helpers/constants';

function FormDisplayWithButton({ name, items, onSubmit, loadingData }) {
  return (
    <>
      <HighlightedHeader name={name} />
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
        className="d-flex flex-wrap w-75"
      >
        {items?.map(item =>
          item?.enabled &&
          grantPermission(AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO) ? (
            item?.isSelectBox ? (
              <div key={item.id} className="form-row-container">
                <select
                  className="btn btn-light-primary  custom-display-form-select-box"
                  onChange={item?.onChange}
                  defaultValue={item?.value}
                >
                  {item?.title && <option value=""> {item?.title}</option>}

                  {item?.options?.map(option => (
                    <option
                      key={option.value}
                      data-id={option.value}
                      value={option.value || option.name}
                      name={item?.name}
                    >
                      {(option.name || option.value).toUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <CommonInput
                key={item.id}
                id={item.id}
                label={item.label}
                value={item.value}
                type={item.type}
                onChange={item?.onChange}
                name={item?.name}
              />
            )
          ) : (
            <DisabledInput
              key={item.id}
              id={item.id}
              label={item.label}
              value={item.value}
              type={item.type}
              kycMatch={item.kycMatch}
            />
          ),
        )}
        {grantPermission(AdminPermissions.MANIPULATE_USER_SENSITIVE_INFO) && (
          <div className="mt-15 font-weight-bold text-center sectional-select-box">
            <button
              type="submit"
              className="btn  text-white  font-weight-bold px-15 py-4"
              style={{
                background: '#0071CE',
                width: '60%',
                paddingRight: '30px',
                margin: 'auto',
              }}
            >
              Save
              {loadingData && <span className="ml-3 spinner spinner-white" />}
            </button>
          </div>
        )}
      </form>
    </>
  );
}

export default FormDisplayWithButton;
