import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import ChecksModal from '../../../common/ChecksModal';
import { ExportButton } from '../../../common/CommonBtn';
import { DateInput } from '../../../common/DateInput';
import ErrorCard from '../../../common/ErrorCard';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { AdminPermissions } from '../../../helpers/constants';
import { exportToExcel } from '../../../utils/exportToExcel';
import {
  DAILY_TRADES_EXPORT_PATH,
  fetchAllDailyTrades,
  saveDailyTrade,
} from '../_redux/fundCrud';
import DailyTrades from './DailyTrades';

const AllDailyTrades = () => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [askAndBidPrices, setAskAndBidPrices] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [dateQuery, setDateQuery] = useState(moment().format('YYYY-MM-DD'));
  const [isCurrentDay, setIsCurrentDay] = useState(true);
  const [downloading, setDownloading] = useState(false);

  /**
   * Fetch daily trades values based on different query parameters
   */
  const getDailyTrades = async selectedDate => {
    const selectedDateQuery = selectedDate ? selectedDate : dateQuery;
    try {
      const res = await fetchAllDailyTrades(
        page,
        limit,
        searchQuery,
        selectedDateQuery,
      );

      if (res.data) {
        setLoading(false);
        handleSetPlansData(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        window.scrollTo({ behavior: 'smooth', top: '0px' });
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDailyTrades();
  }, [page, limit, searchQuery, dateQuery]);

  const handleChangePage = (_event, value) => setPage(value);

  const handleSearch = e => setSearchQuery(e.target.value);

  const handleChangeLimit = event => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handleSetPlansData = plansFromResponse => {
    setPlans(plansFromResponse);

    const newBidAndAskPrices = [];
    plansFromResponse.forEach(({ id, dailyTrade }) => {
      const index = askAndBidPrices.findIndex(i => i.planId === id);
      if (index === -1) {
        newBidAndAskPrices.push({
          planId: id,
          askPrice: dailyTrade?.askPrice,
          bidPrice: dailyTrade?.bidPrice,
        });
      }
    });

    setAskAndBidPrices([...askAndBidPrices, ...newBidAndAskPrices]);
  };

  const selectOptions = value => {
    const newData = [...selectedOptions];
    const valueExist = newData.includes(value);
    if (valueExist) {
      const index = newData.indexOf(value);
      newData.splice(index, 1);
      setSelectedOptions(newData);
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const updateBidAndAskPrices = (planId, event) => {
    const index = askAndBidPrices.findIndex(plan => plan.planId === planId);
    const updatedPrices = [...askAndBidPrices];

    updatedPrices[index] = {
      ...updatedPrices[index],
      [event.target.name]: Number(event.target.value),
    };

    setAskAndBidPrices(updatedPrices);
  };

  const getCurrentBidPrice = id => {
    const myPlan = askAndBidPrices.filter(plan => plan?.planId === id);
    return myPlan[0]?.bidPrice;
  };

  const getCurrentAskPrice = id => {
    const myPlan = askAndBidPrices.filter(plan => plan?.planId === id);
    return myPlan[0]?.askPrice;
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-light-success',
      cancelButton: 'btn btn-light-danger mr-1',
    },
    buttonsStyling: false,
  });

  const handleSaveModal = async () => {
    let allBidAndAskPricesAreFilled = false;
    for (let i = 0; i < askAndBidPrices.length; i++) {
      const plan = askAndBidPrices[i];
      if (!plan.askPrice || !plan.bidPrice) {
        allBidAndAskPricesAreFilled = false;
        return;
      } else {
        allBidAndAskPricesAreFilled = true;
      }
    }
    if (allBidAndAskPricesAreFilled) {
      try {
        const response = await saveDailyTrade(askAndBidPrices);
        if (response.data) {
          swalWithBootstrapButtons.fire(
            'Success',
            'You have saved all ask and bid prices',
            'success',
          );
          setOpen(false);
          setPage(1);
        }
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
        setOpen(false);
      }
    } else {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        'Please put values for all ask and bid prices',
        'error',
      );
      setOpen(false);
    }
  };

  const checkDailyTradeValues = plans => {
    return plans.every(
      plan => plan?.dailyTrade?.askPrice && plan?.dailyTrade?.bidPrice,
    );
  };

  const checkAskBidPricesFilledBeforeSave = dailyTrades => {
    return (
      askAndBidPrices.length === totalRecord &&
      dailyTrades.every(
        dailyTrade => dailyTrade?.askPrice && dailyTrade?.bidPrice,
      )
    );
  };

  const checkDateIsToday = givenDate =>
    moment()
      .startOf('day')
      .isSame(moment(givenDate).startOf('day'));

  const handleDateChange = event => {
    // Reset bid and ask prices values when a date is changed
    setAskAndBidPrices([]);

    // When the modal is cleared the default day will be today
    const selectedDate = event.target.value || moment().format('YYYY-MM-DD');
    setIsCurrentDay(checkDateIsToday(selectedDate));
    setDateQuery(selectedDate);
    getDailyTrades(selectedDate);
    setOpen(false);
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div>
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <h4 className="pt-8">All Daily Trades</h4>
            </div>
            <form className="form-inline my-1">
              <div className="form-group">
                <DateInput
                  name="searchDate"
                  label=""
                  handleDateChange={handleDateChange}
                  max={moment().format('YYYY-MM-DD')}
                />
              </div>
              <div className="form-group mx-3">
                <input
                  type="text"
                  name="fundName"
                  className="form-control ml-5 mr-5 w-100 mt-5"
                  placeholder="Search by fund name or identifier"
                  onChange={handleSearch}
                  autoComplete="off"
                />
              </div>
              <div className="mt-5">
                {grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) ? (
                  <ExportButton
                    exportDocument={() =>
                      exportToExcel(
                        setDownloading,
                        `${DAILY_TRADES_EXPORT_PATH}?date=${dateQuery}`,
                      )
                    }
                    loading={downloading}
                    btnText={'Export to Excel'}
                  />
                ) : (
                  ''
                )}
              </div>
            </form>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-uppercase text-center">
                        <th style={{ minWidth: '100px' }} className="pl-7">
                          <span className="text-dark-75">Fund ID</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Name</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Cool Name</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Fund Identifier</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">Bid Price</span>
                        </th>
                        <th style={{ minWidth: '200px' }}>
                          <span className="text-dark-75">Ask Price</span>
                        </th>
                        <th style={{ minWidth: '100px' }}>
                          <span className="text-dark-75">
                            {checkDailyTradeValues(plans) && isCurrentDay
                              ? 'Edit'
                              : ''}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {plans.map(onePlan => (
                        <DailyTrades
                          key={onePlan.id}
                          planInfo={onePlan}
                          getCurrentAskPrice={getCurrentAskPrice}
                          getCurrentBidPrice={getCurrentBidPrice}
                          updateBidAndAskPrices={updateBidAndAskPrices}
                          isCurrentDay={isCurrentDay}
                        />
                      ))}
                    </tbody>
                    <ChecksModal
                      alerts={alerts}
                      open={open}
                      handleClose={() => setOpen(false)}
                      handleSubmit={() => handleSaveModal()}
                      selectedOptions={selectedOptions}
                      selectOptions={selectOptions}
                      modalTitle={
                        'Are you sure you want to set the ask and bid prices?'
                      }
                    />
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                {totalPages && totalPages > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPages}
                    onChange={handleChangePage}
                  />
                ) : (
                  ''
                )}
                {totalRecord && totalRecord > 10 ? (
                  <NumberOfRows
                    handleChangeLimit={handleChangeLimit}
                    totalRecord={totalRecord}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="text-right mt-8">
                {grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) &&
                isCurrentDay &&
                !checkDailyTradeValues(plans) ? (
                  <button
                    type="submit"
                    className={`btn btn-light-primary my-auto px-9 save-btn`}
                    disabled={
                      !checkAskBidPricesFilledBeforeSave(askAndBidPrices)
                    }
                    style={{ marginTop: '-20px', marginLeft: '10px' }}
                    onClick={e => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllDailyTrades;
