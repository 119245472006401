import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { DateInput } from '../../../common/DateInput';
import SelectBox from '../../../common/SelectBox';

function getModalStyle() {
  const top = 49;
  const left = 44;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const dateOptions = [
  { id: 1, name: 'Account creation date', value: 'CREATED_AT' },
  { id: 2, name: 'KYC Submission date', value: 'KYC_SUBMITTED_AT' },
  { id: 3, name: 'KYC Evaluation date', value: 'KYC_EVALUATED_AT' },
];

const InvestorDate = ({
  open,
  setOpen,
  handleDateChange,
  searchInvestors,
  userStatus,
  dateFilter,
  dateValues,
  kycStatus,
  userLevel,
  setDateFilter,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const handleClose = async e => {
    e.preventDefault();
    setOpen(false); // Close the modal when called
    await searchInvestors(
      kycStatus,
      userLevel,
      userStatus,
      dateFilter,
      dateValues,
    );
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section style={modalStyle} className={classes.paper}>
        <SelectBox
          options={dateOptions}
          name="Date column name"
          setValue={e => setDateFilter(e)}
          defaultValue={dateFilter}
          styles={{
            border: '1px solid #797979',
            color: '#000',
            width: '70%',
            height: '45px',
            borderRadius: '3px',
            textAlign: 'center',
            padding: '0 10px',
            background: '#FFFFFF',
            fontWeight: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem',
          }}
        />

        <DateInput
          name="startDate"
          label="Start Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
          value={dateValues?.startDate}
        />
        <DateInput
          name="endDate"
          label="End Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
          value={dateValues?.endDate}
        />

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div />
          <button
            onClick={handleClose}
            id="kt_login_signin_submit"
            className={`btn btn-primary font-weight-bold `}
            disabled={
              !dateValues?.startDate || !dateValues?.endDate || !dateFilter
            }
          >
            <span>Search</span>
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default InvestorDate;
