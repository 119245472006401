import axios from 'axios';
import dotenv from 'dotenv';
import _ from 'lodash';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_FOREX_VALUES = `${REACT_APP_BASE_URL}/v1/admin/conversions`;

const fetchForexValues = async (page, limit) => {
  let URL = `${FETCH_FOREX_VALUES}?`;
  let queryParams = ['page', 'limit'];
  let functionParams = [page, limit];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = await axios.get(URL);

  return res;
};

const updateNdovuRate = async data => {
  const res = await axios.put(FETCH_FOREX_VALUES, data);

  return res;
};

export { fetchForexValues, updateNdovuRate };
