import axios from 'axios';

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CORPORATE_ACTIONS = `${REACT_APP_BASE_URL}/v1/admin/plans/corporate-actions`;

const getAllCorporateActions = async (page, limit) => {
  return await axios.get(`${CORPORATE_ACTIONS}/?page=${page}&limit=${limit}`);
};

const updateCorporateActions = async payload => {
  const res = await axios.post(`${CORPORATE_ACTIONS}`, payload);
  return res;
};

export { getAllCorporateActions, updateCorporateActions };
