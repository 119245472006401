import React from 'react';

const Alert = ({ alert, alignText }) => {
  return (
    <div
      className={`mt-5 fade show`}
      role="alert"
      style={{
        color: alert.alertMessageType === 'success' ? '#67CCCA' : '#F05B7F',
      }}
    >
      <div
        className={`${alignText ? alignText : 'text-center'} font-weight-bold`}
      >
        {alert.alertMessage && Array.isArray(alert.alertMessage)
          ? alert.alertMessage.map((error, index) => <p key={index}>{error}</p>)
          : alert.alertMessage}
      </div>
    </div>
  );
};

export default Alert;
