import React from 'react';

function CommonInput({ id, label, value, type, onChange, name }) {
  return (
    <section className="form-row-container">
      <label className="common-input-label" htmlFor={id}>
        {label}
      </label>
      <div
        style={{
          position: 'relative',
        }}
      >
        <input
          type={type}
          defaultValue={value}
          id={id}
          name={name}
          onChange={onChange}
          className="common-input-field"
        />
      </div>
    </section>
  );
}

export default CommonInput;
