import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import WarningIconTwo from '../../../icons/exclamation-round-line.svg';
import { updateDailyTrade } from '../_redux/fundCrud';
import ChecksModal from '../../../common/ChecksModal';

function DailyTrades({
  planInfo: {
    id: planId,
    name,
    coolName,
    identifier,
    dailyTrade,
    previousTrade,
    alertVariationPercentage,
  },
  getCurrentAskPrice,
  getCurrentBidPrice,
  updateBidAndAskPrices,
  isCurrentDay,
}) {
  const [askPrice, setAskPrice] = useState(dailyTrade?.askPrice);
  const [bidPrice, setBidPrice] = useState(dailyTrade?.bidPrice);
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [increase, setIncrease] = useState({
    askPrice: askPrice,
    bidPrice: bidPrice,
  });
  const [loadData, setLoadData] = useState(false);

  const performChecks = () => {
    const allAlerts = [];
    const askPriceIncrease =
      askPrice && Number(askPrice) !== 0 && previousTrade?.askPrice
        ? Number(askPrice) - Number(previousTrade?.askPrice)
        : 0;

    const bidPriceIncrease =
      bidPrice && Number(bidPrice) !== 0 && previousTrade?.bidPrice
        ? Number(bidPrice) - Number(previousTrade?.bidPrice)
        : 0;

    const askPricePercentageIncrease =
      askPriceIncrease && askPriceIncrease !== 0
        ? (askPriceIncrease / Number(previousTrade?.askPrice)) * 100
        : 0;

    const bidPricePercentageIncrease =
      bidPriceIncrease && bidPriceIncrease !== 0
        ? (bidPriceIncrease / Number(previousTrade?.bidPrice)) * 100
        : 0;
    setIncrease({
      askPrice: askPricePercentageIncrease,
      bidPrice: bidPricePercentageIncrease,
    });

    if (Number(bidPrice) > Number(askPrice)) {
      const messageText = 'Confirming that the bid is higher than the ask.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }
    if (Number(askPricePercentageIncrease) > alertVariationPercentage) {
      const messageText =
        'The new Ask price differs considerably from the old one.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }
    if (Number(bidPricePercentageIncrease) > alertVariationPercentage) {
      const messageText =
        'The new Bid price differs considerably from the old one.';
      !allAlerts.includes(messageText) && allAlerts.push(messageText);
    }

    setAlerts([...allAlerts]);
  };

  useEffect(() => {
    performChecks();
  }, [askPrice, bidPrice]);

  const selectOptions = value => {
    const newData = [...selectedOptions];
    const valueExist = newData.includes(value);
    if (valueExist) {
      const index = newData.indexOf(value);
      newData.splice(index, 1);
      setSelectedOptions(newData);
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleUpdateModal = async (planId, dailyTradeId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    try {
      const response = await updateDailyTrade(
        dailyTradeId,
        planId,
        Number(askPrice),
        Number(bidPrice),
      );
      if (response) {
        swalWithBootstrapButtons.fire(
          'Success',
          'You have updated the ask and bid price',
          'success',
        );
        setOpen(false);
        setAskPrice(response.data.data.askPrice);
        setBidPrice(response.data.data.bidPrice);
        setSelectedOptions([]);
        setOpen(false);
      }
    } catch (error) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        error.response.data.message,
        'error',
      );
      setOpen(false);
    }
  };

  return (
    <tr className="py-2 my-2">
      <td className="pl-0 py-8 text-center">
        <span className="text-color text-hover-primary mb-1 font-size-lg">
          {planId ? planId : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {name ? name : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {coolName ? coolName : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {identifier ? identifier : '---'}
        </span>
      </td>

      <td className="text-center">
        <div className="form-group mb-2 mr-1">
          <input
            className="form-control mt-10"
            type="number"
            placeholder="Bid"
            name="bidPrice"
            value={getCurrentBidPrice(planId) || ''}
            min={0}
            onChange={e => {
              setBidPrice(e.target.value);
              updateBidAndAskPrices(planId, e);
            }}
            autoComplete="off"
            style={{ width: '100%' }}
            disabled={
              !grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) ||
              !isCurrentDay
            }
          />
          {isCurrentDay ? (
            <div>
              <div>
                <div className="my-1 py-1">
                  {`Previous Price: ${
                    previousTrade?.bidPrice
                      ? previousTrade?.bidPrice?.toFixed(2)
                      : '--'
                  }`}
                  {Number(increase?.bidPrice) > alertVariationPercentage ? (
                    <span style={{ marginLeft: '5px' }}>
                      <img
                        src={WarningIconTwo}
                        style={{ width: '6%' }}
                        alt="Warning icon"
                      />
                    </span>
                  ) : null}
                </div>
                <div>
                  Date:{' '}
                  {previousTrade?.day
                    ? moment(previousTrade?.day).format('l')
                    : '--'}
                </div>

                <div>{`Difference: ${increase?.bidPrice?.toFixed(2)}%`}</div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
      <td className="text-center">
        {' '}
        <div className="form-group mb-2 mr-1">
          <input
            className="form-control mt-10"
            type="number"
            placeholder="Ask"
            name="askPrice"
            value={getCurrentAskPrice(planId) || ''}
            min={0}
            onChange={e => {
              setAskPrice(e.target.value);
              updateBidAndAskPrices(planId, e);
            }}
            autoComplete="off"
            style={{ width: '100%' }}
            disabled={
              !grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) ||
              !isCurrentDay
            }
          />
          {isCurrentDay ? (
            <div>
              <div className="my-1 py-1">
                {`Previous Price: ${
                  previousTrade?.askPrice
                    ? previousTrade?.askPrice?.toFixed(2)
                    : '--'
                }`}
                {Number(increase?.askPrice) > alertVariationPercentage ? (
                  <span style={{ marginLeft: '5px' }}>
                    <img
                      src={WarningIconTwo}
                      style={{ width: '6%' }}
                      alt="Warning Icon"
                    />
                  </span>
                ) : null}
              </div>
              <div>
                Date:{' '}
                {previousTrade?.day
                  ? moment(previousTrade?.day).format('l')
                  : '--'}
              </div>

              <div>{`Difference: ${increase?.askPrice?.toFixed(2)}%`}</div>
            </div>
          ) : (
            ''
          )}
        </div>
      </td>
      <td className="text-center">
        {dailyTrade && isCurrentDay ? (
          <button
            type="submit"
            className={`btn btn-light-primary mb-10 px-9 py-2 save-btn`}
            disabled={loadData}
            onClick={e => {
              e.preventDefault();
              setOpen(true);
            }}
          >
            Edit
            {loadData && <span className="ml-3 spinner spinner-white" />}
          </button>
        ) : (
          ''
        )}
      </td>
      <ChecksModal
        alerts={alerts}
        open={open}
        handleClose={() => setOpen(false)}
        handleSubmit={() => handleUpdateModal(planId, dailyTrade?.id)}
        selectedOptions={selectedOptions}
        selectOptions={selectOptions}
        modalTitle={'Are you sure you want to update the prices?'}
      />
    </tr>
  );
}

export default DailyTrades;
