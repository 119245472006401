import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import { fetchPartners } from '../modules/UnassignedTransactions/_redux/transactionsCrud';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const CommonModal = ({
  open,
  handleClose,
  getByPartners,
  handleChangePartnerId,
  partnerId,
  setOpen,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [partners, setPartners] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getPartners = async () => {
    try {
      const {
        data: { data, currentPage, totalPages },
      } = await fetchPartners(page, 10);
      setPartners(data);
      setPage(Number(currentPage));
      setTotalPages(totalPages);
    } catch (error) {}
  };

  const handleChangePage = async (event, value) => {
    try {
      const {
        data: { data, currentPage, totalPages },
      } = await fetchPartners(value, 10);
      setPartners(data);
      setPage(Number(currentPage));
      setTotalPages(totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    } catch (error) {}
  };

  useEffect(() => {
    getPartners();
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="d-flex justify-content-center font-weight-bolder">
          Select partners
        </div>
        <div style={{ width: '25%' }} className="mx-auto py-10">
          {partners &&
            partners?.length &&
            partners?.map(partner => (
              <div key={partner?.id} className="col-md-3 col-xs-12">
                <input
                  type="radio"
                  name={partner.name}
                  onChange={() => handleChangePartnerId(partner.id)}
                  checked={partner?.id === partnerId}
                  className="form-check-input"
                  id={partner?.id}
                />
                <label
                  className="form-check-label font-weight-bold"
                  htmlFor={partner?.id}
                >
                  {partner?.name}
                </label>
              </div>
            ))}
        </div>

        <div className="d-flex justify-content-center mb-10 mx-10">
          {totalPages && totalPages > 0 ? (
            <Pagination
              page={page}
              count={totalPages}
              onChange={handleChangePage}
            />
          ) : null}
        </div>

        <div className="d-flex justify-content-center flex-wrap">
          <button
            style={{
              background: '#0071CE',
              borderRadius: '5px',
              padding: '10px 20px',
              border: '1px solid #0071CE',
              color: '#ffffff',
              height: '40px',
            }}
            onClick={() => {
              getByPartners(partnerId);
              setOpen(false);
            }}
          >
            Continue
          </button>

          <button
            type="button"
            className="blue-button"
            style={{
              background: '#dbedf8',
              color: '#3699FF',
              border: 'none',
              height: '40px',
              marginTop: 0,
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
