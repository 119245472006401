/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup';

import React, { useState } from 'react';

import Alert from '../../../common/Alert';
import { editPassword } from '../_redux/profileCrud';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import UnableViewPassword from '../../../common/UnableViewPassword';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = props => {
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Required field'),
    newPassword: Yup.string().matches(
      /^(?=.*[\d])(?=.*[!@#$%^&*]?)[\w!@#$%^&*]{8,}$/,
      'Must contain minimum of 8 characters. At least one letter and one number.',
    ),
    confirmPassword: Yup.string()
      .required('Required field')
      .when('newPassword', {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('newPassword')],
          "Password and Confirm Password didn't match",
        ),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        delete values.confirmPassword;
        const response = await editPassword(values);
        setSubmitting(false);
        if (response.status === 200) {
          formik.resetForm();
          setAlertOn(true);
          setAlert({
            alertMessage: 'password edited successfully.',
            alertMessageType: 'success',
          });
          setTimeout(() => {
            setAlertOn(!setAlertOn);
          }, 3000);
          setLoading(false);
        }
      } catch (error) {
        setSubmitting(false);
        setAlertOn(true);
        setAlert({
          alertMessage:
            error.response.data && error.response.data.message
              ? error.response.data.message
              : 'profile editing unsuccessful.Try again.',
          alertMessageType: 'error',
        });
        setTimeout(() => {
          setAlertOn(!setAlertOn);
        }, 3000);
        setStatus(error.response.data.message);
        formik.resetForm();
        setLoading(false);
      }
    },
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <>
      <div id="add-margin-top" className="flex-row-fluid">
        <form className="form" onSubmit={formik.handleSubmit}>
          {/* <!--begin::Card--> */}
          <div className="card card-custom">
            {/* <!--begin::Header--> */}
            <div className="py-3 card-header">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label" style={{ color: '#0071CE' }}>
                  Change Password
                </h3>
              </div>

              <div className="card-toolbar">
                <button
                  type="submit"
                  className="mr-2 px-6 btn"
                  style={{ color: '#fff', background: '#0071CE' }}
                >
                  Save Changes
                  {loading && <span className="ml-2 spinner spinner-white" />}
                </button>
                <button
                  onClick={formik.handleReset}
                  className="btn"
                  style={{ color: '#0071CE', background: '#CAE2F6' }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="card-body">
              {alertOn && <Alert alert={alert} />}
              <div className="mt-3 form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Current Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={viewCurrentPassword ? 'text' : 'password'}
                    className={`form-control ${getInputClasses('oldPassword')}`}
                    name="oldPassword"
                    {...formik.getFieldProps('oldPassword')}
                    placeholder="Current password"
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                    }}
                    autoComplete="off"
                  />
                  <UnableViewPassword
                    handleClickEvent={() =>
                      setViewCurrentPassword(!viewCurrentPassword)
                    }
                    viewPassword={viewCurrentPassword}
                  />
                  {formik.touched.oldPassword && formik.errors.oldPassword ? (
                    <div
                      className="font-weight-bold font-size-sm"
                      style={{ color: '#F05B7F' }}
                    >
                      {formik.errors.oldPassword}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* begin: Password */}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={viewPassword ? 'text' : 'password'}
                    className={`form-control ${getInputClasses('newPassword')}`}
                    name="newPassword"
                    {...formik.getFieldProps('newPassword')}
                    placeholder="New password"
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                    }}
                    autoComplete="off"
                  />
                  <UnableViewPassword
                    handleClickEvent={() => setViewPassword(!viewPassword)}
                    viewPassword={viewPassword}
                  />
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div
                      className="font-weight-bold font-size-sm"
                      style={{ color: '#F05B7F' }}
                    >
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Password confirmation*/}
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Confirm Password
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type={viewConfirmPassword ? 'text' : 'password'}
                    className={`form-control 
                                        ${getInputClasses('confirmPassword')}`}
                    name="confirmPassword"
                    {...formik.getFieldProps('confirmPassword')}
                    placeholder="Confirm password"
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                    }}
                    autoComplete="off"
                  />
                  <UnableViewPassword
                    handleClickEvent={() =>
                      setViewConfirmPassword(!viewConfirmPassword)
                    }
                    viewPassword={viewConfirmPassword}
                  />
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div
                      className="font-weight-bold font-size-sm"
                      style={{ color: '#F05B7F' }}
                    >
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-xl-3 col-lg-3 "></div>
                <span className="mt-1 col-lg-9 col-xl-6 font-size-sm">
                  Password Must contain minimum of 8 characters. At least one
                  letter and one number.
                </span>
              </div>
            </div>
            {/* </form> */}
            {/* <!--end::Form--> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
