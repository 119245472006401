import React from 'react';
import ImageList from './ImageList';
import UploadFile from './UploadFile';

import { getFileName } from '../helpers/getFileName';

function ImageContainer({
  images,

  handleOpeningFile,
  handleCardUpload,
  downloadingImage,
  investorsData,
  files,
  style,
}) {
  return (
    <section
      style={
        style
          ? {
              width: '75%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }
          : {}
      }
    >
      {/* The styles will be displayed dynamically based on the style prop  */}
      <section
        style={
          style
            ? {
                marginTop: '2rem',
                marginBottom: '2rem',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridColumnGap: '3rem',
              }
            : {}
        }
      >
        {images?.map(image => (
          <div key={image.id}>
            {image.url ? (
              <ImageList
                title={image.title}
                imageURL={image.url}
                handleOpeningFile={handleOpeningFile}
                downloadingImage={downloadingImage}
                files={image.files}
                investorsData={image.investorsData}
                handleCardUpload={handleCardUpload}
                fileName={image.fileName}
                originalUrl={investorsData?.image}
                iframeId={image.iframeId}
                margin={'2rem'}
                minHeight="250px"
                style={{ margin: '0 1.5rem' }}
              />
            ) : (
              <div key={image.id}>
                <p>No {image.title} uploaded</p>
                <UploadFile
                  files={files}
                  investorsData={investorsData}
                  document={image.url}
                  name={getFileName(image.fileName)}
                  title={image.title}
                  handleChange={handleCardUpload}
                  displayTitle={false}
                />
              </div>
            )}
          </div>
        ))}
      </section>
    </section>
  );
}

export default ImageContainer;
