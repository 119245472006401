import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const UPDATE_PASSWORD_URL = `${REACT_APP_BASE_URL}/v1/admin/auth/update-password`;

const editPassword = async paswordValues => {
  const res = await axios.put(UPDATE_PASSWORD_URL, {
    ...paswordValues,
  });
  return res;
};

const downloadUsersFile = async imageURL => {
  return axios.get(imageURL, { responseType: 'blob' });
};

export { editPassword };
