/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthPage, Logout } from './modules/Auth';

import React from 'react';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { checkToken } from './helpers/helper';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';

export function Routes() {
  useSelector(({ auth }) => {
    return {
      isAuthorized: auth.isAuthorized != null,
      tokenValidated: auth.tokenValid,
    };
  }, shallowEqual);

  const auth = checkToken();

  return (
    <Switch>
      {!auth ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!auth ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
