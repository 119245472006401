import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const BLOCK_BASE_URL = `${REACT_APP_BASE_URL}/v1/admin/blocks`;
const UPDATE_FUND = `${REACT_APP_BASE_URL}/v1/admin/funds/`;

const createBlocks = async transactionIds => {
  const res = await axios.post(BLOCK_BASE_URL, { transactionIds });
  return res;
};

const fetchBlocks = async (page, limit, partnerId, blockId, blockStatus) => {
  let URL = `${BLOCK_BASE_URL}`;
  let queryParams = ['page', 'limit', 'partnerId', 'id', 'status'];
  let functionParams = [page, limit, partnerId, blockId, blockStatus];

  let params = {};

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      params[queryParams[i]] = functionParams[i];
    }
  }

  const res = await axios.get(URL, { params });
  return res;
};

const fetchBlock = async blockId => {
  const res = await axios.get(`${BLOCK_BASE_URL}/${blockId}`);
  return res;
};

const removeBlock = async blockId => {
  const res = await axios.delete(`${BLOCK_BASE_URL}/${blockId}`);
  return res;
};

const updateFund = async (fundId, askAmount, bidAmount) => {
  const res = await axios.put(`${UPDATE_FUND}${fundId}`, {
    askPrice: askAmount,
    bidPrice: bidAmount,
  });
  return res;
};

const completeBlock = async blockId => {
  const res = await axios.put(`${BLOCK_BASE_URL}/${blockId}`);
  return res;
};

const sendBulkEmail = async blockId => {
  const res = await axios.post(
    `${BLOCK_BASE_URL}/${blockId}/completed-email-notification`,
  );
  return res;
};

const moveTransactionsToUnassigned = async (blockId, transactionId) => {
  const res = await axios.delete(
    `${BLOCK_BASE_URL}/${blockId}/remove-transaction/${transactionId}`,
  );
  return res;
};

export {
  createBlocks,
  fetchBlocks,
  fetchBlock,
  removeBlock,
  updateFund,
  completeBlock,
  sendBulkEmail,
  moveTransactionsToUnassigned,
};
