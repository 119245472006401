import convertAmountToNumber from './convertAmountToNumber';
import { formatNumber } from './helper';

const validateMinimumMaximum = (currency, amount, type, plan = null) => {
  if (type === 'GOAL') {
    if (
      convertAmountToNumber(amount) <
      convertAmountToNumber(currency.minimumInvestableAmount)
    ) {
      const errorMessage = `The minimum amount is ${
        currency?.value
      } ${formatNumber(currency.minimumInvestableAmount)}`;

      return errorMessage;
    } else if (
      convertAmountToNumber(amount) >
      convertAmountToNumber(currency.maximumInvestableAmount)
    ) {
      const errorMessage = `The maximum amount is ${
        currency?.value
      } ${formatNumber(currency.maximumInvestableAmount)}`;

      return errorMessage;
    }
  } else {
    let minimumInvestableAmount = plan.minInvestableAmounts[currency.value];
    if (convertAmountToNumber(amount) < minimumInvestableAmount) {
      const errorMessage = `The minimum amount is ${
        currency?.value
      } ${formatNumber(minimumInvestableAmount)}`;
      return errorMessage;
    } else if (
      convertAmountToNumber(amount) > currency.maximumInvestableAmount
    ) {
      const errorMessage = `The maximum amount is ${
        currency?.value
      } ${formatNumber(currency.maximumInvestableAmount)}`;

      return errorMessage;
    }
  }
};

export default validateMinimumMaximum;
