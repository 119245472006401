import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_ALL_RECURRING_PAYMENTS = `${REACT_APP_BASE_URL}/v1/admin/recurring-payments`;

const fetchAllRecurringPayments = async (page, limit) => {
  let res = '';
  if (page & limit) {
    res = await axios.get(
      `${FETCH_ALL_RECURRING_PAYMENTS}?page=${page}&limit=${limit}`,
    );
    return res;
  } else {
    res = axios.get(`${FETCH_ALL_RECURRING_PAYMENTS}`);
  }
  return res;
};

const searchRecurringPayments = (
  partnerId,
  status,
  paymentChannel,
  planId,
  search,
  page,
  limit,
) => {
  let URL = `${FETCH_ALL_RECURRING_PAYMENTS}?`;
  let queryParams = [
    'partnerId',
    'status',
    'paymentChannel',
    'planId',
    'search',
    'page',
    'limit',
  ];
  let functionParams = [
    partnerId,
    status,
    paymentChannel,
    planId,
    search,
    page,
    limit,
  ];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }
  const res = axios.get(URL);
  return res;
};

const updateRecurringPaymentStatus = async (id, status) => {
  let res = '';
  const body = {
    status,
  };
  res = await axios.put(`${FETCH_ALL_RECURRING_PAYMENTS}/${id}/status`, body);
  return res;
};
export {
  fetchAllRecurringPayments,
  searchRecurringPayments,
  updateRecurringPaymentStatus,
};
