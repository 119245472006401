/* eslint-disable jsx-a11y/anchor-is-valid */

import * as Yup from 'yup';

import React, { useState } from 'react';

import Alert from '../../../common/Alert';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AdminStatus } from '../../../helpers/constants';

const initialValues = {
  names: '',
  email: '',
};

const CreateAdmin = props => {
  let history = useHistory();
  const [alertOn, setAlertOn] = useState(false);
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);

  const CreateAdminSchema = Yup.object().shape({
    names: Yup.string().required('Required field'),
    email: Yup.string()
      .email()
      .required('Required field'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: CreateAdminSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!values) {
        setSubmitting(true);
      }
      history.push('/admins/set-permissions', {
        values,
      });
    },
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  return (
    <>
      <div id="add-margin-top" className="flex-row-fluid">
        <form className="form" onSubmit={formik.handleSubmit}>
          {/* <!--begin::Card--> */}
          <div className="card card-custom">
            {/* <!--begin::Header--> */}
            <div className="py-3 card-header">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label" style={{ color: '#0071CE' }}>
                  Create Account
                </h3>
              </div>

              <div className="card-toolbar">
                <button
                  type="submit"
                  className="mr-2 px-6 btn"
                  style={{ color: '#fff', background: '#0071CE' }}
                >
                  Next
                  {loading && <span className="ml-2 spinner spinner-white" />}
                </button>
                <button
                  onClick={formik.handleReset}
                  className="btn"
                  style={{ color: '#0071CE', background: '#CAE2F6' }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="card-body">
              {alertOn && <Alert alert={alert} />}
              <div className="mt-3 form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  Names
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    className={`form-control ${getInputClasses('names')}`}
                    name="names"
                    {...formik.getFieldProps('names')}
                    placeholder="Names"
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                    }}
                    autoComplete="off"
                  />
                  {formik.touched.names && formik.errors.names ? (
                    <div
                      className="font-weight-bold font-size-sm"
                      style={{ color: '#F05B7F' }}
                    >
                      {formik.errors.names}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                  E-mail
                </label>
                <div className="col-lg-9 col-xl-6">
                  <input
                    type="text"
                    className={`form-control ${getInputClasses('email')}`}
                    name="email"
                    {...formik.getFieldProps('email')}
                    placeholder="E-mail"
                    style={{
                      border: '0.5px solid #CAE2F6',
                      background: '#F5F9FD',
                      height: '48px',
                    }}
                    autoComplete="off"
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div
                      className="font-weight-bold font-size-sm"
                      style={{ color: '#F05B7F' }}
                    >
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* </form> */}
            {/* <!--end::Form--> */}
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAdmin;
