import React, { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorCard from '../../../common/ErrorCard';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import Pagination from '@material-ui/lab/Pagination';
import ForexTable from './forexTable';
import { fetchForexValues, updateNdovuRate } from '../_redux';
import ChecksModal from '../../../common/ChecksModal';
import Swal from 'sweetalert2';
function AdminFX() {
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [manualSpreadPrices, setManualSpreadValues] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleChangePage = async (_event, value) => {
    const res = await fetchForexValues(value, limit);
    setPage(value);

    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setRates(res.data.items);
      handleSetManualSpread(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = event => {
    setPage(1);
    const { value: selectedValue } = event.target;
    const res = fetchForexValues(1, selectedValue);
    setLimit(selectedValue);

    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setRates(res.data.items);
      handleSetManualSpread(res.data.items);
      setTotalPages(res.data.meta.totalPages);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const getForexRates = async () => {
    try {
      setLoading(true);

      const res = await fetchForexValues(page, limit);

      if (res.data) {
        setRates(res.data.items);
        handleSetManualSpread(res.data.items);
        setTotalPages(res.data.meta.totalPages);
        setTotalRecord(res.data.meta.totalItems);
        window.screenTop({
          behavior: 'smooth',
          top: '0px',
        });
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getForexRates();
  }, []);

  const handleSetManualSpread = forexFromResponse => {
    const newManualSpread = [];

    forexFromResponse.forEach(({ id, ndovuConversionPercentageFee }) => {
      const index = manualSpreadPrices.findIndex(i => i.id === id);
      if (index === -1) {
        newManualSpread.push({
          id,
          ndovuConversionPercentageFee: ndovuConversionPercentageFee,
        });
      }
      setManualSpreadValues([...manualSpreadPrices, ...newManualSpread]);
    });
  };

  const updateManualSpread = (forexId, event) => {
    const index = manualSpreadPrices.findIndex(forex => forex.id === forexId);
    const updatedPrices = [...manualSpreadPrices];

    updatedPrices[index] = {
      ...updatedPrices[index],
      [event.target.name]: Number(event.target.value),
    };

    setManualSpreadValues(updatedPrices);
  };

  const checkNdovuOfferedRateFilledBeforeSave = manualSpread => {
    return manualSpread.every(
      conversion =>
        conversion.ndovuConversionPercentageFee !== undefined &&
        conversion.ndovuConversionPercentageFee !== null &&
        conversion.ndovuConversionPercentageFee !== '' &&
        conversion.ndovuConversionPercentageFee >= 0,
    );
  };

  checkNdovuOfferedRateFilledBeforeSave(manualSpreadPrices);

  const [alerts, setAlerts] = useState([]);
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-light-success',
      cancelButton: 'btn btn-light-danger mr-1',
    },
    buttonsStyling: false,
  });
  const handleSaveModal = async () => {
    try {
      const response = await updateNdovuRate(manualSpreadPrices);
      if (response.data) {
        swalWithBootstrapButtons.fire(
          'Success',
          'You have saved ndovu conversion fee',
          'success',
        );
        setOpen(false);
        setPage(1);
      }
    } catch (error) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        error.response.data.message,
        'error',
      );
      setOpen(false);
    }
  };
  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div>
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <h4 className="pt-8">All Currency rates</h4>
            </div>

            <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-uppercase text-center">
                        <th style={{ minWidth: '100px' }} className="pl-7">
                          <span className="text-dark-75">Conversion</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            Open Source API (refreshed if later than 2h )
                          </span>
                        </th>
                        {grantPermission(
                          AdminPermissions.MANIPULATE_CONVERSIONS_RATE,
                        ) && (
                          <th style={{ width: '150px' }}>
                            <span className="text-dark-75">Spread</span>
                          </th>
                        )}

                        <th style={{ width: '150px' }}>
                          <span className="text-dark-75">Rate offered</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rates.map(rate => (
                        <ForexTable
                          key={rate.id}
                          forexDetails={rate}
                          isCurrentDate={true}
                          updateManualSpread={updateManualSpread}
                        />
                      ))}
                    </tbody>
                    <ChecksModal
                      alerts={alerts}
                      open={open}
                      handleClose={() => setOpen(false)}
                      handleSubmit={() => handleSaveModal()}
                      selectedOptions={selectedOptions}
                      modalTitle={
                        'Are you sure you want to set ndovu conversion fees?'
                      }
                    />
                  </table>
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
                {totalPages && totalPages > 0 ? (
                  <Pagination
                    page={page}
                    count={totalPages}
                    onChange={handleChangePage}
                  />
                ) : (
                  ''
                )}

                {totalRecord && totalRecord > 10 ? (
                  <NumberOfRows
                    handleChangeLimit={handleChangeLimit}
                    totalRecord={totalRecord}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="text-right mt-8">
                {grantPermission(
                  AdminPermissions.MANIPULATE_CONVERSIONS_RATE,
                ) ? (
                  <button
                    type="submit"
                    className={`btn btn-light-primary my-auto px-9 save-btn`}
                    disabled={
                      !checkNdovuOfferedRateFilledBeforeSave(manualSpreadPrices)
                    }
                    style={{ marginTop: '-20px', marginLeft: '10px' }}
                    onClick={e => {
                      e.preventDefault();

                      setOpen(true);
                    }}
                  >
                    Save
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminFX;
