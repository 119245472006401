/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */

import { Link, useHistory } from 'react-router-dom';

import React from 'react';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_helpers';
import { grantPermission } from '../../content/Permissions';
import { AdminPermissions } from '../../../../../app/helpers/constants';

function QuickUser({ profile: { names, email } }) {
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    history.push('/logout');
  };

  const closeAsideNav = () => {
    let closeAsideButton = document.getElementById('kt_quick_user_close');
    let asideLeftItemElement = document.getElementById('kt_quick_user');
    asideLeftItemElement.classList.remove('offcanvas-on');
    closeAsideButton.click();
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Admin Profile</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  '/media/svg/icons/General/User.svg',
                )})`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {names}
            </a>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">{email}</span>
              </a>
            </div>
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="navi navi-spacer-x-0 p-0">
          <Link
            onClick={closeAsideNav}
            to="/change-password"
            className="navi-item"
          >
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl('/media/svg/icons/Tools/Axe.svg')}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Change Password</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  profile: JSON.parse(localStorage.getItem('profile')),
});

export default connect(mapStateToProps, null)(QuickUser);
