import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions } from '../../../helpers/constants';

const AllPlan = ({
  handleChange,
  handleChangeInvestorProfile,
  planIdsInvestable,
  planIdsViewable,
  planIdsDiscountable,
  plansIdsHotFund,
  planInfo,
  conservativeProfile,
  balancedProfile,
  aggressiveProfile,
  conservativeEditable,
  balancedEditable,
  aggressiveEditable,
  handleUpdateNonInvestableNote,
}) => {
  const [nonInvestNote, setNonInvestNote] = useState(
    planInfo?.settings[0]?.nonInvestableNote || '',
  );

  return (
    <tr>
      <td className="pl-0 py-8 text-center">
        <span className="text-color text-hover-primary mb-1 font-size-lg">
          {planInfo?.id ? planInfo?.id : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {planInfo?.coolName ? planInfo?.coolName : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {planInfo?.identifier ? planInfo?.identifier : '---'}
        </span>
      </td>

      {grantPermission(AdminPermissions.FUND_CONFIGURATION) ? (
        <>
          <td className="pr-0 text-center">
            <input
              type="checkbox"
              name="discountable"
              onChange={e => handleChange(e, planInfo?.settings[0]?.id)}
              checked={planIdsDiscountable?.includes(planInfo?.settings[0]?.id)}
            />
          </td>
          <td className="pr-0 text-center">
            <input
              type="checkbox"
              name="viewable"
              onChange={e => handleChange(e, planInfo?.settings[0]?.id)}
              checked={planIdsViewable?.includes(planInfo?.settings[0]?.id)}
            />
          </td>

          <td className="pr-0 text-center">
            <input
              type="checkbox"
              name="investable"
              onChange={e => handleChange(e, planInfo?.settings[0]?.id)}
              checked={planIdsInvestable?.includes(planInfo?.settings[0]?.id)}
            />
          </td>

          <td className="pr-0 text-center">
            <input
              type="text"
              name="nonInvestableNote"
              value={nonInvestNote}
              onChange={e => {
                setNonInvestNote(e.target.value);
                handleUpdateNonInvestableNote(e, planInfo?.settings[0]?.id);
              }}
            />
          </td>
          <td className="pr-0 text-center">
            <input
              type="checkbox"
              name="hotFund"
              onChange={e => handleChange(e, planInfo?.settings[0]?.id)}
              checked={plansIdsHotFund?.includes(planInfo?.settings[0]?.id)}
              disabled
            />
          </td>
        </>
      ) : null}

      {grantPermission(AdminPermissions.FUND_CONFIGURATION) ? (
        <>
          <td className="pr-0 text-center">
            <input
              type="number"
              name="conservative"
              placeholder="%"
              onChange={e =>
                handleChangeInvestorProfile(
                  e,
                  planInfo?.id,
                  planInfo.investmentProfilePlan.find(
                    x => x?.investmentProfile?.id === 1,
                  )?.investmentProfile?.id ?? 1,
                )
              }
              value={
                conservativeProfile.find(x => x?.planId === planInfo?.id)
                  ?.percentage
              }
              disabled={conservativeEditable}
              max="100"
            />
          </td>

          <td className="pr-0 text-center">
            <input
              type="number"
              name="balanced"
              placeholder="%"
              onChange={e =>
                handleChangeInvestorProfile(
                  e,
                  planInfo?.id,
                  planInfo.investmentProfilePlan.find(
                    x => x?.investmentProfile?.id === 2,
                  )?.investmentProfile?.id ?? 2,
                )
              }
              value={
                balancedProfile.find(x => x?.planId === planInfo?.id)
                  ?.percentage
              }
              disabled={balancedEditable}
              max="100"
            />
          </td>

          <td className="pr-0 text-center">
            <input
              type="number"
              name="aggressive"
              placeholder="%"
              onChange={e =>
                handleChangeInvestorProfile(
                  e,
                  planInfo?.id,
                  planInfo.investmentProfilePlan.find(
                    x => x?.investmentProfile?.id === 3,
                  )?.investmentProfile?.id ?? 3,
                )
              }
              value={
                aggressiveProfile.find(x => x?.planId === planInfo?.id)
                  ?.percentage
              }
              disabled={aggressiveEditable}
              max="100"
            />
          </td>
        </>
      ) : (
        ''
      )}
    </tr>
  );
};

export default AllPlan;
