import React from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Content } from './Content';
import { grantPermission } from './Permissions';

export function ContentRoute({
  children,
  component,
  render,
  permission,
  ...props
}) {
  const history = useHistory();
  return (
    <Route {...props}>
      {routeProps => {
        if (typeof children === 'function') {
          return <Content>{children(routeProps)}</Content>;
        }

        if (!routeProps.match) {
          return null;
        }

        if (children) {
          return <Content>{children}</Content>;
        }

        if (component) {
          if (permission) {
            return grantPermission(permission) ? (
              <Content>{React.createElement(component, routeProps)}</Content>
            ) : (
              history.push('/error/error-v1')
            );
          }
          return (
            <Content>{React.createElement(component, routeProps)}</Content>
          );
        }

        if (render) {
          return <Content>{render(routeProps)}</Content>;
        }

        return null;
      }}
    </Route>
  );
}
