import React, { useEffect, useState } from 'react';

import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import Pagination from '@material-ui/lab/Pagination';
import NumberOfRows from '../../../common/SelectNumberOfRows';

import SettlementDetail from './SettlementDetail';
import SelectBox from '../../../common/SelectBox';
import { AdminPermissions, settlementTypes } from '../../../helpers/constants';
import { fetchSettlementDetails } from '../_redux/settlementDetail';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { useParams, useLocation, useHistory } from 'react-router-dom';

const SettlementDetails = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [settlementDetails, setSettlementDetail] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [searchItem, setSearchItem] = useState('');
  const [settlementType, setsettlementType] = useState('');
  const [investorId, setSelectedInvestor] = useState(null);
  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const getSettlementDetails = async () => {
    if (location.state?.investorId) {
      setSelectedInvestor(location.state?.investorId);

      return getSettlementDetailsByInvestorID(location.state?.investorId);
    }
    try {
      setLoading(true);
      const res = await fetchSettlementDetails(page, limit);

      if (res.status === 200) {
        setSettlementDetail(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const searchForSettlementDetails = async settlementType => {
    try {
      if (settlementType) {
        setLoadingData(true);
        const res = await fetchSettlementDetails(
          1,
          limit,
          settlementType,
          searchItem,
        );
        if (res.status === 200) {
          setSettlementDetail(res.data.items);
          setTotalRecord(res.data.meta.totalItems);
          setTotalPages(res.data.meta.totalPages);
          handleAlert(res.data.message, 'success');
          setLoadingData(false);
        }
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoading(false);
    }
  };
  const handleChangePage = async (event, value) => {
    const res = await fetchSettlementDetails(
      value,
      limit,
      settlementType,
      searchItem,
    );
    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setSettlementDetail(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);

    const res = await fetchSettlementDetails(
      1,
      selectedValue,
      settlementType,
      searchItem,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setSettlementDetail(res.data.items);
    }
  };

  const getSettlementDetailsByInvestorID = async investorId => {
    try {
      setLoading(true);
      const res = await fetchSettlementDetails(
        page,
        limit,
        null,
        null,
        investorId,
      );

      if (res.status === 200) {
        setSettlementDetail(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);

        // set the investorId on the location.state to be null

        const nextState = { ...location.state, investorId: null };
        history.replace({ ...location, state: nextState });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearchById = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await fetchSettlementDetails(
      1,
      limit,
      settlementType,
      null,
      value,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setSettlementDetail(res.data.items);
    }
  };
  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);
    const res = await fetchSettlementDetails(
      1,
      limit,
      settlementType,
      value.replace('+', '%2B'),
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setSettlementDetail(res.data.items);
    }
  };

  useEffect(() => {
    getSettlementDetails();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="card card-custom card-stretch gutter-b">
        <div
          className="card-header flex row"
          style={{
            flexDirection: 'column',
          }}
        >
          <section
            style={{
              width: '95%',
              marginTop: '2rem',
              marginBottom: '0.2rem',
            }}
          >
            <h1>Settlement Details</h1>
            <p>Profile Details and documentation</p>
          </section>
          <section
            className="my-5"
            style={{
              display: 'flex',
              flexDirection: 'row !important',
              justifyContent: 'space-between',
              width: '90%',
            }}
          >
            <SelectBox
              name="Settlement Type"
              options={settlementTypes}
              setValue={setsettlementType}
            />
          </section>

          <form
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              width: '90%',
              marginBottom: '2rem',
            }}
          >
            <input
              className="form-control"
              type="number"
              placeholder="Search by ID"
              name="plan"
              min={0}
              defaultValue={investorId}
              style={{
                width: '20%',
                paddingTop: '20px',
                paddingBottom: '20px',
                border: '1px solid #0071CE',
                height: '45px',
              }}
              onChange={handleSearchById}
            />

            <input
              className="form-control"
              type="text"
              placeholder="Search by  name,email or phone"
              name="plan"
              min={0}
              style={{
                width: '50%',
                paddingTop: '20px',
                paddingBottom: '20px',
                border: '1px solid #0071CE',
                height: '45px',
              }}
              onChange={handleSearch}
            />

            <button
              className="btn btn-primary px-6 mb-2 mr-1"
              onClick={e => {
                e.preventDefault();
                searchForSettlementDetails(settlementType);
              }}
              style={{
                width: '253px',
                height: '45px',
              }}
            >
              Search
              <span className="svg-icon svg-icon-light ml-2">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/General/Search.svg')}
                  alt="search icon"
                />
              </span>
              {loadingData && <span className="ml-3 spinner spinner-white" />}
            </button>
          </form>
        </div>
        <div className="card-body mt-3 mb-10 pt-0 pb-3">
          <div className="tab-content">
            <div className="table-responsive">
              <table className="table table-head-custom  table-head-bg table-border table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th style={{ minWidth: '90px' }} className="pl-7">
                      <span className="text-dark-75">Investor ID</span>
                    </th>
                    <th style={{ minWidth: '250px' }} className="text-center">
                      <span className="text-dark-75">
                        Settlement Created Date
                      </span>
                    </th>
                    <th style={{ minWidth: '180px' }} className="text-center">
                      <span className="text-dark-75">Settlement ID </span>
                    </th>

                    <th style={{ minWidth: '200px' }} className="text-center">
                      <span className="text-dark-75">Investor Name</span>
                    </th>
                    <th style={{ minWidth: '120px' }} className="text-center">
                      <span className="text-dark-75">Settlement Type</span>
                    </th>
                    <th style={{ minWidth: '120px' }} className="text-center">
                      <span className="text-dark-75">Bank Name</span>
                    </th>
                    <th style={{ minWidth: '120px' }} className="text-center">
                      <span className="text-dark-75">Settlement Details</span>
                    </th>
                    <th style={{ minWidth: '120px' }} className="text-center">
                      <span className="text-dark-75">currency</span>
                    </th>
                    {grantPermission(
                      AdminPermissions.MANIPULATE_SETTLEMENT_DETAILS,
                    ) && (
                      <th style={{ minWidth: '120px' }} className="text-center">
                        <span className="text-dark-75">Action</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {settlementDetails &&
                    settlementDetails.map(eachInvestor => (
                      <SettlementDetail
                        key={eachInvestor.id}
                        {...eachInvestor}
                        getSettlementDetails={getSettlementDetails}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
            {totalPages && totalPages > 0 ? (
              <Pagination
                page={page}
                count={totalPages}
                onChange={handleChangePage}
              />
            ) : (
              ''
            )}
            {totalRecord && totalRecord > 10 ? (
              <NumberOfRows
                handleChangeLimit={handleChangeLimit}
                totalRecord={totalRecord}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettlementDetails;
