import moment from 'moment';
import React from 'react';
import { currencyHelper, getEmail, getNames } from '../../../helpers/helper';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions, BlockStatus } from '../../../helpers/constants';
import { Link, useLocation } from 'react-router-dom';

const TransactionInstance = ({
  id: transactionId,
  transactionCreatedAt,
  operationType,
  transactionStatus,
  originalAmount,
  originalCurrency,
  convertedFinalAmount,
  finalCurrency,
  conversion,
  conversionRate,
  ndovuFee,
  ndovuFeePercentage,
  appliedConversionRate,
  units,
  goal,
  planSubscription,
  paymentRecord,
  blockStatus,
  deleteTransaction,
}) => {
  const {
    id: investorId,

    kycStatus,
    causal,
  } = planSubscription.investor;

  const { type: investmentType } = planSubscription;

  const paymentChannel = paymentRecord?.paymentLinkMetadata?.paymentChannel;

  const location = useLocation();

  return (
    <tr
      className={
        operationType === 'DEPOSIT'
          ? 'deposit_background-color row-border'
          : 'withdraw_background-color row-border'
      }
      key={transactionId}
    >
      <td className="pl-0 py-8 text-center">
        <div className="text-hover-primary mb-1 font-size-lg text-color">
          {transactionId}
        </div>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {investmentType}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {goal ? goal?.id : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">{operationType}</span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {moment(transactionCreatedAt).format('YYYY-MM-DD')}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">{investorId}</span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {causal ? causal : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {getNames(planSubscription.investor)}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          <Link
            to={{
              pathname: `/investor-profile/${investorId}`,
              state: { prevUrl: location.pathname },
            }}
          >
            {getEmail(planSubscription.investor)}
          </Link>
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">{kycStatus}</span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {paymentChannel ? paymentChannel : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {currencyHelper(originalAmount, originalCurrency)}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-lg">
          {paymentRecord?.discountedAmount
            ? currencyHelper(
                paymentRecord.discountedAmount,
                paymentRecord.currency,
              )
            : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {currencyHelper(convertedFinalAmount, finalCurrency)}
        </span>
      </td>

      {blockStatus === BlockStatus.COMPLETED ? (
        <td className="text-center">
          <span className="d-block text-color font-size-lg">
            {((originalAmount - convertedFinalAmount) / 100).toFixed(4)}%
          </span>
        </td>
      ) : null}

      <td className="text-center">
        <span className="d-block text-color font-size-lg">{conversion}</span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {conversionRate ? Number(conversionRate).toFixed(3) : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {Number(ndovuFee).toFixed(3)}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {`${Number(ndovuFeePercentage).toFixed(2)}%`}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {appliedConversionRate
            ? Number(appliedConversionRate).toFixed(3)
            : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {units ? Number(units).toFixed(4) : '---'}
        </span>
      </td>

      <td className="text-center">
        <span className="d-block text-color font-size-lg">
          {transactionStatus}
        </span>
      </td>

      {grantPermission(AdminPermissions.BLOCK_MANIPULATION) &&
      blockStatus === BlockStatus.PENDING ? (
        <td>
          <div className="text-center">
            <button
              className="btn btn-light-primary font-weight-bold"
              onClick={() => deleteTransaction(transactionId)}
            >
              {'Remove'}
            </button>
          </div>
        </td>
      ) : null}
    </tr>
  );
};

export default TransactionInstance;
