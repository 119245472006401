import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import Alert from '../../../common/Alert';
import { ExportButton } from '../../../common/CommonBtn';
import CustomDateRange from '../../../common/DateRange/DateRange';
import handleAlert from '../../../common/handleAlert';
import SelectBox from '../../../common/SelectBox';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import {
  AdminPermissions,
  paymentChannels,
  paymentChannelsMap,
  pocketWithdrawalTransactionStatus,
} from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';
import { scrollToTop } from '../../../helpers/scrolToTop';
import { exportToExcel } from '../../../utils/exportToExcel';
import { fetchAllPartners } from '../../UnassignedTransactions/_redux/transactionsCrud';
import {
  createPaymentBlock,
  fetchAllCurrencies,
  fetchPocketOpTransactions,
  searchPendingPocketTransactions,
  updatePocketTransactionStatus,
} from '../_redux/pocketsCrud';

const investorTableHeaders = [
  'Transaction ID',
  'Date Executed',
  'Investor ID',
  'Entity Name',
  'User Email',
  'Settlement details ',
  'Payment Channel',
  'Bank Name',
  'Currency',
  'Operation Type',
  'Partner',
  'Withdrawn Amount',
  'Transaction Fee',
  'Final Amount',
  'Status Description',
  'Transaction Status',
];

const PendingWithdrawals = () => {
  const [open, setOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState('');
  const [channel, setChannel] = useState('');
  const [currency, setCurrency] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [pocketTransactions, setPocketTransactions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [dateValues, setDateValues] = useState({});
  const [searchItem, setSearchItem] = useState('');
  const [limit, setLimit] = useState(10);
  const [partners, setPartners] = useState();
  const [partnerId, setPartnerId] = useState('');
  const [transactionIds, setTransactionIds] = useState([]);
  const [selectedPaymentChannel, setSelectedPaymentChannel] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [allCurrencies, setAllCurrencies] = useState('');
  const [errorMessage, setErrorMessage] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  const handleChange = (e, id, settlementChannel, currency) => {
    // Check if the selected payment channel and currency match the current transaction
    if (
      (selectedPaymentChannel &&
        selectedPaymentChannel !== settlementChannel) ||
      (selectedCurrency && selectedCurrency !== currency)
    ) {
      setSelectedPaymentChannel(selectedPaymentChannel);
      setSelectedCurrency(selectedCurrency);
      handleAlert(
        'Can only batch transactions with the same payment channel and currency.',
        'error',
      );
      return;
    }

    // Handle checkbox state changes
    if (e.target.checked) {
      // Add the transaction ID to the list if it's not already included
      if (!transactionIds.includes(id)) {
        setTransactionIds([...transactionIds, id]);
        setSelectedPaymentChannel(settlementChannel);
        setSelectedCurrency(currency);
      }
    } else {
      // Remove the transaction ID from the list
      setTransactionIds(transactionIds.filter(value => value !== id));
    }
  };

  const getPocketTransaction = async (page, limit) => {
    try {
      setLoading(true);
      const res = await fetchPocketOpTransactions(page, limit);
      if (res.status === 200) {
        setPocketTransactions(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);

      scrollToTop();
    }
  };
  const searchForPocketTransactions = async (
    kyc,
    userLevel,
    user,
    dateFilter,
    dateValues,
  ) => {
    try {
      if (kyc || userLevel || user || dateValues) {
        setLoadingData(true);
        const res = await searchPendingPocketTransactions(
          channel,
          partnerId,
          currency,
          user,
          dateFilter,
          dateValues?.startDate,
          dateValues?.endDate,
          searchItem,
          page,
          limit,
        );
        if (res.status === 200) {
          setPocketTransactions(res.data.items);
          setTotalRecord(res.data.meta.totalItems);
          setTotalPages(res.data.meta.totalPages);
          handleAlert(res.data.message, 'success');
          setLoadingData(false);
        }
      }
    } catch (error) {
      handleAlert(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);
    const res = await searchPendingPocketTransactions(
      channel,
      partnerId,
      currency,
      null,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      1,
      selectedValue,
    );
    setLimit(selectedValue);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setPocketTransactions(res.data.items);
    }
  };
  const handleDateChange = event => {
    setDateValues({ ...dateValues, [event.target.name]: event.target.value });
  };
  const handleChangePage = async (event, value) => {
    const res = await searchPendingPocketTransactions(
      channel,
      partnerId,
      currency,
      null,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      value,
      limit,
    );

    setPage(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setPocketTransactions(res.data.items);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };
  useEffect(() => {
    getPocketTransaction(page, limit);
    getPartners();
    fetchCurrencies();
  }, []);
  const setValue = (val, type, id) => {
    if (type === 'channel') {
      setChannel(val);
    } else if (type === 'partners') {
      setPartnerId(id);
    } else if (type === 'currencies') {
      setCurrency(val);
    }
  };
  const onHandleStatusChange = async (val, id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to ${val} status?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await updatePocketTransactionStatus(id, val);

        const removeReversedTransaction = pocketTransactions?.filter(
          transaction => transaction?.id !== id,
        );
        setPocketTransactions(removeReversedTransaction);

        swalWithBootstrapButtons
          .fire('Status Changed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              result.dismiss = Swal.DismissReason.cancel;
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleSearch = async e => {
    const { value } = e.target;
    setPage(1);

    const res = await searchPendingPocketTransactions(
      channel,
      partnerId,
      currency,
      null,
      dateFilter,
      dateValues?.startDate,
      dateValues?.endDate,
      searchItem,
      page,
      limit,
    );
    setSearchItem(value);
    if (res.status === 200) {
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
      setPocketTransactions(res.data.items);
    }
  };

  const getPartners = async () => {
    try {
      const res = await fetchAllPartners();
      if (res.status === 200) {
        setPartners(res.data.data);
      }
    } catch (error) {}
  };

  const fetchCurrencies = async () => {
    try {
      const res = await fetchAllCurrencies();
      if (res.status === 200) {
        setAllCurrencies(res.data.data);
      }
    } catch (error) {}
  };
  const handleCreatePaymentBlock = async transactionIds => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure about approving payments?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, create it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await createPaymentBlock(
          transactionIds,
          selectedCurrency,
          selectedPaymentChannel,
        );
        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getPocketTransaction(page, limit);
              setTransactionIds([]);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
    setSelectedCurrency(null);
    setSelectedPaymentChannel(null);
  };

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <main className="card card-custom card-stretch gutter-b">
          <section
            style={{
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div className="card-header">
              <div
                className="mt-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {errorMessage?.alertMessage && <Alert alert={errorMessage} />}
                <h1>Pending withdrawals</h1>
                <p>Transactions</p>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <ExportButton
                    exportDocument={() =>
                      exportToExcel(
                        setExportLoading,
                        'v1/admin/pocket/transactions/download?status=PENDING&source=WITHDRAW&notInBatch=false',
                        {
                          ...(partnerId && { partnerId }),
                          ...(searchItem && { search: searchItem }),

                          ...(dateValues?.startDate && {
                            startDate: dateValues?.startDate,
                          }),
                          ...(dateValues?.endDate && {
                            endDate: dateValues?.endDate,
                          }),
                        },
                      )
                    }
                    loading={exportLoading}
                    btnText={'Export to Excel'}
                    width="253px"
                    height="45px"
                    marginTop
                  />
                </div>
              </div>
              <div
                style={{
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <SelectBox
                    name="Payment channel"
                    options={paymentChannels}
                    width="250px"
                    setValue={setValue}
                    type="channel"
                  />
                  {allCurrencies && (
                    <SelectBox
                      name="Currency"
                      options={allCurrencies}
                      width="250px"
                      setValue={setValue}
                      type="currencies"
                    />
                  )}

                  {partners && (
                    <SelectBox
                      name="Partner Filters"
                      options={partners}
                      width="250px"
                      setValue={setValue}
                      type="partners"
                    />
                  )}

                  <button
                    type="button"
                    style={{
                      width: '250px',
                      height: '45px',
                      borderRadius: '10px',
                      padding: '20px, 148px, 20px, 135px',
                      border: '1px solid #0071CE',
                      color: '#0071CE',
                      background: '#FFFFFF',
                    }}
                    onClick={() => setOpen(true)}
                  >
                    {' '}
                    Date Filters
                  </button>
                </form>
                <form
                  className="d-flex flex-wrap"
                  style={{
                    marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search by name,email or phone number"
                    name="plan"
                    min={0}
                    style={{
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      width: '80%',
                      height: '45px',
                      padding: '12px 20px 12px 20px',
                      borderRadius: '10px',
                      border: '1px solid #0071CE',
                    }}
                    onChange={handleSearch}
                  />

                  <button
                    className="btn btn-primary px-6 mb-2 mr-1"
                    onClick={e => {
                      e.preventDefault();
                      searchForPocketTransactions(
                        channel,
                        partnerId,
                        userStatus,
                        dateFilter,
                        dateValues,
                      );
                    }}
                    style={{
                      color: 'white',
                      background: '#0071CE',
                      width: '165px',
                      border: '1px solid #0071CE',
                      borderRadius: '5px',
                    }}
                  >
                    Search
                    <span className="svg-icon svg-icon-light ml-2">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/General/Search.svg',
                        )}
                        alt="search icon"
                      />
                    </span>
                    {loadingData && (
                      <span className="ml-3 spinner spinner-white" />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </section>
          <section className="card-body mt-3 mb-10 pt-0 pb-3">
            <div className="tab-content">
              <div className="table-responsive ">
                <table className="table table-head-custom table-head-bg  table-border table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {investorTableHeaders.map((header, index) => (
                        <th
                          key={index}
                          style={{ minWidth: '100px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span
                            style={{
                              color: '#3699FF',
                            }}
                          >
                            {header}
                          </span>
                        </th>
                      ))}
                      {grantPermission(
                        AdminPermissions.POCKETS_MANIPULATION,
                      ) ? (
                        <th style={{ minWidth: '100px', textAlign: 'center' }}>
                          <span style={{ color: '#3699FF' }}>Select</span>
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {pocketTransactions.map(transaction => (
                      <tr key={transaction.id}>
                        <td className="pl-0 py-8 text-center">
                          <span className="d-block font-size-sm">
                            {transaction.id}
                          </span>
                        </td>
                        <td className="pl-0 py-8 text-center ">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {moment(transaction.createdAt).format('YYYY-MM-DD')}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center ">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.investorId}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center ">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction?.entityName}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.email}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.settlementDetail
                              ? transaction.settlementDetail.type ===
                                paymentChannelsMap.BANK
                                ? transaction.settlementDetail.accountNumber
                                : transaction.settlementDetail.phoneNumber
                              : '---'}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.settlementDetail
                              ? transaction?.settlementDetail.serviceProvider
                              : '--'}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.settlementDetail?.bankName
                              ? transaction?.settlementDetail?.bankName
                              : '--'}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.currency}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.operation}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {transaction.partnerName}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {formatNumber(transaction.originalAmount)}
                          </div>
                        </td>

                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {formatNumber(transaction.fee)}
                          </div>
                        </td>
                        <td className="pl-0 py-8 text-center">
                          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
                            {formatNumber(transaction.finalAmount)}
                          </div>
                        </td>

                        <td className="pr-0  py-8 text-center">
                          {transaction.statusDescription
                            ? transaction.statusDescription
                            : '---'}
                        </td>

                        {grantPermission(
                          AdminPermissions.POCKETS_MANIPULATION,
                        ) ? (
                          <td
                            className="pl-0 py-8 text-center"
                            style={{
                              minWidth: '120px',
                            }}
                          >
                            <select
                              className="btn btn-light-primary font-weight-bold"
                              onChange={e =>
                                onHandleStatusChange(
                                  e.target.value.toUpperCase(),
                                  transaction.id,
                                )
                              }
                              value={transaction.status}
                              style={{
                                border: `1px solid #2D9A2D`,
                                color: 'green',
                                background: '#FFFFFF',
                                width: '100%',
                                height: '45px',
                                borderRadius: '10px',
                                padding: '20px, 148px, 20px, 135px',
                              }}
                            >
                              {pocketWithdrawalTransactionStatus.map(option => {
                                return (
                                  <option
                                    data-id={option.id}
                                    key={option.id}
                                    value={option.value || option.name}
                                  >
                                    {option.value || option.name}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        ) : (
                          <td
                            className="pl-0 py-8 text-center"
                            style={{
                              minWidth: '120px',
                            }}
                          >
                            {transaction.status}
                          </td>
                        )}
                        {grantPermission(
                          AdminPermissions.POCKETS_MANIPULATION,
                        ) ? (
                          <td
                            className="pl-0 py-8 text-center"
                            style={{
                              minWidth: '100px',
                            }}
                          >
                            <input
                              type="checkbox"
                              name="Checkboxes3_1"
                              onChange={e =>
                                handleChange(
                                  e,
                                  transaction.id,
                                  transaction?.settlementDetail &&
                                    transaction?.settlementDetail
                                      ?.serviceProvider,
                                  transaction.currency,
                                )
                              }
                              disabled={
                                transaction.operation !== 'DEBIT' ||
                                transaction.status !== 'PENDING'
                              }
                              checked={transactionIds.includes(transaction.id)}
                            />
                          </td>
                        ) : null}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>
            <div className="text-right mt-8">
              <span className="mr-8">
                <strong>Selected</strong>:{' '}
                {transactionIds.length ? transactionIds.length : 0}
              </span>
              <button
                className="btn btn-light-primary font-weight-bold"
                onClick={() => handleCreatePaymentBlock(transactionIds)}
                disabled={transactionIds.length < 1}
              >
                Create withdrawal Batch
              </button>
            </div>
            <CustomDateRange
              open={open}
              setOpen={setOpen}
              handleDateChange={handleDateChange}
              searchTransaction={searchForPocketTransactions}
              operation={channel}
              partnerId={partnerId}
              userStatus={userStatus}
              dateFilter={dateFilter}
              dateValues={dateValues}
            />
          </section>
        </main>
      )}
    </>
  );
};

export default PendingWithdrawals;
