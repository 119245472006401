import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import NumberOfRows from '../../../common/SelectNumberOfRows';
import { getAllCorporateActions } from '../_redux';
import CorporateActionTable from './CorporateActionTable';

const tableHeaders = [
  'Fund ID',
  'Fund Name',
  'Action',
  'Factor',
  'Date',
  'Initiator',
  'Original units',
  'Final units',
  'Initial share price',
  'Target share price',
];

const CorporateActions = () => {
  const [loading, setLoading] = useState(true);
  const [corporateActions, setCorporateActions] = useState(null);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const handleChangeLimit = async event => {
    const { value: selectedValue } = event.target;
    setPage(1);

    const res = await getAllCorporateActions(1, selectedValue);

    setLimit(selectedValue);
    if (res.status === 200) {
      setCorporateActions(res.data.items);
      setTotalRecord(res.data.meta.totalItems);
      setTotalPages(res.data.meta.totalPages);
    }
  };

  const handleChangePage = async (event, value) => {
    const res = await getAllCorporateActions(value, limit);

    setPage(value);
    if (res.status === 200) {
      setCorporateActions(res.data.items);
      setTotalRecord(res.data.meta.totalItems);
      window.scrollTo({ behavior: 'smooth', top: '0px' });
    }
  };

  const fetchCorporateActions = async (page, limit) => {
    try {
      setLoading(true);
      const res = await getAllCorporateActions(page, limit);
      if (res.status === 200) {
        setCorporateActions(res.data.items);
        setTotalRecord(res.data.meta.totalItems);
        setTotalPages(res.data.meta.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleAlert(error.response.data.message, 'error');
    }
  };

  useEffect(() => {
    fetchCorporateActions(page, limit);
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center" style={{ marginTop: '100px' }}>
          <img
            style={{ width: '70px' }}
            src={toAbsoluteUrl('/media/logos/loading.gif')}
            alt={'loading gif'}
          />
        </div>
      ) : (
        <main className="card card-custom card-stretch gutter-b">
          <section
            style={{
              width: '95%',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <div className="card-header">
              <div
                className="mt-10"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h1>Corporate Actions</h1>
              </div>
            </div>
          </section>
          <section className="card-body mt-3 mb-10 pt-0 pb-3">
            <div className="tab-content">
              <div>
                <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                      {tableHeaders.map((header, index) => (
                        <th
                          key={index}
                          style={{ minWidth: '100px', textAlign: 'center' }}
                          className="pl-7"
                        >
                          <span style={{ color: '#3699FF' }}> {header} </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {corporateActions?.map(eachTransaction => (
                      <CorporateActionTable
                        {...eachTransaction}
                        key={eachTransaction.id}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10">
              {totalPages && totalPages > 0 ? (
                <Pagination
                  page={page}
                  count={totalPages}
                  onChange={handleChangePage}
                />
              ) : (
                ''
              )}
              {totalRecord && totalRecord > 10 ? (
                <NumberOfRows
                  handleChangeLimit={handleChangeLimit}
                  totalRecord={totalRecord}
                />
              ) : (
                ''
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default CorporateActions;
