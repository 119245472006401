import React from 'react';
import SVG from 'react-inlinesvg';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { AdminPermissions } from '../../../../../app/helpers/constants';
import { checkIsActive, toAbsoluteUrl } from '../../../../_helpers';
import { grantPermission } from '../../content/Permissions';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : '';
  };

  const closeLeftMenuAsideInMobile = () => {
    let asideItemElement = document.getElementById('kt_aside');
    let asideItemToggleBtn = document.getElementById('kt_aside_mobile_toggle');
    asideItemElement.classList.remove('aside-on');
    asideItemToggleBtn.click();
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {grantPermission(AdminPermissions.TRANSACTION_VIEW) ||
        grantPermission(AdminPermissions.USER_VIEW) ||
        grantPermission(AdminPermissions.VIEW_SETTLEMENT_DETAILS) ? (
          <li
            className={`menu-item menu-item-submenu menu-item-open`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="#">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Union.svg')} />
              </span>
              <span className="menu-text">Users </span>
            </NavLink>
            {/* Collapsible submenu */}
            <ul className={`menu-submenu menu-submenu-open`}>
              {grantPermission(AdminPermissions.USER_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/dashboard',
                    false,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink className="menu-link" to="/dashboard">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Design/Layers.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Users Profile</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.USER_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/investors',
                    false,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink className="menu-link" to="/investors">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Design/Layers.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Investors</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.VIEW_SETTLEMENT_DETAILS) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/settlement-details',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/settlement-details"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Navigation/Close.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Settlement Details</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        ) : (
          ''
        )}
        {grantPermission(AdminPermissions.TRANSACTION_VIEW) ||
        grantPermission(AdminPermissions.TRANSACTION_VIEW) ||
        grantPermission(AdminPermissions.BLOCK_VIEW) ||
        grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) ? (
          <li
            className={`menu-item menu-item-submenu menu-item-open`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              to="#"
              style={{
                textDecoration: 'none',
              }}
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Shopping/Dollar.svg')}
                />
              </span>
              <span className="menu-text">Investments</span>
            </NavLink>

            {/* Collapsible submenu */}
            <ul className={`menu-submenu menu-submenu-open`}>
              {grantPermission(AdminPermissions.TRANSACTION_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/unassigned-transactions',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/unassigned-transactions"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Dollar.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Unassigned Transactions</span>
                  </NavLink>
                </li>
              )}

              {grantPermission(AdminPermissions.TRANSACTION_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/archived-transactions',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/archived-transactions"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Navigation/Close.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Archived Transactions</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.BLOCK_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive('/blocks', true)}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/blocks"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Communication/Clipboard-list.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Blocks</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.DAILY_TRADE_CONFIGURATION) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/daily-trades',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/daily-trades"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Dollar.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Daily Trades</span>
                  </NavLink>
                </li>
              )}

              {grantPermission(AdminPermissions.VIEW_RECURRING_PAYMENTS) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/recurring-payments',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/recurring-payments"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Dollar.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Recurring Payments</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        ) : (
          ''
        )}
        {grantPermission(AdminPermissions.VIEW_POCKETS) ||
        grantPermission(AdminPermissions.VIEW_POCKETS) ||
        grantPermission(AdminPermissions.VIEW_PAYMENT_BATCH) ? (
          <li
            className={`menu-item menu-item-submenu 
            menu-item-open
          `}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="#">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Shopping/Pocket.svg')}
                />
              </span>
              <span className="menu-text">Pockets</span>
            </NavLink>

            {/* Collapsible submenu */}
            <ul className={`menu-submenu menu-submenu-open`}>
              {grantPermission(AdminPermissions.VIEW_POCKETS) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/pocket-transactions',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/pocket-transactions"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Pocket.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Pocket Transactions</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.VIEW_POCKETS) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/pending-withdrawals',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/pending-withdrawals"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Pocket3.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Pending withdrawals</span>
                  </NavLink>
                </li>
              )}
              {grantPermission(AdminPermissions.VIEW_PAYMENT_BATCH) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/payment-batch',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/payment-batch"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Pocket2.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Payment Batch</span>
                  </NavLink>
                </li>
              )}
            </ul>
          </li>
        ) : (
          ''
        )}
        {grantPermission(AdminPermissions.FUND_VIEW) ||
        grantPermission(AdminPermissions.PARTNER_VIEW) ||
        grantPermission(AdminPermissions.SUPER_ADMIN) ||
        grantPermission(AdminPermissions.VIEW_CONVERSIONS_RATE) ? (
          <li
            className={`menu-item menu-item-submenu menu-item-open`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="#">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl('/media/svg/icons/Shopping/Settings.svg')}
                />
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>

            {/* Collapsible submenu */}
            <ul className={`menu-submenu  menu-submenu-open`}>
              {grantPermission(AdminPermissions.FUND_VIEW) ? (
                <li
                  className={`menu-item ${getMenuItemActive('/plans', true)}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/plans"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Pocket.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Funds</span>
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {grantPermission(AdminPermissions.VIEW_CORPORATE_ACTIONS) ? (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/corporate-actions',
                    true,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink
                    onClick={closeLeftMenuAsideInMobile}
                    to="/corporate-actions"
                    className="menu-link"
                  >
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Shopping/Pocket.svg',
                        )}
                      />
                    </span>
                    <span className="menu-text">Corporate Actions</span>
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {grantPermission(AdminPermissions.PARTNER_VIEW) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/partners',
                    false,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink className="menu-link" to="/partners">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Design/Union.svg')}
                      />
                    </span>
                    <span className="menu-text">Partners</span>
                  </NavLink>
                </li>
              )}

              {grantPermission(AdminPermissions.VIEW_CONVERSIONS_RATE) && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    '/conversions',
                    false,
                  )}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink className="menu-link" to="/conversions">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Design/Union.svg')}
                      />
                    </span>
                    <span className="menu-text">Conversions</span>
                  </NavLink>
                </li>
              )}

              {grantPermission(AdminPermissions.SUPER_ADMIN) ? (
                <li
                  className={`menu-item ${getMenuItemActive('/admins', false)}`}
                  aria-haspopup="true"
                  style={{
                    marginLeft: '45px',
                  }}
                >
                  <NavLink className="menu-link" to="/admins">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl('/media/svg/icons/Design/Union.svg')}
                      />
                    </span>
                    <span className="menu-text">Admins</span>
                  </NavLink>
                </li>
              ) : (
                ''
              )}
            </ul>
          </li>
        ) : (
          ''
        )}
      </ul>
    </>
  );
}
