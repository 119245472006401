import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { fetchBlock, moveTransactionsToUnassigned } from '../_redux/blocksCrud';

import TransactionInstance from './TransactionInstance';
import Back from '../../../common/Back';
import Loader from '../../../common/Loader';
import ErrorCard from '../../../common/ErrorCard';
import { exportToExcel } from '../../../utils/exportToExcel';
import { ExportButton } from '../../../common/CommonBtn';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions, BlockStatus } from '../../../helpers/constants';

const FundTransactions = props => {
  const { blockId } = props.match.params;
  const [fundInstance, setFundInstance] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [blockDetails, setblockDetails] = useState();

  const { fundInstanceId } = props.match.params;

  const [exportLoading, setExportLoading] = useState(false);
  const getFundInstance = async () => {
    try {
      const res = await fetchBlock(blockId);
      if (res.status === 200) {
        setFundInstance(
          res.data.data.fundInstances.find(
            fund => fund.id === Number(fundInstanceId),
          ),
        );
        setblockDetails(res.data.data);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getFundInstance();
  }, [blockId, fundInstanceId]);

  const deleteTransaction = async transactionId => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const modalTitle =
      'Are you sure you want to remove this transaction from current block?';
    const result = await swalWithBootstrapButtons.fire({
      title: modalTitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        await moveTransactionsToUnassigned(blockId, transactionId);
        getFundInstance();
      } catch (error) {
        setErrorMessage(error?.response?.data?.message);
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/blocks'} errorMessage={errorMessage} />
      ) : (
        <>
          {!fundInstance ? (
            <Loader />
          ) : (
            <>
              <div className="mb-5">
                <Back url={`/view-fund/${blockId}`} />
              </div>

              <div
                key={fundInstanceId}
                className="card card-custom gutter-b mb-5 pb-5"
              >
                <div className="card-body">
                  <div className="tab-content special-content">
                    <div className="table-responsive">
                      <table className="table  table-vertical-center">
                        <thead className="main-table-head">
                          <tr className="text-center text-uppercase">
                            <th style={{ minWidth: '200px' }} className="pl-7">
                              <span className="text-dark-75 special-content">
                                FundInstance ID
                              </span>
                            </th>
                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">Plan ID</span>
                            </th>
                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">Fund Name</span>
                            </th>
                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Fund Cool Name
                              </span>
                            </th>
                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">Identifier</span>
                            </th>
                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Fund Manager ID
                              </span>
                            </th>
                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Fund Manager Name
                              </span>
                            </th>
                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Provider ID</span>
                            </th>
                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Provider Name
                              </span>
                            </th>
                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Bid Price</span>
                            </th>
                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Ask Price</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="pl-0 py-8 text-center">
                              <div className="special-color mb-1 font-size-lg">
                                {fundInstanceId}
                              </div>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.id}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.name}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.coolName}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.identifier}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.fundManager?.id}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.plan?.fundManager?.fundname}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {''}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {''}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.bidPrice}
                              </span>
                            </td>
                            <td className="text-center">
                              <span className="special-color  d-block font-size-lg">
                                {fundInstance?.askPrice}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="mt-5 mb-15">
                    <ExportButton
                      exportDocument={() =>
                        exportToExcel(
                          setExportLoading,
                          `v1/admin/blocks/${blockId}/export-fund-instance-transactions?fundInstanceId=${fundInstanceId}`,
                        )
                      }
                      loading={exportLoading}
                      btnText="Export to Excel"
                    />
                  </div>
                  <div className="separator separator-dashed my-10 font-weight-bold" />
                  <div className="tab-content">
                    <div className="table-responsive">
                      <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                        <thead>
                          <tr className="text-center text-uppercase">
                            <th style={{ minWidth: '150px' }} className="pl-7">
                              <span className="text-dark-75">
                                Transaction ID
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Investment Type
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">Goal Id</span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Operation Type
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Date Executed
                              </span>
                            </th>

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Investor ID</span>
                            </th>

                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Investor Reference
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Investor Name
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Investor Email
                              </span>
                            </th>

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">KYC Status</span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Payment Channel
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Original amount
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Discount amount
                              </span>
                            </th>

                            <th style={{ minWidth: '220px' }}>
                              <span className="text-dark-75">
                                Final converted amount
                              </span>
                            </th>

                            {blockDetails?.status === BlockStatus.COMPLETED ? (
                              <th style={{ minWidth: '280px' }}>
                                <span className="text-dark-75">
                                  Withdrawal amount discrepancy
                                </span>
                              </th>
                            ) : null}

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Conversion</span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">
                                Conversion rate
                              </span>
                            </th>

                            <th style={{ minWidth: '150px' }}>
                              <span className="text-dark-75">Ndovu fee</span>
                            </th>

                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Ndovu fee percentage
                              </span>
                            </th>

                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Applied conversion rate
                              </span>
                            </th>

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Units</span>
                            </th>

                            <th style={{ minWidth: '180px' }}>
                              <span className="text-dark-75">
                                Transaction Status
                              </span>
                            </th>

                            {grantPermission(
                              AdminPermissions.BLOCK_MANIPULATION,
                            ) &&
                            blockDetails?.status === BlockStatus.PENDING ? (
                              <th style={{ minWidth: '180px' }}>
                                <span className="text-dark-75">Action</span>
                              </th>
                            ) : null}
                          </tr>
                        </thead>

                        <tbody>
                          {fundInstance?.transactions?.map(eachData => (
                            <TransactionInstance
                              key={eachData.id}
                              {...eachData}
                              blockStatus={blockDetails?.status}
                              deleteTransaction={deleteTransaction}
                            />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FundTransactions;
