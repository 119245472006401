import moment from 'moment';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import {
  AdminPermissions,
  TransactionStatus,
  pocketTransactionSource,
  pocketTransactionStatusDropdownOptions,
  pocketWithdrawalTransactionStatus,
} from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';
import { updatePocketTransactionStatus } from '../_redux/pocketsCrud';
import ConfirmationModal from './ConfirmationModal';

const PocketTransactionTable = ({
  createdAt,
  email,
  phoneNumber,
  operation,
  id,
  status,
  ndovuConversionPercentageFee,
  conversionRate,
  forexNdovuFee,
  getTransaction,
  getPartners,
  partnerName,
  originalAmount,
  settlementDetail,
  source,
  fee,
  plan,
  statusDescription,
  pending,
  paymentChannel,
  setAlert,
  currency,
}) => {
  const [open, setOpen] = useState(false);
  const [chosenStatus, setChosenStatus] = useState('');
  const [currentStatus, setCurrentStatus] = useState(status);

  const onHandleStatusChange = async (val, id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to ${val} status?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await updatePocketTransactionStatus(id, val);
        setCurrentStatus(val);
        swalWithBootstrapButtons
          .fire('Status Changed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              result.dismiss = Swal.DismissReason.cancel;
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const unChangedTransactionStatus = [
    TransactionStatus.COMPLETED,
    TransactionStatus.ARCHIVED,
    TransactionStatus.REVERSED,
  ];

  const transactionStatusOptions = (
    transactionSource,
    currentTransactionStatus,
  ) => {
    if (transactionSource === pocketTransactionSource.WITHDRAW) {
      return pocketWithdrawalTransactionStatus;
    } else {
      return pocketTransactionStatusDropdownOptions[currentTransactionStatus];
    }
  };

  return (
    <tr>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">{id}</span>
      </td>
      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD, h:mm:ss a')}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {plan ? plan?.identifier : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {plan ? plan?.coolName : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {email}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {phoneNumber}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {partnerName}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {operation}
        </div>
      </td>
      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {source}
          </div>
        </td>
      )}
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {currency}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {formatNumber(originalAmount)}
        </div>
      </td>

      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {formatNumber(fee)}
          </div>
        </td>
      )}

      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {formatNumber(fee)}
          </div>
        </td>
      )}

      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {forexNdovuFee && forexNdovuFee !== null
              ? forexNdovuFee.toFixed(2)
              : '---'}
          </div>
        </td>
      )}

      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {ndovuConversionPercentageFee &&
            ndovuConversionPercentageFee !== null
              ? ndovuConversionPercentageFee.toFixed(2)
              : '---'}
          </div>
        </td>
      )}

      {pending && (
        <td className="pl-0 py-8 text-center">
          <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
            {conversionRate && conversionRate !== null
              ? conversionRate.toFixed(2)
              : '---'}
          </div>
        </td>
      )}
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">
          {settlementDetail
            ? settlementDetail.type === 'BANK'
              ? settlementDetail.accountNumber
              : settlementDetail.phoneNumber
            : '---'}
        </span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">
          {source === 'TOP_UP'
            ? paymentChannel
              ? paymentChannel
              : '---'
            : settlementDetail
            ? settlementDetail.type
            : '---'}
        </span>
      </td>
      <td className="pr-0 text-center">
        <span className="d-block font-size-sm">
          {statusDescription ? statusDescription : '---'}
        </span>
      </td>

      {grantPermission(AdminPermissions.POCKETS_MANIPULATION) ? (
        <td className="px-2 text-center" style={{ minWidth: '220px' }}>
          {unChangedTransactionStatus.includes(currentStatus) ? (
            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
              {currentStatus}
            </div>
          ) : (
            <select
              className="btn btn-light-primary font-weight-bold"
              onChange={e =>
                onHandleStatusChange(e.target.value.toUpperCase(), id)
              }
              value={currentStatus}
              style={{
                border: `1px solid #2D9A2D`,
                color: 'green',
                background: '#FFFFFF',
                width: '100%',
                height: '45px',
                borderRadius: '10px',
                padding: '20px, 148px, 20px, 135px',
              }}
            >
              {transactionStatusOptions(source, currentStatus)?.map(option => {
                return (
                  <option
                    data-id={option.id}
                    key={option.id}
                    value={option.value || option.name}
                  >
                    {option.value || option.name}
                  </option>
                );
              })}
            </select>
          )}
        </td>
      ) : (
        <td className="px-2 text-center" style={{ minWidth: '220px' }}>
          {currentStatus}
        </td>
      )}

      <ConfirmationModal
        open={open}
        setModalStatus={setOpen}
        status={chosenStatus}
        id={id}
        getTransaction={getTransaction}
        getPartners={getPartners}
      />
    </tr>
  );
};

export default PocketTransactionTable;
