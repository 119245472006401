export const removeNonNumeric = amount =>
  amount?.toString()?.replace(/[^0-9.]/g, '');

export const hasOnlyOneDot = amount => {
  const dots = amount
    ?.toString()
    ?.split('')
    ?.filter(char => char === '.');
  return dots?.length > 1 ? false : true;
};
