import React from 'react';

export const DateInput = ({
  label,
  name,
  handleDateChange,
  classes,
  max,
  value,
  marginTop,
}) => {
  return (
    <div
      className={`d-flex align-items-center ${marginTop ? marginTop : 'mt-5'}`}
    >
      <div className="mr-5">{label}</div>
      <div>
        <input
          type="date"
          className={`${classes} form-control`}
          name={name}
          onChange={handleDateChange}
          max={max}
          defaultValue={value && value}
        />
      </div>
    </div>
  );
};
