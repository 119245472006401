import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../icons/backIcon.svg';

const Back = ({ url }) => {
  return (
    <Link to={url}>
      <img src={BackIcon} alt={'Back icon'} />
      <span className="ml-2">Back</span>
    </Link>
  );
};

export default Back;
