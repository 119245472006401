import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { fetchAllPartners } from '../_redux/partnersCrud';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import ErrorCard from '../../../common/ErrorCard';
import AllPartnerInfo from './allPartners';

const AllPartners = () => {
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const getPartners = async () => {
    try {
      setLoading(true);
      const res = await fetchAllPartners();
      if (res.status === 200) {
        let data = res.data.data;
        setPartners(data);
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div>
          <div className="card card-custom gutter-b">
            <div className="card-header">
              <h4 className="pt-8">All Partners</h4>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-capitalize text-center">
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Partner Name</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            Investment Approach
                          </span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Investment Type</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">KYC Type</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">Blocks</span>
                        </th>
                        <th style={{ minWidth: '120px' }}>
                          <span className="text-dark-75">
                            Unassigned Transactions
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {partners &&
                        partners.map(eachPartner => (
                          <AllPartnerInfo
                            key={eachPartner.id}
                            partnerInfo={eachPartner}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllPartners;
