import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_TRANSACTIONS = `${REACT_APP_BASE_URL}/v1/admin/transactions`;

const fetchArchivedTransactions = async (
  investmentType,
  page,
  limit,
  search,
  startDate,
  endDate,
  transactionStatus,
) => {
  let URL = `${FETCH_TRANSACTIONS}`;
  let queryParams = [
    'investmentType',
    'page',
    'limit',
    'search',
    'startDate',
    'endDate',
    'transactionStatus',
  ];
  let functionParams = [
    investmentType,
    page,
    limit,
    search,
    startDate,
    endDate,
    transactionStatus,
  ];

  let params = {};

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      params[queryParams[i]] = functionParams[i];
    }
  }

  const res = await axios.get(URL, { params });
  return res;
};

const changeRefundStatus = async id => {
  const res = await axios.put(
    `${REACT_APP_BASE_URL}/v1/admin/transactions/${id}/refunded`,
  );
  return res;
};

const changeTrnxnStatusToReconciled = async id => {
  const res = await axios.put(
    // `${REACT_APP_BASE_URL}/v1/admin/transactions/${id}/reaccept`,
    `${REACT_APP_BASE_URL}/v1/admin/transactions/${id}/`,
  );
  return res;
};

export {
  fetchArchivedTransactions,
  changeRefundStatus,
  changeTrnxnStatusToReconciled,
};
