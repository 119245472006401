import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import ErrorCard from '../../../common/ErrorCard';
import { AdminPermissions } from '../../../helpers/constants';
import {
  fetchAllplansConfiguration,
  getAllCountries,
  investorProfilePlanSettings,
  planSettings,
} from '../_redux/planCrud';
import AllPlan from '../pages/allPlans';
import SelectBox from '../../../common/SelectBox';

const PlanConfiguration = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [planTobeChanged, setplanTobeChanged] = useState([]);
  const [planIdsInvestable, setplanIdsInvestable] = useState([]);
  const [planIdsViewable, setplanIdsViewable] = useState([]);
  const [plansIdsHotFund, setplanIdsHotFund] = useState([]);
  const [planIdsDiscountable, setPlanIdsDiscountable] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [investmentProfiles, setInvestmentProfiles] = useState([]);
  const [conservativeProfile, setConservativeProfile] = useState([{}]);
  const [balancedProfile, setBalancedProfile] = useState([{}]);
  const [aggressiveProfile, setAggressiveProfile] = useState([{}]);
  const [aggressiveEditable, setAggressiveEditable] = useState(true);
  const [balancedEditable, setBalancedEditable] = useState(true);
  const [conservativeEditable, setConservativeEditable] = useState(true);
  const [conservativeProfileValues, setConservativeProfileValues] = useState(
    [],
  );
  const [balancedProfileValues, setBalancedProfileValues] = useState([]);
  const [aggressiveProfileValues, setAggressiveProfileValues] = useState([]);
  const [nonInvestableNotes, setNonInvestableNotes] = useState([]);

  const getPlans = async () => {
    try {
      setLoading(true);
      const res = await fetchAllplansConfiguration('true', null);
      if (res.status === 200) {
        let data = res.data.data;
        setPlans(data);
        data.map(eachPlan =>
          eachPlan.plans.forEach(element => {
            if (element?.settings[0].viewable) {
              planIdsViewable.push(element?.settings[0].id);
            }
            if (element?.settings[0].investable) {
              planIdsInvestable.push(element?.settings[0].id);
            }
            if (element?.settings[0].isCurrentOffer) {
              plansIdsHotFund.push(element?.settings[0].id);
            }
            if (element?.investmentProfilePlan) {
              conservativeProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 1,
                )?.percentage,
              });
              balancedProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 2,
                )?.percentage,
              });
              aggressiveProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 3,
                )?.percentage,
              });
            }
            if (element?.settings[0].nonInvestableNote) {
              nonInvestableNotes.push({
                id: element.settings[0].id,
                nonInvestableNote: element.nonInvestableNote,
              });
            }
          }),
        );
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const getPlansWithCountry = async e => {
    try {
      setLoading(true);
      const res = await fetchAllplansConfiguration('true', e);
      if (res.status === 200) {
        let data = res.data.data;
        setPlans(data);
        data.map(eachPlan =>
          eachPlan.plans.forEach(element => {
            if (element?.settings[0].discountable) {
              planIdsDiscountable.push(element?.settings[0].id);
            }
            if (element?.settings[0].viewable) {
              planIdsViewable.push(element?.settings[0].id);
            }
            if (element?.settings[0].investable) {
              planIdsInvestable.push(element?.settings[0].id);
            }
            if (element?.investmentProfilePlan) {
              conservativeProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 1,
                )?.percentage,
              });
              balancedProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 2,
                )?.percentage,
              });
              aggressiveProfile.push({
                planId: element?.id,
                percentage: element?.investmentProfilePlan.find(
                  x => x?.investmentProfile?.id === 3,
                )?.percentage,
              });
            }
            if (element?.settings[0].nonInvestableNote) {
              nonInvestableNotes.push({
                id: element.settings[0].id,
                nonInvestableNote: element.nonInvestableNote,
              });
            }
          }),
        );
        setLoading(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };
  const handleSubmit = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure about updating funds?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await planSettings(planTobeChanged);
        swalWithBootstrapButtons
          .fire('Updates!', response.data.message, 'success')
          .then(async res => {
            if (res.isConfirmed) {
              setConservativeEditable(true);
              setBalancedEditable(true);
              setAggressiveEditable(true);
              setConservativeProfileValues([]);
              setBalancedProfileValues([]);
              setAggressiveProfileValues([]);
              if (selectedCountry) {
                await getPlansWithCountry(selectedCountry);
              } else {
                await getPlans();
              }
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleSubmitPercentageAllocation = async investmentProfile => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: 'Are you sure about updating this investment profile',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        let bodyReq = {};
        if (investmentProfile === 'conservative') {
          bodyReq = {
            investmentProfileId: 1,
            values: conservativeProfileValues,
          };
        } else if (investmentProfile === 'balanced') {
          bodyReq = {
            investmentProfileId: 2,
            values: balancedProfileValues,
          };
        } else {
          bodyReq = {
            investmentProfileId: 3,
            values: aggressiveProfileValues,
          };
        }
        const response = await investorProfilePlanSettings(bodyReq);
        swalWithBootstrapButtons
          .fire('Updates!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              setConservativeEditable(true);
              setBalancedEditable(true);
              setAggressiveEditable(true);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const pushplanTobeChanged = (keyName, payload) => {
    if (planTobeChanged.length) {
      let payloadIndex = planTobeChanged.findIndex(x => x.id === payload.id);
      if (payloadIndex !== -1) {
        planTobeChanged[payloadIndex][keyName] = payload[keyName];
      } else {
        planTobeChanged.push(payload);
      }
    } else {
      planTobeChanged.push(payload);
    }
  };

  const handleChecks = (checked, id, name) => {
    if (name === 'investable') {
      if (checked) {
        !planIdsInvestable.includes(id) &&
          setplanIdsInvestable(planIdsInvestable.concat(id));
      } else {
        setplanIdsInvestable(planIdsInvestable.filter(value => value !== id));
      }
    } else if (name === 'hotFund') {
      if (checked) {
        !plansIdsHotFund.includes(id) &&
          setplanIdsHotFund(plansIdsHotFund.concat(id));
      } else {
        setplanIdsHotFund(plansIdsHotFund.filter(value => value !== id));
      }
    } else if (name === 'discountable') {
      if (checked) {
        !planIdsDiscountable.includes(id) &&
          setPlanIdsDiscountable(planIdsDiscountable.concat(id));
      } else {
        setPlanIdsDiscountable(
          planIdsDiscountable.filter(value => value !== id),
        );
      }
    } else {
      if (checked) {
        !planIdsViewable.includes(id) &&
          setplanIdsViewable(planIdsViewable.concat(id));
      } else {
        setplanIdsViewable(planIdsViewable.filter(value => value !== id));
      }
    }
  };

  const handleChange = (e, id) => {
    if (id) {
      const { checked, name } = e.target;

      let payload = { id };

      if (checked) {
        payload[name] = true;
        payload['nonInvestableNote'] = handleCheckWhetherItemExists(id);
        pushplanTobeChanged(name, payload);
      } else {
        payload[name] = false;
        payload['nonInvestableNote'] = handleCheckWhetherItemExists(id);
        pushplanTobeChanged(name, payload);
      }
      handleChecks(checked, id, name);
    }
  };

  const handleCheckWhetherItemExists = id => {
    const index = nonInvestableNotes.findIndex(plan => plan.id === id);
    if (index !== -1) {
      return nonInvestableNotes[index]['nonInvestableNote'];
    }
  };
  const handleUpdateNonInvestableNote = (e, id) => {
    const { name, value } = e.target;
    let payload = { id };
    payload[name] = value;

    const index = nonInvestableNotes.findIndex(plan => plan.id === id);
    const updatedNonInvestableNotes = [...nonInvestableNotes];

    updatedNonInvestableNotes[index] = {
      ...updatedNonInvestableNotes[index],
      [e.target.name]: e.target.value,
    };

    setNonInvestableNotes(updatedNonInvestableNotes);

    return pushplanTobeChanged(name, payload);
  };

  const handleChangeInvestorProfile = (e, planId, investorProfileId) => {
    const { value, name } = e.target;
    let newArray;
    let valueIndex;
    if (name === 'conservative') {
      valueIndex = conservativeProfile.findIndex(x => x.planId === planId);
      if (valueIndex !== -1) {
        newArray = [...conservativeProfile];
        newArray[valueIndex].percentage = Number(value);
        setConservativeProfile(newArray);
        handleProfilesValues('conservative', newArray);
      }
    } else if (name === 'balanced') {
      valueIndex = balancedProfile.findIndex(x => x.planId === planId);
      if (valueIndex !== -1) {
        newArray = [...balancedProfile];
        newArray[valueIndex].percentage = Number(value);
        setBalancedProfile(newArray);
        handleProfilesValues('balanced', newArray);
      }
    } else {
      valueIndex = aggressiveProfile.findIndex(x => x.planId === planId);
      if (valueIndex !== -1) {
        newArray = [...aggressiveProfile];
        newArray[valueIndex].percentage = Number(value);
        setAggressiveProfile(newArray);
        handleProfilesValues('aggressive', newArray);
      }
    }
  };

  const handleProfilesValues = (investmentProfile, obj) => {
    const data = obj.filter(function(element) {
      return element.percentage !== undefined && element.percentage > 0;
    });
    switch (investmentProfile) {
      case 'conservative':
        conservativeProfileValues.length = 0;
        return setConservativeProfileValues(data);
      case 'balanced':
        balancedProfileValues.length = 0;
        return setBalancedProfileValues(data);
      default:
        aggressiveProfileValues.length = 0;
        return setAggressiveProfileValues(data);
    }
  };

  const fetchCountries = async () => {
    try {
      const res = await getAllCountries(selectedCountry);
      const data = res.data.data.map(country => ({
        id: country.id,
        name: country.name,
        value: country.code,
      }));
      setCountries(data);
    } catch (error) {
      console.log(error);
      setErrorMessage(error.data.message);
    }
  };

  const setCountryAndFetch = e => {
    setSelectedCountry(e);
    getPlansWithCountry(e);
  };

  if (selectedCountry) {
    console.log(selectedCountry, 'my selected country');
  }

  useEffect(() => {
    fetchCountries();
    getPlans();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <div>
          <div className="card card-custom gutter-b">
            <div
              className="card-header"
              style={{
                padding: '2rem',
              }}
            >
              <div
                style={{
                  height: '20px',
                  marginTop: 'auto',
                  marginBottom: '3rem',
                }}
              >
                <h1>Fund configurations</h1>
              </div>
              <div
                style={{
                  height: '20px',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                }}
              >
                <form
                  className="d-flex flex-wrap"
                  style={{
                    // marginTop: '1.5rem',
                    marginBottom: '1.5rem',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  {countries && (
                    <SelectBox
                      options={countries}
                      name="Select country"
                      setValue={e => setCountryAndFetch(e)}
                      defaultValue={selectedCountry}
                    />
                  )}
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap my-5 mx-10"></div>
            <div className="card-body mt-3 mb-15 pt-0 pb-3">
              <div className="tab-content">
                <div className="table-responsive">
                  <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                    <thead>
                      <tr className="text-uppercase text-center">
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Fund ID</span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Cool Name</span>
                        </th>

                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Identifier</span>
                        </th>

                        {grantPermission(
                          AdminPermissions.FUND_CONFIGURATION,
                        ) ? (
                          <>
                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Discountable</span>
                            </th>
                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Viewable</span>
                            </th>

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Investable</span>
                            </th>

                            <th style={{ minWidth: '170px' }}>
                              <span className="text-dark-75">
                                Non investable note
                              </span>
                            </th>

                            <th style={{ minWidth: '120px' }}>
                              <span className="text-dark-75">Hot fund</span>
                            </th>
                          </>
                        ) : null}

                        {grantPermission(
                          AdminPermissions.FUND_CONFIGURATION,
                        ) ? (
                          <>
                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Conservative{' '}
                                <a
                                  onClick={() =>
                                    setConservativeEditable(
                                      !conservativeEditable,
                                    )
                                  }
                                >
                                  <i
                                    className={
                                      conservativeEditable
                                        ? 'fa fa-edit text-success'
                                        : 'fa fa-times text-danger'
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>{' '}
                                {!conservativeEditable ? (
                                  <a
                                    onClick={() =>
                                      handleSubmitPercentageAllocation(
                                        'conservative',
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-save text-success"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                ) : (
                                  ''
                                )}
                              </span>
                            </th>

                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Balanced{' '}
                                <a
                                  href="#"
                                  onClick={() =>
                                    setBalancedEditable(!balancedEditable)
                                  }
                                >
                                  <i
                                    className={
                                      balancedEditable
                                        ? 'fa fa-edit text-success'
                                        : 'fa fa-times text-danger'
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>{' '}
                                {!balancedEditable ? (
                                  <a
                                    onClick={() =>
                                      handleSubmitPercentageAllocation(
                                        'balanced',
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-save text-success"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                ) : (
                                  ''
                                )}
                              </span>
                            </th>

                            <th style={{ minWidth: '200px' }}>
                              <span className="text-dark-75">
                                Aggressive{' '}
                                <a
                                  href="#"
                                  onClick={() =>
                                    setAggressiveEditable(!aggressiveEditable)
                                  }
                                >
                                  <i
                                    className={
                                      aggressiveEditable
                                        ? 'fa fa-edit text-success'
                                        : 'fa fa-times text-danger'
                                    }
                                    aria-hidden="true"
                                  ></i>
                                </a>{' '}
                                {!aggressiveEditable ? (
                                  <a
                                    onClick={() =>
                                      handleSubmitPercentageAllocation(
                                        'aggressive',
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-save text-success"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                ) : (
                                  ''
                                )}
                              </span>
                            </th>
                          </>
                        ) : (
                          ''
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {plans &&
                        plans.map(
                          eachPlan =>
                            eachPlan?.plans &&
                            eachPlan?.plans.map((onePlan, index) => (
                              <AllPlan
                                handleChange={handleChange}
                                handleChangeInvestorProfile={
                                  handleChangeInvestorProfile
                                }
                                planIdsInvestable={planIdsInvestable}
                                planIdsViewable={planIdsViewable}
                                plansIdsHotFund={plansIdsHotFund}
                                key={onePlan.id}
                                planInfo={onePlan}
                                conservativeProfile={conservativeProfile}
                                balancedProfile={balancedProfile}
                                aggressiveProfile={aggressiveProfile}
                                conservativeEditable={conservativeEditable}
                                balancedEditable={balancedEditable}
                                aggressiveEditable={aggressiveEditable}
                                handleUpdateNonInvestableNote={
                                  handleUpdateNonInvestableNote
                                }
                                planIdsDiscountable={planIdsDiscountable}
                              />
                            )),
                        )}
                    </tbody>
                  </table>
                </div>
              </div>

              {grantPermission(AdminPermissions.FUND_CONFIGURATION) ? (
                <div className="text-right mt-8">
                  <button
                    className="btn btn-light-primary font-weight-bold"
                    onClick={() => handleSubmit()}
                    disabled={
                      (planTobeChanged && planTobeChanged.length > 0) ||
                      (investmentProfiles && investmentProfiles.length > 0)
                        ? false
                        : true
                    }
                  >
                    Save
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlanConfiguration;
