import React from 'react';

const OpenFile = ({
  file,
  handleOpeningFile,
  text,
  loading,
  clickedButton,
}) => {
  return (
    <div className="mt-5">
      <div
        className="btn btn-light-primary font-weight-bold"
        onClick={e =>
          file ? handleOpeningFile(file, clickedButton) : e.preventDefault()
        }
      >
        {text}
      </div>
      {loading ? (
        <i
          style={{ color: '#0071CE' }}
          className="fa fa-spinner fa-pulse fa-lg fa-fw ml-3"
        />
      ) : null}
    </div>
  );
};

export default OpenFile;
