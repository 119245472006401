import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import Swal from 'sweetalert2';

import { currencyHelper, getEmail, getNames } from '../../../helpers/helper';
import {
  TransactionStatus,
  ArchivedRefunded,
  AdminPermissions,
} from '../../../helpers/constants';
import {
  changeRefundStatus,
  changeTrnxnStatusToReconciled,
} from '../_redux/archivedTransactionsCrud';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';

function ArchivedTransaction({
  getTransactionsArchived,
  createdAt,
  originalAmount,
  originalCurrency,
  id: transactionId,
  operationType,
  transactionStatus,
  transactionType,
  convertedFinalAmount,
  finalCurrency,
  conversion,
  conversionRate,
  ndovuFee,
  ndovuFeePercentage,
  appliedConversionRate,
  paymentRecord,
  refunded,
  archivedReason,
  dateValues,
  planSubscription,
  triggerSource,
}) {
  const handleChangeRefunded = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to REFUNDED ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await changeRefundStatus(id);
        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getTransactionsArchived(dateValues);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const handleToReconciledStatus = async id => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to RECONCILED ?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await changeTrnxnStatusToReconciled(id);
        swalWithBootstrapButtons
          .fire('Created!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              getTransactionsArchived(dateValues);
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const displayStatusDropdown = transactionStatus => {
    if (refunded) {
      return <span>{transactionStatus}</span>;
    } else {
      return (
        <select
          className="btn btn-light-primary font-weight-bold mb-5"
          style={{
            paddingTop: '10px',
            paddingBottom: '10px',
            background: '#efcd8c',
            color: 'white',
          }}
          onChange={() => handleToReconciledStatus(transactionId)}
          value={transactionStatus}
        >
          <option value={transactionStatus}>{transactionStatus}</option>
          <option value={TransactionStatus.RECONCILED}>
            {TransactionStatus.RECONCILED}
          </option>
        </select>
      );
    }
  };

  const displayRefundedDropdown = refundedStatus => {
    return (
      <select
        className={'btn btn-light-primary font-weight-bold mb-5'}
        style={{
          paddingTop: '10px',
          paddingBottom: '10px',
          background: 'rgb(130 193 255)',
          color: 'white',
        }}
        onChange={() => handleChangeRefunded(transactionId)}
        value={refundedStatus}
      >
        <option value={refundedStatus}>
          {ArchivedRefunded[`${refundedStatus}`]}
        </option>
        <option value={refundedStatus}>
          {ArchivedRefunded[`${!refundedStatus}`]}
        </option>
      </select>
    );
  };

  const paymentChannel = paymentRecord?.paymentLinkMetadata?.paymentChannel;
  const {
    name,
    coolName,
    fundManager: { fundname },
  } = planSubscription?.plan;
  const {
    id: investorId,

    kycStatus,
    causal,
  } = planSubscription.investor;
  return (
    <tr
      className={
        operationType === 'DEPOSIT'
          ? 'archived_deposit_background-color row-border-bottom'
          : 'archived_withdraw_background-color row-border-bottom'
      }
    >
      <td className="text-center sticky-col first-col">
        <span className="text-color d-block font-size-sm">
          {getNames(planSubscription.investor)}
        </span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="text-color text-hover-primary mb-1 font-size-lg">
          {transactionId}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-lg">
          {transactionType}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{operationType}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{triggerSource}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD')}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{investorId}</span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {causal ? causal : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {getEmail(planSubscription.investor)}
        </span>
      </td>
      <td className="text-center">
        {kycStatus === 'PENDING' ? (
          <Link
            to={`/investor-profile/${investorId}`}
            className="text-color d-block font-size-sm"
          >
            {kycStatus}
          </Link>
        ) : (
          <span className="text-color d-block font-size-sm">{kycStatus}</span>
        )}
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">{fundname}</span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">{name}</span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">{coolName}</span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {paymentChannel ? paymentChannel : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {currencyHelper(originalAmount, originalCurrency)}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {paymentRecord?.discountedAmount
            ? currencyHelper(
                paymentRecord.discountedAmount,
                paymentRecord.currency,
              )
            : '--'}
        </span>
      </td>

      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {currencyHelper(convertedFinalAmount, finalCurrency)}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">{conversion}</span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {conversionRate ? Number(conversionRate).toFixed(3) : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {Number(ndovuFee)?.toFixed(3)}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {`${Number(ndovuFeePercentage)?.toFixed(2)}%`}
        </span>
      </td>
      <td className="text-center">
        {appliedConversionRate
          ? Number(appliedConversionRate).toFixed(3)
          : '---'}
      </td>
      <td className="text-center">
        {archivedReason && <span>{archivedReason.split('_').join(' ')}</span>}
      </td>
      {grantPermission(AdminPermissions.TRANSACTION_REFUND_CHANGE) ? (
        <td className="text-center text-color">
          {refunded ? <span>REFUNDED</span> : displayRefundedDropdown(refunded)}
        </td>
      ) : (
        ''
      )}
      {grantPermission(AdminPermissions.TRANSACTION_STATUS_CHANGE) ? (
        <td className="text-center text-color">
          {displayStatusDropdown(transactionStatus)}
        </td>
      ) : (
        ''
      )}
    </tr>
  );
}

export default ArchivedTransaction;
