/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
const CreateAdmin = props => {
  const { names } = JSON.parse(localStorage.getItem('profile'));
  return (
    <>
      <div className="d-flex justify-content-center">
        <h1>Welcome {names}!</h1>
      </div>
    </>
  );
};

export default CreateAdmin;
