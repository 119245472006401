import { Link } from 'react-router-dom';
import moment from 'moment';
import React from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions, AdminStatus } from '../../../helpers/constants';

const Admin = ({ createdAt, email, names, id, status, handleActivation }) => {
  return (
    <tr>
      <td className="pl-0 py-8">
        <span className="text-dark-75  d-block font-size-lg">{names}</span>
      </td>
      <td>
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {email}
        </div>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">{status}</span>
      </td>
      <td>
        <span className="text-dark-75  d-block font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD')}
        </span>
      </td>
      {grantPermission(AdminPermissions.SUPER_ADMIN) ? (
        <>
          <td className="pr-0">
            <Link
              to={`/admins/permissions/${id}`}
              className="btn btn-light-primary  font-size-sm"
            >
              View Permissions
            </Link>
          </td>
          <td className="pr-0">
            {status === AdminStatus.ACTIVE ? (
              <button
                className="btn btn-light-danger font-size-sm"
                onClick={() =>
                  handleActivation(
                    id,
                    AdminStatus.INACTIVE,
                    'Deactivate',
                    names,
                  )
                }
              >
                Deactivate
              </button>
            ) : (
              <button
                className="btn btn-light-success font-size-sm"
                onClick={() =>
                  handleActivation(id, AdminStatus.ACTIVE, 'Activate', names)
                }
              >
                Activate
              </button>
            )}
          </td>
        </>
      ) : (
        ''
      )}
    </tr>
  );
};

export default Admin;
