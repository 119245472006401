import moment from 'moment';
import React, { useState } from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import { AdminPermissions, InvestorTypes } from '../../../helpers/constants';
import { Link, useLocation } from 'react-router-dom';
import UserListModal from './users-modal';
const SingleInvestor = ({ investor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <tr
      style={{
        textAlign: 'center',
      }}
    >
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor?.id}
        </div>
      </td>

      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.investorType}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.entityName ? investor.entityName : '---'}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.email ? investor.email : '---'}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.investorPhone ? investor.investorPhone : '---'}
        </div>
      </td>

      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.investorType === InvestorTypes.INDIVIDUAL ? (
            <Link
              to={{
                pathname: `users/${investor?.accountOwner?.id}`,
                state: { prevUrl: location.pathname },
              }}
            >
              {' '}
              {investor?.accountOwner?.id}
            </Link>
          ) : (
            <p
              onClick={openModal}
              style={{
                color: '#3699FF',
                cursor: 'pointer',
                backgroundColor: 'transparent',
              }}
            >
              View users
            </p>
          )}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(investor.createdAt).format('YYYY-MM-DD')}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.kycSubmittedAt !== null
            ? moment(investor.kycSubmittedAt).format('YYYY-MM-DD')
            : '---'}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.kycEvaluatedAt !== null
            ? moment(investor.kycEvaluatedAt).format('YYYY-MM-DD')
            : '---'}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.investorSubscriptionPlan}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.status ? investor.status : '---'}
        </div>
      </td>
      <td className="pl-0 py-8">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor.partner}
        </div>
      </td>
      {grantPermission(AdminPermissions.USER_VIEW) ? (
        <td className="pr-0">
          <Link
            to={{
              pathname: `/investor-profile/${investor.id}`,
              state: { prevUrl: location.pathname },
            }}
            className="btn btn-light-primary  font-size-sm"
          >
            View Investor
          </Link>
        </td>
      ) : (
        ''
      )}
      {isModalOpen && (
        <UserListModal
          users={investor?.users}
          open={isModalOpen}
          onClose={closeModal}
        />
      )}
    </tr>
  );
};

export default SingleInvestor;
