import moment from 'moment';
import React from 'react';
import { actionNames } from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';

const CorporateActionTable = ({
  createdAt,
  action,
  plan,
  initiatedBy,
  factor,
  originalUnits,
  finalUnits,
  originalUnitCost,
  targetUnitCost,
}) => {
  return (
    <tr>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">{plan ? plan?.id : '---'}</span>
      </td>

      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {plan ? plan?.coolName : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {action ? actionNames[action] : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {factor ? factor : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD, HH:mm')}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {initiatedBy ? initiatedBy?.names : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {formatNumber(originalUnits.toFixed(4))}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {formatNumber(finalUnits.toFixed(4))}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {originalUnitCost
            ? formatNumber(originalUnitCost?.toFixed(4))
            : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {targetUnitCost ? formatNumber(targetUnitCost?.toFixed(4)) : '---'}
        </div>
      </td>
    </tr>
  );
};

export default CorporateActionTable;
