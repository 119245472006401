import React from 'react';

const HighlightedHeader = ({ name }) => {
  return (
    <div
      style={{
        color: '#0071CE',
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <h4
        style={{
          fontFamily: 'Inter',
          fontSize: '19px',
          fontWeight: '400',
          lineHeight: '23px',
          letterSpacing: '0em',
          textAlign: 'left',
        }}
      >
        {name}
      </h4>
      <div
        style={{
          background: '#0071CE',
          height: '2px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        className="separator separator-solid"
      />
    </div>
  );
};

export default HighlightedHeader;
