import React from 'react';

const UnableViewPassword = ({ handleClickEvent, viewPassword }) => {
  return (
    <div onClick={handleClickEvent} className="mb-5 password-eye-icon">
      {viewPassword ? (
        <i className="fa fa-eye" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-eye-slash" aria-hidden="true"></i>
      )}
    </div>
  );
};

export default UnableViewPassword;
