import React from 'react';
import { Link } from 'react-router-dom';
import { AdminPermissions } from '../../../helpers/constants';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';

function AllPartnerInfo({ partnerInfo }) {
  return (
    <tr>
      <td className="pl-0 py-8 text-center">
        <span className="text-color text-hover-primary mb-1 font-size-lg">
          {partnerInfo?.name ? partnerInfo?.name : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {partnerInfo?.investmentApproach
            ? partnerInfo?.investmentApproach
            : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {partnerInfo?.partnerAccountType
            ? partnerInfo?.partnerAccountType
            : '---'}
        </span>
      </td>
      <td className="text-center">
        <span className="text-color d-block font-size-sm">
          {partnerInfo?.kycPolicy ? partnerInfo?.kycPolicy : '---'}
        </span>
      </td>
      {grantPermission(AdminPermissions.BLOCK_VIEW) ? (
        <td className="text-center">
          <Link
            to={`/blocks/partner/${partnerInfo.id}`}
            className="btn btn-light-primary  font-size-sm"
          >
            Blocks
          </Link>
        </td>
      ) : (
        ''
      )}
      {grantPermission(AdminPermissions.TRANSACTION_VIEW) ? (
        <td className="text-center p-0">
          <Link
            to={`/unassigned-transactions/partner/${partnerInfo.id}`}
            className="btn btn-light-primary  font-size-sm"
            onClick={() => {}}
          >
            Unassigned Transactions
          </Link>
        </td>
      ) : (
        ''
      )}
    </tr>
  );
}

export default AllPartnerInfo;
