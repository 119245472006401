import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '35%',
    height: '40vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const ChecksModal = ({
  open,
  handleClose,
  alerts,
  handleSubmit,
  selectOptions,
  selectedOptions,
  modalTitle,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 className="font-weight-bolder mt-10 text-center">{modalTitle}</h2>
        <div style={{ width: '90%' }} className="mx-auto py-10">
          {alerts?.map(item => (
            <div key={item}>
              <input
                type="checkbox"
                name="investable"
                onChange={() => selectOptions(item)}
                checked={selectedOptions?.includes(item)}
                className="form-check-input"
                id={item}
              />
              <label
                className="form-check-label font-weight-bold"
                htmlFor={item}
              >
                {item}
              </label>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center flex-wrap">
          <button
            className="btn btn-light-success"
            style={{
              height: '40px',
              border: 'none',
              marginTop: 0,
              marginLeft: '10px',
              borderRadius: '5px',
            }}
            onClick={handleSubmit}
            disabled={selectedOptions.length !== alerts.length}
          >
            Yes, set it!
          </button>
          <button
            type="button"
            style={{
              border: 'none',
              height: '40px',
              marginTop: 0,
              marginLeft: '10px',
              borderRadius: '5px',
            }}
            onClick={handleClose}
            className="btn btn-light-danger "
          >
            No, Cancel!
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ChecksModal;
