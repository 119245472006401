import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const INVESTORS_URL = `${REACT_APP_BASE_URL}/v1/admin/investors`;
const FETCH_INVESTOR_HISTORY = `${REACT_APP_BASE_URL}/v1/kyc-audit/`;
const USERS_URL = `${REACT_APP_BASE_URL}/v1/admin/users`;
const BUSINESS_CHAMA_URL = `${REACT_APP_BASE_URL}/v1/investor/business-chama/admin`;

const fetchUsers = async (page, limit) => {
  let res = '';
  if (page && limit) {
    res = await axios.get(`${USERS_URL}?page=${page}&limit=${limit}`);
  } else {
    res = await axios.get(`${USERS_URL}`);
  }
  return res;
};

const fetchInvestor = async investorId => {
  const res = await axios.get(`${INVESTORS_URL}/${investorId}/profile`);
  return res;
};

const fetchUser = async investorId => {
  const res = await axios.get(`${USERS_URL}/${investorId}/`);
  return res;
};

const updateInvestorInfo = async (investorId, investorData) => {
  let fullUrl = `${INVESTORS_URL}/${investorId}/update-user-data`;
  const res = await axios.put(`${fullUrl}`, investorData);
  return res;
};
const updateBusinessChamaInvestorInfo = async (investorId, investorData) => {
  let fullUrl = `${BUSINESS_CHAMA_URL}/${investorId}`;
  const res = await axios.put(`${fullUrl}`, investorData);
  return res;
};

const updateUserData = async (userId, data) => {
  const res = await axios.put(`${USERS_URL}/${userId}/`, data);
  return res;
};
const searchForUsersApi = async (
  userStatus,
  dateColumnName,
  startDate,
  endDate,
  search,
  page,
  limit,
) => {
  let URL = USERS_URL + '?';
  let queryParams = [
    'userStatus',
    'dateColumnName',
    'startDate',
    'endDate',
    'search',
    'page',
    'limit',
  ];
  let functionParams = [
    userStatus,
    dateColumnName,
    startDate,
    endDate,
    search,
    page,
    limit,
  ];

  for (let i = 0; i < functionParams.length; i++) {
    if (functionParams[i]) {
      URL = URL + '&' + queryParams[i] + '=' + functionParams[i];
    }
  }

  const res = await axios.get(URL);
  return res;
};
const fetchInvestorTransaction = async (
  investorId,
  investmentType,
  page,
  limit,
) => {
  let res = '';
  if (page && limit) {
    res = await axios.get(
      `${INVESTORS_URL}/${investorId}/transactions/${investmentType}?page=${page}&limit=${limit}`,
    );
  } else {
    res = await axios.get(
      `${INVESTORS_URL}/${investorId}/transactions/${investmentType}`,
    );
  }
  return res;
};

const downloadUsersFile = async imageURL => {
  return axios.get(imageURL, { responseType: 'blob' });
};

const getImageURL = async imageURL => {
  const res = await axios.get(imageURL, { responseType: 'blob' });

  return res;
};

export {
  fetchInvestor,
  updateInvestorInfo,
  fetchInvestorTransaction,
  downloadUsersFile,
  getImageURL,
  fetchUsers,
  fetchUser,
  searchForUsersApi,
  updateBusinessChamaInvestorInfo,
  updateUserData,
};
