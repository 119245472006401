import { checkToken } from '../../../../app/helpers/helper';

export const grantPermission = requestedRoles => {
  const auth = checkToken();
  if (auth) {
    const { permissions } = JSON.parse(localStorage.getItem('profile'));
    const permissionAssigned = element => element === requestedRoles;
    return !!permissions.some(permissionAssigned);
  }
  return true;
};
