import moment from 'moment';
import React, { useState } from 'react';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
import {
  AdminPermissions,
  settlementDetailActions,
  settlementDetailActionsMap,
  settlementDetailTypes,
} from '../../../helpers/constants';
import SelectBox from '../../../common/SelectBox';
import SettlementApproval from './SettlementApprovalModal';
import { getNames } from '../../../helpers/helper';

const SettlementDetail = ({
  createdAt,
  approvalStatus,
  id,
  type,
  accountNumber,
  phoneNumber,
  getSettlementDetails,
  bankName,
  investor,
  currency,
}) => {
  const [open, setOpen] = useState(false);
  const [chosenStatus, setChosenStatus] = useState('');
  const setValue = async (val, id) => {
    setChosenStatus(val);
    setOpen(true);
  };

  return (
    <tr>
      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {investor?.id ? investor?.id : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD')}
        </div>
      </td>
      <td className="pl-0 py-8 text-center">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {id}
        </div>
      </td>

      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">{getNames(investor)}</span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">{type}</span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm" style={{ width: '200px' }}>
          {bankName ? bankName : '---'}
        </span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">
          {type === settlementDetailTypes.BANK ? accountNumber : phoneNumber}
        </span>
      </td>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">
          {currency ? currency.currency : '---'}
        </span>
      </td>
      {grantPermission(AdminPermissions.MANIPULATE_SETTLEMENT_DETAILS) && (
        <td className="pr-0">
          <SelectBox
            options={settlementDetailActions}
            color={
              approvalStatus !== settlementDetailActionsMap.PENDING
                ? 'grey'
                : '#2D9A2D'
            }
            width="100%"
            defaultValue={approvalStatus}
            setValue={setValue}
          />
        </td>
      )}

      <SettlementApproval
        open={open}
        setModalStatus={setOpen}
        status={chosenStatus}
        id={id}
        getSettlementDetails={getSettlementDetails}
      />
    </tr>
  );
};

export default SettlementDetail;
