import React from 'react';

function SelectBox({
  name,
  options,
  setValue = null,
  type = null,
  defaultValue = null,
  disabled = false,
  styles,
}) {
  const defaultStyle = {
    border: '1px solid #0071CE',
    color: '#0071CE',
    background: '#FFFFFF',
    maxWidth: '190px',
    height: '45px',
    borderRadius: '10px',
    padding: '20px, 148px, 20px, 135px',
    fontWeight: 500,
  };
  return (
    <select
      className="btn btn-light-primary"
      onChange={e =>
        setValue(
          e.target.value.toUpperCase(),
          type,
          e.target.options[e.target.selectedIndex].getAttribute('data-id'),
        )
      }
      defaultValue={defaultValue}
      disabled={disabled}
      style={styles ? styles : defaultStyle}
    >
      {name && <option value=""> {name}</option>}

      {options.map(option => (
        <option
          key={option.value}
          data-id={option.value}
          value={option.value || option.name}
        >
          {option.name || option.value}
        </option>
      ))}
    </select>
  );
}

export default SelectBox;
