import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { updatePocketTransactionStatus } from '../_redux/pocketsCrud';

function getModalStyle() {
  const top = 49;
  const left = 44;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));
function ConfirmationModal({
  open,
  setModalStatus,
  status,
  id,
  getTransaction,
  getPartners,
}) {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const handleClose = async (e, shouldUpdate = null) => {
    e.preventDefault();
    setModalStatus(false); // Close the modal when called

    if (shouldUpdate) {
      await updatePocketTransactionStatus(id, status);
      getTransaction();
      getPartners();
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setModalStatus(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section style={modalStyle} className={classes.paper}>
        <h4
          style={{
            marginBottom: '2rem',
          }}
          className="text-dark-75  text-hover-primary mb-1 font-size-lg"
        >
          Are you sure that you want to change the status of this transaction to{' '}
          {status}
        </h4>
        <List
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            width: '100%',
            margin: 'auto',
          }}
        >
          <button
            type="button"
            style={{
              width: '40%',
              height: '45px',
              borderRadius: '10px',
              padding: '20px, 148px, 20px, 135px',
              border: '1px solid green',
              color: '#FFFFFF',
              background: 'green',
              marginRight: '1rem',
            }}
            onClick={e => handleClose(e, true)}
          >
            Yes
          </button>

          <button
            type="button"
            style={{
              width: '40%',
              height: '45px',
              borderRadius: '10px',
              padding: '20px, 148px, 20px, 135px',
              border: '1px solid red',
              color: '#FFFFFF',
              background: 'red',
            }}
            onClick={e => handleClose(e)}
          >
            NO
          </button>
        </List>
      </section>
    </Modal>
  );
}

export default ConfirmationModal;
