import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Alert from '../../../common/Alert';
import Loader from '../../../common/Loader';
import SelectBox from '../../../common/SelectBox';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { hasOnlyOneDot } from '../../../helpers/removeNonNumberic';
import BackIcon from '../../../icons/backIcon.svg';
import { fetchAllCurrencies } from '../../pockets/_redux/pocketsCrud';
import { adminAddInvestorVoucher } from '../_redux';

const AddVoucher = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [allCurrencies, setAllCurrencies] = useState('');
  const { investorId } = useParams();

  const fetchCurrencies = async () => {
    try {
      const res = await fetchAllCurrencies();
      if (res.status === 200) {
        setAllCurrencies(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const initialValues = {
    expirationDate: '',
    amount: '',
    currency: '',
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required('Amount required'),
    currency: Yup.string().required('Currency Required'),
    expirationDate: Yup.date().required('Date required'),
  });

  const handleAlert = (message, iconType) => {
    Swal.fire({
      icon: iconType,
      position: 'top-end',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
      text: message,
      showConfirmButton: false,
      timer: 1800,
    });
  };

  const handleInputChange = e => {
    const inputValue = e.target.value;
    if (isNaN(convertAmountToNumber(inputValue))) {
      return;
    }
    let value = e.target.value.replace(/,/g, '');

    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const hasOneDot = hasOnlyOneDot(value);
    if (!hasOneDot) return false;

    formik.setFieldValue('amount', value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-light-success',
          cancelButton: 'btn btn-light-danger mr-1',
        },
        buttonsStyling: false,
      });
      const result = await swalWithBootstrapButtons.fire({
        title: `Are you sure you want to add voucher to investor with email ${location?.state?.email} for amount ${values.currency} ${values.amount} with expiration date of ${values.expirationDate}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, create it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      });
      if (result.isConfirmed) {
        try {
          if (!values) setSubmitting(true);

          try {
            setLoading(true);
            const res = await adminAddInvestorVoucher({
              ...values,
              expirationDate: values.expirationDate,
              amount: convertAmountToNumber(values.amount),
              investorId: Number(investorId),
            });
            if (res.status === 201) {
              setLoading(false);
              handleAlert('Voucher created successfully', 'success');
              formik.resetForm();
              history.push(location?.state?.prevUrl);
            }
          } catch (error) {
            setLoading(false);
            handleAlert(error.response.data.message, 'error');
          }
        } catch (error) {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            error.response.data.message,
            'error',
          );
        }
      } else {
        result.dismiss = Swal.DismissReason.cancel;
      }
    },
  });
  const onBackClick = () => {
    return location.state?.prevUrl
      ? history.push(location.state?.prevUrl)
      : history.push('/investors');
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="cursor-pointer"
            style={{
              marginBottom: '1rem',
            }}
            onClick={() => onBackClick()}
          >
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </div>

          <div className="card card-custom gutter-b">
            <div className="card-header d-flex flex-row">
              <h6 className="pt-8">
                {' '}
                Add voucher for investor : {location?.state?.email}
              </h6>
            </div>

            <div className="card-body">
              <div className="mb-5">
                {alert?.alertMessage ? (
                  <Alert alert={alert} alignText={'text-left'} />
                ) : null}
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="d-flex flex-wrap add-voucher-form"
              >
                <section className="add-voucher-form-section">
                  <label
                    className="add-voucher-form-label"
                    htmlFor={investorId}
                  >
                    Expiration Date
                  </label>
                  <div className="relative">
                    <input
                      type="date"
                      id={investorId}
                      name="expirationDate"
                      min={moment()
                        .add(1, 'day')
                        .format('YYYY-MM-DD')}
                      className="add-voucher-form-input"
                      {...formik.getFieldProps('expirationDate')}
                    />
                    {formik.touched.expirationDate &&
                    formik.errors.expirationDate ? (
                      <div
                        className="font-weight-bold font-size-sm d-flex "
                        style={{
                          color: '#F05B7F',
                          width: '260px',
                        }}
                      >
                        {formik.errors.expirationDate}
                      </div>
                    ) : null}
                  </div>
                </section>

                <section className="add-voucher-form-section">
                  <label
                    className="add-voucher-form-label"
                    htmlFor={investorId}
                  >
                    Amount
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      value={formik.values.amount}
                      id={investorId}
                      name="amount"
                      className="add-voucher-form-input"
                      onChange={handleInputChange}
                    />
                    {formik.touched.amount && formik.errors.amount ? (
                      <div
                        className="font-weight-bold font-size-sm d-flex "
                        style={{
                          color: '#F05B7F',
                          width: '260px',
                        }}
                      >
                        {formik.errors.amount}
                      </div>
                    ) : null}
                  </div>
                </section>

                {allCurrencies && (
                  <SelectBox
                    options={allCurrencies}
                    name="Select Currency"
                    setValue={value => {
                      formik.setFieldValue('currency', value);
                    }}
                    defaultValue={formik.values.currency}
                    styles={{
                      width: '45%',
                      height: '45.46px',
                      marginTop: '1.5rem',
                      marginBottom: '1.5rem',
                    }}
                  />
                )}

                <div className="add-voucher-button-container font-weight-bold text-center">
                  <button
                    type="submit"
                    className="add-voucher-button  text-white  font-weight-bold px-15 py-4"
                    disabled={loading}
                  >
                    Save
                    {loading && <span className="ml-3 spinner spinner-white" />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddVoucher;
