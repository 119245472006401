import React from 'react';
import { useHistory } from 'react-router-dom';

import CommonButton from '../common/CommonBtn';

const ErrorCard = ({ errorMessage, backUrl }) => {
  const history = useHistory();
  return (
    <div className="card card-custom">
      <div className="card-header card-border">
        <div className="card-title">
          <h3 className="card-label" style={{ color: '#0071CE' }}>
            Failed
          </h3>
        </div>
      </div>

      <div className="text-center mt-10">
        <p>Something went wrong!!</p>
        <p style={{ color: '#F05B7F' }}>{errorMessage}</p>
      </div>

      <div className="text-center mt-5 mb-10">
        <CommonButton
          btnText="Close"
          className="text-center full-width-small btn-size common-btn"
          onClick={() =>
            backUrl
              ? history.push(backUrl)
              : history.push('/unassigned-transactions')
          }
        />
      </div>
    </div>
  );
};

export default ErrorCard;
