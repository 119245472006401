import { List, Modal, makeStyles } from '@material-ui/core';

import React, { useState } from 'react';

function getModalStyle() {
  const top = 49;
  const left = 44;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    // width: '50%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));
const UserListModal = ({ users, onClose, open }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  console.log(users);

  const tableHeaders = [
    {
      id: 1,
      title: 'User ID',
    },
    {
      id: 2,
      title: 'Full Name',
    },
    {
      id: 3,
      title: 'User Email',
    },
  ];
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section style={modalStyle} className={classes.paper}>
        <div>
          <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                {tableHeaders.map(header => (
                  <th
                    key={header.id}
                    style={{
                      minWidth: '100px',
                      textAlign: 'center',
                    }}
                    className="pl-7"
                  >
                    <span
                      style={{
                        color: '#3699FF',
                      }}
                    >
                      {header.title}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map(eachUser => (
                <tr key={eachUser?.id}>
                  <td className="pl-0 py-8 text-center">
                    <span className="d-block font-size-sm">{eachUser?.id}</span>
                  </td>
                  <td className="pl-0 py-8 text-center">
                    <span className="d-block font-size-sm">
                      {eachUser?.firstName + ' ' + eachUser?.lastName}
                    </span>
                  </td>
                  <td className="pl-0 py-8 text-center">
                    <span className="d-block font-size-sm">
                      {eachUser?.email}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <List
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            width: '100%',
            margin: 'auto',
            justifyContent: 'center',
          }}
        >
          <button
            type="button"
            style={{
              width: '40%',
              height: '45px',
              borderRadius: '10px',
              padding: '20px, 148px, 20px, 135px',
              border: '1px solid #0071ce',
              color: '#FFFFFF',
              background: '#0071ce',
            }}
            onClick={e => handleClose(e)}
          >
            Close
          </button>
        </List>
      </section>
    </Modal>
  );
};

export default UserListModal;
