import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const LOGIN_URL = `${REACT_APP_BASE_URL}/v1/admin/auth/login`;
const OTP_VERIFICATION_URL = `${REACT_APP_BASE_URL}/v1/admin/auth/verify`;
const login = async (email, password) => {
  const res = axios.post(LOGIN_URL, { email, password });
  return res;
};

const validateOtp = payload => axios.post(OTP_VERIFICATION_URL, payload);

export { login, validateOtp };
