import jwtDecode from 'jwt-decode';
import { InvestorTypes } from './constants';

export const checkToken = () => {
  const token = localStorage.getItem('ndovuAdminToken');
  if (token && token !== null) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now) {
      localStorage.removeItem('ndovuAdminToken');
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const isTokenValidated = () => {
  const isValidated = localStorage.getItem('tokenValidated');
  if (isValidated && isValidated === 'true') {
    return true;
  } else {
    return false;
  }
};

export const currencyHelper = (amount, currency) => {
  const twoDecimalDigit = amount
    ?.toLocaleString('en-US', { maximumFractionDigits: 2 })
    .replace(/\D00$/, '');

  const money = `${twoDecimalDigit} ${currency}`;

  return money;
};

export const getNames = investor => {
  if (investor.investorType === InvestorTypes.INDIVIDUAL) {
    return (
      investor?.accountOwner?.firstName + ' ' + investor?.accountOwner?.lastName
    );
  } else {
    return investor?.businessChamaName;
  }
};

export const getEmail = investor => {
  if (investor.investorType === InvestorTypes.INDIVIDUAL) {
    return investor?.accountOwner?.email;
  } else {
    return investor?.businessChamaEmail;
  }
};

export const formatNumber = number =>
  new Intl.NumberFormat('en-US').format(number);
