import React, { useEffect, useState } from 'react';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import BackIcon from '../../../icons/backIcon.svg';
import { useHistory, useLocation } from 'react-router-dom';

import ErrorCard from '../../../common/ErrorCard';

import { fetchInvestorPortfolioById } from '../../UnassignedTransactions/_redux/transactionsCrud';
import { formatNumber } from '../../../helpers/helper';

const InvestorPortfolio = props => {
  const { investorId } = props.match.params;
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [allTransactions, setAllTransactions] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const history = useHistory();

  const fetchInvestorPortfolio = async () => {
    try {
      setLoading(true);
      const res = await fetchInvestorPortfolioById(investorId);

      setAllTransactions(res.data);
      setLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestorPortfolio();
  }, []);

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  const onBackClick = () => {
    return location.state?.prevUrl
      ? history.push(location.state?.prevUrl)
      : history.push('/dashboard');
  };

  return (
    <>
      {errorMessage ? (
        <ErrorCard backUrl={'/dashboard'} errorMessage={errorMessage} />
      ) : (
        <>
          <div className="cursor-pointer" onClick={() => onBackClick()}>
            <img src={BackIcon} alt={'Back icon'} />
            <span className="ml-2">Back</span>
          </div>
          <div className="card card-custom card-stretch gutter-b mt-2">
            <div className="card-header pt-7">
              <h4>Investor Portfolio</h4>
            </div>
            <div className="card-body">
              <div className="tab-content">
                <div
                  style={{
                    width: '90%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <table className="table table-responsive table-head-custom table-head-bg  table-border table-vertical-center">
                    <thead>
                      <tr className="text-center text-uppercase pl-7">
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Fund ID</span>
                        </th>
                        <th style={{ minWidth: '180px' }}>
                          <span className="text-dark-75">Fund Name</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">Current Value</span>
                        </th>
                        <th style={{ minWidth: '150px' }} className="pl-7">
                          <span className="text-dark-75">Currency</span>
                        </th>
                        <th style={{ minWidth: '150px' }}>
                          <span className="text-dark-75">
                            Profit percentage
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allTransactions &&
                        allTransactions.map(
                          ({
                            fundID,
                            fundName,
                            currency,
                            profit,
                            currentValue,
                          }) => (
                            <tr className="row-border" key={fundID}>
                              <td className="pl-0 py-8 text-center">
                                <div className="text-hover-primary mb-1 font-size-lg">
                                  <span className="font-weight-bold text-color">
                                    {fundID}
                                  </span>
                                </div>
                              </td>

                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {fundName}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {formatNumber(currentValue)}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {currency}
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="d-block font-size-lg text-color">
                                  {profit}
                                </span>
                              </td>
                            </tr>
                          ),
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvestorPortfolio;
