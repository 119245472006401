import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  AskPrice: '[AskPrice] Action',
  BidPrice: '[BidPrice] Action',
};

const initialAuthState = {
  askPrice: 0,
  bidPrice: 0,
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case actionTypes.AskPrice: {
      const { askPrice } = action.payload;
      return { ...state, askPrice };
    }
    case actionTypes.BidPrice: {
      const { bidPrice } = action.payload;
      return { ...state, bidPrice };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  askPrice: askPrice => ({
    type: actionTypes.AskPrice,
    payload: { askPrice },
  }),
  bidPrice: bidPrice => ({
    type: actionTypes.BidPrice,
    payload: { bidPrice },
  }),
};

export function* saga() {}
