import React, { useState } from 'react';
import moment from 'moment';
import { formatNumber, getEmail, getNames } from '../../../helpers/helper';
import {
  AdminPermissions,
  recurringPaymentStatus,
} from '../../../helpers/constants';
import Swal from 'sweetalert2';
import { updateRecurringPaymentStatus } from '../_redux';
import { grantPermission } from '../../../../_metronic/layout/components/content/Permissions';
const RecurringPaymentTable = ({
  id,
  createdAt,
  amount,
  currency,
  status,
  endDate,
  paymentChannel,
  phoneNumber,
  planSubscription,
}) => {
  const unChangedTransactionStatus = ['EXPIRED', 'CANCELLED'];
  const [currentStatus, setCurrentStatus] = useState(status);

  const onHandleStatusChange = async (val, id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: `Are you sure about moving transaction ${id} to ${val} status?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, move it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      try {
        const response = await updateRecurringPaymentStatus(id, val);
        setCurrentStatus(val);
        swalWithBootstrapButtons
          .fire('Status Changed!', response.data.message, 'success')
          .then(res => {
            if (res.isConfirmed) {
              result.dismiss = Swal.DismissReason.cancel;
            }
          });
      } catch (error) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          error.response.data.message,
          'error',
        );
      }
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };
  return (
    <tr>
      <td className="pl-0 py-8 text-center">
        <span className="d-block font-size-sm">{id}</span>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {planSubscription?.investor?.partner?.name}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {getEmail(planSubscription?.investor)}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {getNames(planSubscription?.investor)}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {planSubscription?.plan?.coolName}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {paymentChannel}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {phoneNumber ? phoneNumber : '---'}
        </div>
      </td>
      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {formatNumber(amount)}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {currency.assetName}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(createdAt).format('YYYY-MM-DD')}
        </div>
      </td>

      <td className="pl-0 py-8 text-center ">
        <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
          {moment(endDate).format('YYYY-MM-DD')}
        </div>
      </td>

      {grantPermission(AdminPermissions.MANIPULATE_RECURRING_PAYMENTS) && (
        <td className="pl-0 py-8 text-center ">
          {unChangedTransactionStatus.includes(currentStatus) ? (
            <div className="text-dark-75  text-hover-primary mb-1 font-size-lg">
              {currentStatus}
            </div>
          ) : (
            <select
              className="btn btn-light-primary font-weight-bold"
              onChange={e =>
                onHandleStatusChange(e.target.value.toUpperCase(), id)
              }
              value={currentStatus}
              style={{
                border: `1px solid #2D9A2D`,
                color: 'green',
                background: '#FFFFFF',
                width: '100%',
                height: '45px',
                borderRadius: '10px',
                padding: '20px, 148px, 20px, 135px',
              }}
            >
              {recurringPaymentStatus?.map(option => {
                return (
                  <option
                    data-id={option.id}
                    key={option.id}
                    value={option.value || option.name}
                  >
                    {option.value || option.name}
                  </option>
                );
              })}
            </select>
          )}
        </td>
      )}
    </tr>
  );
};

export default RecurringPaymentTable;
