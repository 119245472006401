import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Alert from '../../../common/Alert';
import Back from '../../../common/Back';
import Loader from '../../../common/Loader';
import { DisplaySuccessModal } from '../../../common/SelectNumberOfRows/SuccessModal';
import { incomeTypes } from '../../../helpers/constants';
import { formatNumber } from '../../../helpers/helper';
import { fetchPlanDetails, payDividends } from '../_redux/planCrud';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { PlanDetailsContainer } from '../../../common/PlanDetailsContainer';

const inputElements = [
  {
    name: 'annualInterestRate',
    label: 'Annual Interest Rate %',
    type: 'number',
    disabled: false,
    placeholder: 'Enter Annual Interest Rate',
  },
  {
    name: 'duration',
    label: 'Duration ( in months)',
    type: 'number',
    disabled: false,
    placeholder: 'Enter Duration',
  },
  {
    name: 'payoutRate',
    label: 'Payout  Rate % ',
    type: 'number',
    disabled: true,
    placeholder: 'Computed by annual rate * duration',
  },
  {
    name: 'ndovuFeePayout',
    label: 'Ndovu Fee Payout %',
    type: 'number',
    disabled: false,
    placeholder: 'Enter Ndovu Fee',
  },
  {
    name: 'netInterestRate',
    label: 'Net Interest Rate %',
    type: 'number',
    disabled: true,
    placeholder: 'Enter Annual Interest Rate',
  },
];
export const PlanDetails = () => {
  //  use this  container  to house and style all dividends content

  const dividendsPaymentValidationSchema = Yup.object().shape({
    annualInterestRate: Yup.string().required('Required field'),
    duration: Yup.string().required('Required field'),
    ndovuFeePayout: Yup.string().required('Required field'),
  });

  // init formik and set the details.
  // this is for  incomeType is INTEREST
  const initialValues = {
    amountPerUnit: null,
    annualInterestRate: null,
    duration: null,
    payoutRate: null,
    ndovuFeePayout: null,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: dividendsPaymentValidationSchema,
    onSubmit: async values => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-light-success',
          cancelButton: 'btn btn-light-danger mr-1',
        },
        buttonsStyling: false,
      });

      const result = await swalWithBootstrapButtons.fire({
        title: `An amount of ${calculateAmountToBePaidInterests(
          planDetails,
        )} is going to be disbursed to the platform users, Are you sure you want to proceed?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        setAlert({
          alertMessage: null,
          alertMessageType: null,
        });

        try {
          setLoading(true);

          const response = await payDividends({
            amountPerUnit: 0,
            annualInterestRate: Number(values.annualInterestRate),
            duration: Number(values.duration),
            ndovuFeePercentage: Number(values.ndovuFeePayout),
            planId: Number(planId),
          });

          DisplaySuccessModal(response?.data?.message);

          setLoading(false);
        } catch (error) {
          setAlert({
            alertMessage: error?.response?.data?.message,
            alertMessageType: 'error',
          });
          setLoading(false);
        }

        setAmountPerUnit();
      } else {
        result.dismiss = Swal.DismissReason.cancel;
      }
    },
  });

  const { planId } = useParams();
  const [amountPerUnit, setAmountPerUnit] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });
  const [loading, setLoading] = useState(false);

  const updatePlanDetailsState = async () => {
    try {
      setLoading(true);
      const response = await fetchPlanDetails(planId);
      const planData = response?.data?.data;
      setPlanDetails(planData);
      setLoading(false);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
      setLoading(false);
    }
  };
  //  have a custom  input change that sets and calculates the static fields

  const handleInputChange = e => {
    const { name, value: inputValue } = e.target;
    //  ensure that the  admin entered a
    if (isNaN(convertAmountToNumber(inputValue))) {
      return;
    }

    let value = e.target.value.replace(/,/g, '');

    if (name === 'duration') {
      if (formik.values.annualInterestRate) {
        const computedPayoutRate =
          formik.values.annualInterestRate * (value / 12);

        formik.setFieldValue('payoutRate', computedPayoutRate.toFixed(4));
      }
      formik.setFieldValue(name, value);

      //  also check whether the annualInterestRate is present and if so,  get the payoutRate
    } else if (name === 'annualInterestRate') {
      //  also check whether the annualInterestRate is present and if so,  get the payoutRate
      formik.setFieldValue(name, value);

      if (formik.values.duration) {
        const computedPayoutRate =
          value * (formik.values.duration / 12).toFixed(12);

        formik.setFieldValue('payoutRate', computedPayoutRate.toFixed(4));
      }
    } else if (name === 'ndovuFeePayout') {
      formik.setFieldValue(name, value);
      if (formik.values.payoutRate) {
        const computedNetInterestRate = formik.values.payoutRate - value;
        formik.setFieldValue(
          'netInterestRate',
          computedNetInterestRate.toFixed(4),
        );
      }
    }
  };

  useEffect(() => {
    updatePlanDetailsState();
  }, []);

  const handleSubmitForIntrest = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-light-success',
        cancelButton: 'btn btn-light-danger mr-1',
      },
      buttonsStyling: false,
    });

    const result = await swalWithBootstrapButtons.fire({
      title: `An amount of ${calculateAmountToBePaid(
        planDetails,
        amountPerUnit,
      )} is going to be disbursed to the platform users, Are you sure you want to proceed?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    });

    if (result.isConfirmed) {
      setAlert({
        alertMessage: null,
        alertMessageType: null,
      });

      const payload = {
        planId: Number(planId),
      };

      if (planDetails?.incomeType === incomeTypes.DIVIDENDS) {
        payload.amountPerUnit = Number(amountPerUnit);
      } else {
        payload.percentageInterestRate = Number(amountPerUnit);
      }

      try {
        setLoading(true);
        const response = await payDividends(payload);

        DisplaySuccessModal(response?.data?.message);

        setLoading(false);
      } catch (error) {
        setAlert({
          alertMessage: error?.response?.data?.message,
          alertMessageType: 'error',
        });
        setLoading(false);
      }

      setAmountPerUnit();
    } else {
      result.dismiss = Swal.DismissReason.cancel;
    }
  };

  const calculateAmountToBePaid = (planDetails, amountPerUnit) => {
    const { incomeType, totalUnitsHeld } = planDetails;

    const amount =
      incomeType !== incomeTypes.DIVIDENDS
        ? totalUnitsHeld * (amountPerUnit / 100)
        : totalUnitsHeld * amountPerUnit;

    return formatNumber(amount.toFixed(4));
  };

  const calculateAmountToBePaidInterests = planDetails => {
    const { totalUnitsHeld } = planDetails;
    const { annualInterestRate, duration, ndovuFeePayout } = formik.values;

    const computedDuration = (duration / 12).toFixed(2);
    const payOutRate = (annualInterestRate * computedDuration).toFixed(4);
    const netInterestRate = ((payOutRate - ndovuFeePayout) / 100).toFixed(4);

    return formatNumber((totalUnitsHeld * netInterestRate).toFixed(2));
  };

  const renderInterestContent = (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <h4 className="pt-8">Fund Details</h4>
        </div>

        <div className="card-body">
          <div className="mb-5">
            {alert?.alertMessage ? (
              <Alert alert={alert} alignText={'text-left'} />
            ) : null}
          </div>

          <section className="form">
            <PlanDetailsContainer>
              <div>
                Fund:{' '}
                <span className="font-weight-bold">
                  {planDetails?.coolName}
                </span>
              </div>

              <div>
                Symbol:{' '}
                <span className="font-weight-bold">
                  {planDetails?.identifier}
                </span>
              </div>

              <div>
                Total investors holding the fund:{' '}
                <span className="font-weight-bold">
                  {planDetails?.totalInvestors}
                </span>
              </div>

              <div>
                Total Amount of units held:{' '}
                <span className="font-weight-bold">
                  {planDetails?.totalUnitsHeld &&
                    formatNumber(planDetails?.totalUnitsHeld.toFixed(4))}
                </span>
              </div>
            </PlanDetailsContainer>

            <form onSubmit={formik.handleSubmit}>
              {inputElements.map((element, index) => (
                <div key={index} className="mt-3 form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                    {element.label}
                  </label>
                  <div>
                    <input
                      disabled={element.disabled}
                      type={element.type}
                      className={`form-control`}
                      name={element.name}
                      placeholder={
                        element.placeholder ? element.placeholder : '---'
                      }
                      value={
                        formik.values[`${element.name}`]
                          ? formik.values[`${element.name}`]
                          : ''
                      }
                      onChange={e => handleInputChange(e)}
                      style={{
                        border: '0.5px solid #CAE2F6',
                        background: '#F5F9FD',
                        height: '48px',
                        width: '260px',
                      }}
                      autoComplete="off"
                    />
                  </div>
                </div>
              ))}
              <div>
                <button
                  className="btn btn-light-primary font-weight-bold"
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Pay
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </>
  );

  const renderDividendPaymentContent = (
    <div className="card card-custom gutter-b">
      <div className="card-header">
        <h4 className="pt-8">Fund Details</h4>
      </div>

      <div className="card-body">
        <div className="mb-5">
          {alert?.alertMessage ? (
            <Alert alert={alert} alignText={'text-left'} />
          ) : null}
        </div>

        <div>
          <div>
            Fund:{' '}
            <span className="font-weight-bold">{planDetails?.coolName}</span>
          </div>

          <div>
            Symbol:{' '}
            <span className="font-weight-bold">{planDetails?.identifier}</span>
          </div>

          <div>
            Total Amount of units held:{' '}
            <span className="font-weight-bold">
              {planDetails?.totalUnitsHeld &&
                formatNumber(planDetails?.totalUnitsHeld.toFixed(4))}
            </span>
          </div>

          <div>
            Dividend percentage fee:{' '}
            <span className="font-weight-bold">
              {planDetails?.dividendPercentageFee
                ? planDetails?.dividendPercentageFee
                : '--'}
            </span>
          </div>

          <div>
            Total investors holding the fund:{' '}
            <span className="font-weight-bold">
              {planDetails?.totalInvestors}
            </span>
          </div>
        </div>

        <div
          style={{ gap: '45px' }}
          className="d-flex align-items-center mt-10"
        >
          <div
            style={{
              maxWidth: '180px',
            }}
            className="form-group mb-2"
          >
            <div>
              <input
                className="form-control"
                type="number"
                placeholder={
                  planDetails?.incomeType !== incomeTypes.DIVIDENDS
                    ? 'Percentage interest'
                    : 'Amount per unit'
                }
                name="amountPerUnit"
                min={0}
                autoComplete="off"
                value={amountPerUnit || ''}
                onChange={e => setAmountPerUnit(e.target.value)}
              />
            </div>
          </div>

          <div>
            {planDetails?.incomeType !== incomeTypes.DIVIDENDS
              ? '%'
              : planDetails?.currency}
          </div>

          <div>
            <button
              className="btn btn-light-primary font-weight-bold"
              onClick={handleSubmitForIntrest}
              disabled={!amountPerUnit || !planDetails.incomeType}
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb-5">
            <Back url={`/plans`} />
          </div>

          {planDetails?.incomeType !== incomeTypes.DIVIDENDS
            ? renderInterestContent
            : renderDividendPaymentContent}
        </>
      )}
    </>
  );
};
