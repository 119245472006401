import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import moment from 'moment';
import { DateInput } from '../../../common/DateInput';
import { getInvestorStatement } from '../_redux';
import { saveAs } from 'file-saver';

function getModalStyle() {
  const top = 49;
  const left = 44;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '25%',
    maxHeight: '75vh',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const StatementDateModal = ({
  open,
  setOpen,
  handleDateChange,
  handleStatementClose,
  dateValues,
  id,
  setAlert,
  handleAlert,
  setPdfLoading,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const handleClose = async e => {
    e.preventDefault();
    setOpen(false); // Close the modal when called
    setPdfLoading(true);
    // handleStatementClose();
    try {
      const res = await getInvestorStatement(dateValues, id);

      if (res.status === 200) {
        // Create a Blob from the response data
        const pdfToBlob = new Blob([res.data], {
          type: 'application/pdf',
        });

        console.log(pdfToBlob);
        // Use file-saver to save the Blob as 'statements.pdf' file
        saveAs(pdfToBlob, 'statements.pdf');

        handleAlert('Downloaded', 'success');
      }
    } catch (error) {
      // Handle the error response as JSON
      let errorMessage;
      if (error?.response?.request?.responseType === 'arraybuffer') {
        errorMessage = JSON.parse(
          new TextDecoder().decode(error?.response?.data),
        )?.message;
      } else {
        errorMessage = error?.response?.data?.message;
      }
      setAlert({
        alertMessage: errorMessage
          ? errorMessage
          : 'Something went wrong, please try again',
        alertMessageType: 'error',
      });

      handleAlert(errorMessage, 'error');
      //   setPdfLoading(false);
    }
    setPdfLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section style={modalStyle} className={classes.paper}>
        <DateInput
          name="startDate"
          label="Start Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
        />
        <DateInput
          name="endDate"
          label="End Date"
          handleDateChange={handleDateChange}
          max={moment().format('YYYY-MM-DD')}
        />
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <div />
          <button
            onClick={handleClose}
            id="kt_login_signin_submit"
            className={`btn btn-primary font-weight-bold `}
            // disabled={!!dateValues}
          >
            <span>Search</span>
          </button>
        </div>
      </section>
    </Modal>
  );
};

export default StatementDateModal;
