import React, { useEffect } from 'react';
import OpenFile from '../OpenFile';
import UploadFile from './UploadFile';
import FileIcon from './FileIcon';
import { getFileName } from '../helpers/getFileName';

function ImageList({
  imageURL,
  handleOpeningFile,
  downloadingImage,
  files,
  investorsData,
  handleCardUpload,
  fileName,
  originalUrl,
  iframeId,
  margin,
  title,
}) {
  const styleImageInsideIframe = () => {
    const iframe = document.getElementById(iframeId);
    const iframeContainer = document.getElementById(`${iframeId}-container`);
    if (iframe) {
      iframe.onload = () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        const iframeImage = iframeDocument.querySelector('img');
        const imageWidth = iframeImage.width > 350 ? 350 : iframeImage.width;
        const imageHeight = iframeImage.height > 350 ? 350 : iframeImage.height;

        const newParent = document.createElement('div');

        iframeContainer.style.width = `${imageWidth}px`;
        iframeContainer.style.height = `${imageHeight}px`;

        newParent.style.width = `${imageWidth}px`;
        newParent.style.height = `${imageHeight}px`;
        newParent.id = `${iframeId}-image-container`;
        newParent.style.marginLeft = 'auto';
        newParent.style.marginRight = 'auto';

        const originalParent = iframeImage.parentNode;
        originalParent.insertBefore(newParent, iframeImage.nextSibling);

        newParent.appendChild(iframeImage);

        if (iframeImage) {
          iframeImage.style.width = '95%';
          iframeImage.style.height = '100%';
          iframeImage.style.objectFit = 'contain';
          iframeImage.style.marginLeft = 'auto';
          iframeImage.style.marginRight = 'auto';
        }
      };
    }
  };

  const zoomInAndZoomOut = action => {
    const iframe = document.getElementById(iframeId);

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    iframe.removeAttribute('scrolling');

    const iframeImage = iframeDocument.querySelector('img');
    const iframeContainer = document.getElementById(`${iframeId}-container`);
    const imageContainer = iframeDocument.getElementById(
      `${iframeId}-image-container`,
    );

    iframeContainer.style.width = '350px';
    iframeContainer.style.height = '350px';

    if (iframeImage) {
      const currentWidth = parseInt(imageContainer.style.width);
      const currentHeight = parseInt(imageContainer.style.height);

      const newWidth =
        action === 'zoomIn' ? currentWidth * 1.2 : currentWidth / 1.2;
      const newHeight =
        action === 'zoomIn' ? currentHeight * 1.2 : currentHeight / 1.2;

      imageContainer.style.width = `${newWidth}px`;
      imageContainer.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    styleImageInsideIframe();
  }, []);

  return (
    <div
      className="image-list"
      style={{
        marginRight: margin && margin,
      }}
    >
      {originalUrl?.includes('.pdf') ? (
        <div>
          <p>This is a pdf file </p>
          <FileIcon />
        </div>
      ) : (
        <>
          <div
            id={`${iframeId}-container`}
            style={{
              width: '350px',
              height: '350px',
              overflow: 'hidden',
            }}
          >
            <iframe
              id={iframeId}
              src={imageURL}
              title={`${fileName}`}
              style={{
                width: '100%',
                height: '100%',
                border: '1px solid #cae2f6',
                borderRadius: '5px',
              }}
              scrolling="no"
            ></iframe>
          </div>
          <div className="d-flex align-items-center mt-5">
            <div className="font-weight-bolder mr-5">Zoom</div>
            <div
              style={{
                backgroundColor: '#000',
                color: '#fff',
                width: '20px',
                height: '20px',
              }}
              className="d-flex justify-content-center align-items-center font-weight-bold cursor-pointer"
              onClick={() => zoomInAndZoomOut('zoomIn')}
            >
              +
            </div>
            <div
              className="d-flex justify-content-center align-items-center ml-1 font-weight-bold cursor-pointer"
              style={{
                backgroundColor: '#000',
                color: '#fff',
                width: '20px',
                height: '20px',
              }}
              onClick={() => zoomInAndZoomOut('zoomOut')}
            >
              -
            </div>
          </div>
        </>
      )}

      <div className="d-flex flex-wrap py-3 justify-content-between align-items-center">
        <OpenFile
          file={imageURL}
          text={'preview'}
          handleOpeningFile={handleOpeningFile}
          loading={downloadingImage}
          clickedButton="userIdentification"
        />
        <UploadFile
          files={files}
          investorsData={investorsData}
          document={imageURL}
          name={getFileName(fileName)}
          title={`${title}`}
          handleChange={handleCardUpload}
          displayTitle={true}
        />
      </div>
    </div>
  );
}

export default ImageList;
